import * as actionTypes from './actionTypes';
import { PERMISSAO_BANNER } from './constants';

export function pesquisarBanners(data = {}) {
  return {
    type: actionTypes.PESQUISAR_BANNERS,
    payload: {
      request: {
        data,
        url: 'app/configuracoes/banners/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: false,
        failure: {
          key: 'banner.mensagens.post.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_BANNER.CONSULTAR,
    },
  };
}

export function criarBanner(data) {
  return {
    type: actionTypes.CRIAR_BANNER,
    payload: {
      request: {
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
        url: 'app/configuracoes/banners',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: {
          key: 'banner.mensagens.post.httpSucesso',
        },
        failure: {
          key: 'banner.mensagens.post.httpFalha',
        },
      },
    },
  };
}

export function recuperarBanner(id) {
  return {
    type: actionTypes.RECUPERAR_BANNER,
    payload: {
      request: {
        url: `app/configuracoes/banners/${id}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: false,
        failure: {
          key: 'banner.mensagens.get.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_BANNER.VISUALIZAR,
    },
  };
}

export function atualizarBanner(data, id) {
  return {
    type: actionTypes.ATUALIZAR_BANNER,
    payload: {
      request: {
        data,
        url: `app/configuracoes/banners/${id}`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: {
          key: 'banner.mensagens.put.httpSucesso',
        },
        failure: {
          key: 'banner.mensagens.put.httpFalha',
        },
      },
    },
  };
}

export function deletarBanner(id) {
  return {
    type: actionTypes.DELETAR_BANNER,
    payload: {
      request: {
        url: `app/configuracoes/banners/${id}`,
        method: 'DELETE',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: {
          key: 'banner.mensagens.delete.httpSucesso',
        },
        failure: {
          key: 'banner.mensagens.delete.httpFalha',
        },
      },
    },
  };
}
