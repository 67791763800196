import React from 'react';
import _ from 'lodash';
import { DonwnloadDocumentosContext } from '../../../constants';
import { statusIcon } from '../../helpers';
import './styles.scss';
import InfoTarefa from './InfoTarefa';

const percentualConclusao = (etapa) => {
  const total = etapa.tarefas.length;
  const count = etapa.tarefas.filter(t => t.status === 'concluido').length;
  return parseInt((count / total) * 100, 0);
};

const Etapas = () => (
  <DonwnloadDocumentosContext.Consumer>
    {({ t, downloadCorrente: download }) => (
      download && (
        _.map(download.etapas, ((etapa) => {
          const perc = percentualConclusao(etapa);
          return (
            <ul className="etapa" key={etapa.nome}>
              <span className="name">{etapa.nome}</span>
              <span className="progress">
                <span className="evolution" style={{ width: `${perc}%`, opacity: (perc / 100) }} />
              </span>
              {etapa.tarefas.map(tarefa => (
                <li
                  className="tarefa"
                  key={`tarefa-${tarefa.id}`}>
                  {statusIcon(tarefa.status)}
                  <span className="title">{tarefa.label}</span>
                  {InfoTarefa(tarefa, t)}
                </li>
              ))}
            </ul>
          );
        }))
      )
    )}
  </DonwnloadDocumentosContext.Consumer>
);

export default Etapas;
