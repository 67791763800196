import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import List, { ListItem } from 'linear-react-components-ui/lib/list';
import GridCol, { GridRow } from 'linear-react-components-ui/lib/gridlayout';
import SearchField from 'linear-react-components-ui/lib/inputs/search';
import { PrimaryButton } from 'linear-react-components-ui/lib/buttons';
import SpinnerLoading from 'linear-react-components-ui/lib/spinner';
import _ from 'lodash';
import SemDados from '@linear-front-end/commons/lib/semDados';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

class Container extends Component {
  state = {
    gtinSelecionado: undefined,
  }

  componentWillUnmount() {
    this.props.limparGtins();
  }

  pesquisar = (value) => {
    const { pesquisarGtins } = this.props;
    const { gtinSelecionado } = this.state;

    let filtro = { numero: value };
    if (value.trim() === '' || gtinSelecionado) {
      this.setState({ gtinSelecionado: undefined });
      this.props.limparGtins();
      return;
    }
    if (!/^\d+$/.test(value)) filtro = { descricao_produto: value };
    pesquisarGtins(filtro);
    if (gtinSelecionado) this.setState({ gtinSelecionado: undefined });
  }

  renderizarGtins = ({ gtins, pesquisando }) => (
    <List selectable style={{ height: 410, marginTop: 5 }}>
      <Fragment>
        {gtins.map(gtin => (
          <ListItem
            leftElement={
              <div className="image-container">
                <img src={gtin.urlImagemThumb} alt="" className="thumbsize" />
              </div>
            }
            itemId={`${gtin.numero}`}
            key={`${gtin.numero}`}
            text={gtin.descricaoProduto}
            subText={gtin.numero}
            onClick={() => { this.setState({ gtinSelecionado: gtin }); }} />
        ))}
        { pesquisando && <SpinnerLoading />}
      </Fragment>
    </List>
  )

  renderizarGtinSelecionado = () => {
    const {
      handlerUsarImagem, pesquisando, disableButton, t, gtins,
    } = this.props;
    const { gtinSelecionado } = this.state;

    if (gtinSelecionado && !pesquisando) {
      return (
        <Fragment>
          <img src={gtinSelecionado.urlImagemOriginal} alt="" className="originalsize" />
          <PrimaryButton
            label={t('commons:buttons.usarImagem')}
            onClick={() => { handlerUsarImagem(gtinSelecionado.id); }}
            isLoading={disableButton}
            disabled={disableButton}
            style={{ marginTop: 10 }} />
        </Fragment>
      );
    } if (gtins.length > 0 && !pesquisando) {
      return (
        <SemDados
          hideIcon
          title={t('commons:labels.selecioneImagem')}
          info={t('cadastros:produtoImagem.mensagens.clicarImagemLista')} />
      );
    }
    return null;
  }

  render() {
    const { t } = this.props;
    const pesquisaGtins = _.debounce((condicao) => { this.pesquisar(condicao); }, 300);
    return (
      <GridRow style={{ height: '100%' }}>
        <GridCol cols="12 12 6 6 6">
          <SearchField
            onChange={(e) => { pesquisaGtins(e.target.value); }}
            placeHolder={t('tabelasSistema:gtins.labels.pesquisarPlaceholder')}
            onReset={() => { this.pesquisar(''); }} />
          {this.renderizarGtins({ ...this.props })}
        </GridCol>
        <GridCol cols="12 12 6 6 6">
          <div className="image-container">
            {this.renderizarGtinSelecionado()}
          </div>
        </GridCol>
      </GridRow>
    );
  }
}

Container.propTypes = {
  pesquisarGtins: PropTypes.func.isRequired,
  pesquisando: PropTypes.bool.isRequired,
  gtins: PropTypes.arrayOf(PropTypes.objectOf),
  handlerUsarImagem: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
  limparGtins: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Container.defaultProps = {
  gtins: [],
};

const mapStateToProps = state => ({
  pesquisando: selectors.obterPesquisandoGtins(state),
  gtins: selectors.obterGtins(state),
});

const { pesquisarGtins, limparGtins } = actions;
const connectRedux = connect(mapStateToProps, { pesquisarGtins, limparGtins });
export default connectRedux(translate(['common', 'tabelasSistema'])(Container));
