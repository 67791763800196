import * as actionTypes from './actionTypes';
import { PERMISSAO_PARCEIROS } from './constants';

export function criarParceiro(data) {
  return {
    type: actionTypes.CRIAR_PARCEIRO,
    payload: {
      request: {
        data,
        url: 'cadastros/parceiros',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'parceiro.mensagens.post.httpSucesso',
        },
        failure: {
          key: 'parceiro.mensagens.post.httpFalha',
        },
      },
    },
  };
}

export function pesquisarParceiros(data) {
  return {
    type: actionTypes.PESQUISAR_PARCEIROS,
    payload: {
      request: {
        data,
        url: 'cadastros/parceiros/pesquisa',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        },
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        failure: {
          key: 'parceiro.mensagens.getAll.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_PARCEIROS.CONSULTAR,
    },
  };
}

export function recuperarParceiro(id, visualizacao = false) {
  return {
    type: actionTypes.RECUPERAR_PARCEIRO,
    payload: {
      request: {
        url: `cadastros/parceiros/${id}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        failure: {
          key: 'parceiro.mensagens.get.httpFalha',
        },
      },
      permissionAttr: visualizacao ? PERMISSAO_PARCEIROS.CONSULTAR : undefined,
    },
  };
}

export function atualizarParceiro(data, id) {
  return {
    type: actionTypes.ATUALIZAR_PARCEIRO,
    payload: {
      request: {
        data,
        url: `cadastros/parceiros/${id}`,
        method: 'PUT',
        headers: { 'Accept-Language': 'pt-BR', 'Content-Type': 'application/json;charset=UTF-8' },
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'parceiro.mensagens.put.httpSucesso',
        },
        failure: {
          key: 'parceiro.mensagens.put.httpFalha',
        },
      },
    },
  };
}

export function ativarParceiro(id) {
  return {
    type: actionTypes.ATIVAR_PARCEIRO,
    payload: {
      request: {
        url: `cadastros/parceiros/${id}/ativar`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'parceiro.mensagens.ativar.httpSucesso',
        },
        failure: {
          key: 'parceiro.mensagens.ativar.httpFalha',
        },
      },
    },
  };
}

export function inativarParceiro(id) {
  return {
    type: actionTypes.INATIVAR_PARCEIRO,
    payload: {
      request: {
        url: `cadastros/parceiros/${id}/inativar`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'parceiro.mensagens.inativar.httpSucesso',
        },
        failure: {
          key: 'parceiro.mensagens.inativar.httpFalha',
        },
      },
    },
  };
}

export function restaurarParceiro(id) {
  return {
    type: actionTypes.RESTAURAR_PARCEIRO,
    payload: {
      request: {
        url: `cadastros/parceiros/${id}/restaurar`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'parceiro.mensagens.restaurar.httpSucesso',
        },
        failure: {
          key: 'parceiro.mensagens.restaurar.httpFalha',
        },
      },
    },
  };
}

export function deletarParceiro(id) {
  return {
    type: actionTypes.DELETAR_PARCEIRO,
    payload: {
      request: {
        url: `cadastros/parceiros/${id}`,
        method: 'DELETE',
      },
      recordId: id,
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'parceiro.mensagens.delete.httpSucesso',
        },
        failure: {
          key: 'parceiro.mensagens.delete.httpFalha',
        },
      },
    },
  };
}

export function recuperarCaracteristicasParceiro() {
  return {
    type: actionTypes.RECUPERAR_CARACTERISTICAS_PARCEIRO,
    payload: {
      request: {
        url: 'cadastros/parceiros/caracteristicas',
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        failure: {
          key: 'parceiro.mensagens.get.httpFalha',
        },
      },
    },
  };
}

export function atualizarColaboradorAposCriarUsuario(idParceiro, idUsuario) {
  return {
    type: actionTypes.ATUALIZAR_COLABORADOR_APOS_CRIAR_USUARIO,
    idParceiro,
    idUsuario,
  };
}
