export const CRIAR_PERFIL = 'CRIAR_PERFIL';
export const CRIAR_PERFIL_REQUISICAO = 'CRIAR_PERFIL_REQUISICAO';
export const CRIAR_PERFIL_SUCESSO = 'CRIAR_PERFIL_SUCESSO';
export const CRIAR_PERFIL_ERRO = 'CRIAR_PERFIL_ERRO';

export const PESQUISAR_PERFIS = 'PESQUISAR_PERFIS';
export const PESQUISAR_PERFIS_REQUISICAO = 'PESQUISAR_PERFIS_REQUISICAO';
export const PESQUISAR_PERFIS_SUCESSO = 'PESQUISAR_PERFIS_SUCESSO';
export const PESQUISAR_PERFIS_ERRO = 'PESQUISAR_PERFIS_ERRO';

export const RECUPERAR_PERFIL = 'RECUPERAR_PERFIL';
export const RECUPERAR_PERFIL_REQUISICAO = 'RECUPERAR_PERFIL_REQUISICAO';
export const RECUPERAR_PERFIL_SUCESSO = 'RECUPERAR_PERFIL_SUCESSO';
export const RECUPERAR_PERFIL_ERRO = 'RECUPERAR_PERFIL_ERRO';

export const ATUALIZAR_PERFIL = 'ATUALIZAR_PERFIL';
export const ATUALIZAR_PERFIL_REQUISICAO = 'ATUALIZAR_PERFIL_REQUISICAO';
export const ATUALIZAR_PERFIL_SUCESSO = 'ATUALIZAR_PERFIL_SUCESSO';
export const ATUALIZAR_PERFIL_ERRO = 'ATUALIZAR_PERFIL_ERRO';

export const DELETAR_PERFIL = 'DELETAR_PERFIL';
export const DELETAR_PERFIL_REQUISICAO = 'DELETAR_PERFIL_REQUISICAO';
export const DELETAR_PERFIL_SUCESSO = 'DELETAR_PERFIL_SUCESSO';
export const DELETAR_PERFIL_ERRO = 'DELETAR_PERFIL_ERRO';

export const RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL = 'RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL';
export const RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL_REQUISICAO = 'RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL_REQUISICAO';
export const RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL_SUCESSO = 'RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL_SUCESSO';
export const RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL_ERRO = 'RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL_ERRO';

export const RECUPERAR_RECURSOS_VINCULADOS_PERFIL = 'RECUPERAR_RECURSOS_VINCULADOS_PERFIL';
export const RECUPERAR_RECURSOS_VINCULADOS_PERFIL_REQUISICAO = 'RECUPERAR_RECURSOS_VINCULADOS_PERFIL_REQUISICAO';
export const RECUPERAR_RECURSOS_VINCULADOS_PERFIL_SUCESSO = 'RECUPERAR_RECURSOS_VINCULADOS_PERFIL_SUCESSO';
export const RECUPERAR_RECURSOS_VINCULADOS_PERFIL_ERRO = 'RECUPERAR_RECURSOS_VINCULADOS_PERFIL_ERRO';

export const INCLUIR_RECURSOS_PERFIL = 'INCLUIR_RECURSOS_PERFIL';
export const INCLUIR_RECURSOS_PERFIL_REQUISICAO = 'INCLUIR_RECURSOS_PERFIL_REQUISICAO';
export const INCLUIR_RECURSOS_PERFIL_SUCESSO = 'INCLUIR_RECURSOS_PERFIL_SUCESSO';
export const INCLUIR_RECURSOS_PERFIL_ERRO = 'INCLUIR_RECURSOS_PERFIL_ERRO';

export const REMOVER_RECURSOS_PERFIL = 'REMOVER_RECURSOS_PERFIL';
export const REMOVER_RECURSOS_PERFIL_REQUISICAO = 'REMOVER_RECURSOS_PERFIL_REQUISICAO';
export const REMOVER_RECURSOS_PERFIL_SUCESSO = 'REMOVER_RECURSOS_PERFIL_SUCESSO';
export const REMOVER_RECURSOS_PERFIL_ERRO = 'REMOVER_RECURSOS_PERFIL_ERRO';
