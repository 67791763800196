import _ from 'lodash';
import { createSelector } from 'reselect';
import * as constants from './constants';

const obterRecuperando = state => state[constants.NAME].recuperando;
const obterSalvando = state => state[constants.NAME].salvando;
const obterUrlsLojas = createSelector(
  state => state[constants.NAME].urlsLojas,
  urlsLojas => _.sortBy(_.values(urlsLojas), ['nomeFantasia']),
);

export { obterUrlsLojas, obterRecuperando, obterSalvando };
