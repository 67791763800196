"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _App = _interopRequireDefault(require("./components/App"));

var _DesktopContent = _interopRequireDefault(require("./components/DesktopContent"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  App: _App["default"],
  Content: _DesktopContent["default"]
};
exports["default"] = _default;