import _ from 'lodash';
import { createSelector } from 'reselect';
import * as constants from './constants';

export const obterParceiros = createSelector(
  state => state[constants.NAME].itens,
  itens => _.orderBy(_.map(itens, item => item), 'id', ['asc']),
);

export const obterOperacaoApi = state => state[constants.NAME].operacaoApi;
export const getDefaultData = (state) => {
  const { defaultData } = state[constants.NAME];
  return JSON.parse(JSON.stringify(defaultData));
};

export const obterParceiroSelecionado = state => state[constants.NAME].current;
export const obterParceiroPaginacao = state => state[constants.NAME].paginacao;
export const obterCaracteristicas = state => state[constants.NAME].caracteristicas;

export const obterRecuperandoCaracteristicas = state =>
  state[constants.NAME].recuperandoCaracteristicas;
