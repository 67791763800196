import * as actionTypes from './actionTypes';

export function pesquisarDownloadsDocumentos(data = {}) {
  return {
    type: actionTypes.PESQUISAR_DOWNLOADS_DOUMENTOS,
    payload: {
      request: {
        data,
        url: 'documentos-eletronicos/downloads/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'documentosEletronicos',
        success: false,
        failure: {
          key: 'notasFiscais.mensagens.getAll.httpFalha',
        },
      },
    },
  };
}

export function recuperarDownloadDocumentos(id) {
  return {
    type: actionTypes.RECUPERAR_DOWNLOAD_DOCUMENTOS,
    payload: {
      request: {
        url: `documentos-eletronicos/downloads/${id}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'documentosEletronicos',
        success: false,
        failure: {
          key: 'notasFiscais.mensagens.getAll.httpFalha',
        },
      },
    },
  };
}

export function limparDownloads() {
  return {
    type: actionTypes.LIMPAR_DOWNLOADS_DOCUMENTOS,
  };
}
