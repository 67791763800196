import _ from 'lodash';
import * as constants from './constants';

const obterUploadingImageProduto = state => state[constants.NAME].uploadingImagemProduto;
const obterImagemProduto = state => state[constants.NAME].imagemProduto;
const obterAtachandoImagem = state => state[constants.NAME].atachandoImagem;
const obterRecuperandoImagem = state => state[constants.NAME].recuperando;
const obterImagemSelecionada = state => state[constants.NAME].imagemSelecionada;
const obterPesquisandoImagens = state => state[constants.NAME].pesquisando;
const obterImagens = state => _.map(state[constants.NAME].produtoImagens.itens, item => item);
const obterAtualizandoImagem = state => state[constants.NAME].atualizando;
const obterDeletandoImagem = state => state[constants.NAME].deletando;
const obterPesquisandoImagensSelecao = state => state[constants.NAME].pesquisandoImagensSelecao;
const obterImagensSelecao = state => state[constants.NAME].produtoImagensSelecao;

export {
  obterImagemSelecionada,
  obterRecuperandoImagem,
  obterPesquisandoImagens,
  obterUploadingImageProduto,
  obterImagemProduto,
  obterAtachandoImagem,
  obterAtualizandoImagem,
  obterImagens,
  obterPesquisandoImagensSelecao,
  obterImagensSelecao,
  obterDeletandoImagem,
};
