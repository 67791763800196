import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalForm from 'linear-react-components-ui/lib/dialog/form';
import Tabs, { TabPanel } from 'linear-react-components-ui/lib/tabs';
import { CancelButton } from 'linear-react-components-ui/lib/buttons';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import Upload from './Upload';
import ImagensPrivadasContainer from './ImagensPrivadasContainer';
import { TABS_ID } from '../../constants';
import { uploadImagemProduto, atacharImagemAoProduto } from '../../actions';
import * as selectors from '../../selectors';
import promocoes from '../../../promocoes';
import tabelasSistema from '../../../../../tabelas_sistema';

class Container extends Component {
  state = {
    selectedTabId: TABS_ID.upload,
  }

  uploadImagem = (data) => {
    const { produto, dispatch } = this.props;
    dispatch(uploadImagemProduto(produto.id, data))
      .then((response) => { this.finalizar(response); });
  }

  atacharImagem = (data) => {
    const { produto, dispatch } = this.props;
    dispatch(atacharImagemAoProduto(produto.id, { ...data }))
      .then((response) => { this.finalizar(response); });
  };

  finalizar = (response) => {
    const { dispatch, handlerFecharModal } = this.props;
    const { substituirProdutoEmPromocao } = promocoes.lista.actions;
    dispatch(substituirProdutoEmPromocao(response.payload.data));
    handlerFecharModal();
  }

  render() {
    const {
      handlerFecharModal, t, produto, uploading, atachandoImagem,
    } = this.props;
    const { selectedTabId } = this.state;
    return (
      <ModalForm
        title={t('cadastros:produtoImagem.labels.tituloTelaSelecao', { descricaoProduto: produto.descricaoVenda })}
        handlerClose={() => { handlerFecharModal(); }}
        width="960px"
        height="650px"
        closeOnEsc={!uploading}
        buttons={[
          <CancelButton
            key="button-cancelar"
            label={t('commons:buttons.cancelar')}
            onClick={() => { handlerFecharModal(); }}
            disabled={uploading} />,
        ]}>
        <Tabs
          selectedTab={selectedTabId}
          tabMenuPosition="top"
          handlerTabClick={(tabId) => {
            this.setState({ selectedTabId: tabId });
          }}>
          <TabPanel title={t('commons:labels.upload')} id={TABS_ID.upload}>
            <Upload
              t={t}
              handlerUpload={this.uploadImagem}
              produtoId={produto.id}
              uploading={uploading} />
          </TabPanel>
          <TabPanel title={t('commons:labels.imagensPrivadas')} id={TABS_ID.imagensPrivadas}>
            <ImagensPrivadasContainer
              handlerUsarImagem={(produtoImagemId) => {
                this.atacharImagem({ produtoImagemId });
              }}
              produtoId={produto.id} />
          </TabPanel>
          <TabPanel title={t('commons:labels.bancoImagens')} id={TABS_ID.bancoImagem}>
            <tabelasSistema.PesquisaGtins
              handlerUsarImagem={(gtinId) => { this.atacharImagem({ gtinId }); }}
              disableButton={atachandoImagem} />
          </TabPanel>
        </Tabs>
      </ModalForm>
    );
  }
}

Container.propTypes = {
  produto: PropTypes.object.isRequired,
  handlerFecharModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  atachandoImagem: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  uploading: selectors.obterUploadingImageProduto(state),
  imagemProduto: selectors.obterUploadingImageProduto(state),
  atachandoImagem: selectors.obterAtachandoImagem(state),
});

const connectRedux = connect(mapStateToProps);
export default connectRedux(translate(['common', 'cadastros'])(Container));
