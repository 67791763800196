"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uiEstaEditandoOuAdicionando = exports.uiEstaEditando = exports.uiEstaAdicionando = exports.uiEstaRestaurando = exports.uiEstaInativando = exports.uiEstaAtivando = exports.uiEstaDeletando = exports.apiEstaSalvando = exports.apiEstaPesquisando = exports.apiEstaRestaurando = exports.apiEstaInativando = exports.apiEstaAtivando = exports.apiEstaDeletando = exports.apiEstaRecuperando = exports.OPERACOES_API = exports.OPERACOES_UI = void 0;
var OPERACOES_UI = {
  ADICIONANDO: 'ADICIONANDO',
  EDITANDO: 'EDITANDO',
  DELETANDO: 'DELETANDO',
  ATIVANDO: 'ATIVANDO',
  INATIVANDO: 'INATIVANDO',
  RESTAURANDO: 'RESTAURANDO'
};
exports.OPERACOES_UI = OPERACOES_UI;
var OPERACOES_API = {
  DELETANDO: 'DELETANDO',
  ATIVANDO: 'ATIVANDO',
  INATIVANDO: 'INATIVANDO',
  RESTAURANDO: 'RESTAURANDO',
  PESQUISANDO: 'PESQUISANDO',
  RECUPERANDO: 'RECUPERANDO',
  SALVANDO: 'SALVANDO'
};
exports.OPERACOES_API = OPERACOES_API;

var apiEstaRecuperando = function apiEstaRecuperando(opApi) {
  return OPERACOES_API.RECUPERANDO === opApi;
};

exports.apiEstaRecuperando = apiEstaRecuperando;

var apiEstaDeletando = function apiEstaDeletando(opApi) {
  return OPERACOES_API.DELETANDO === opApi;
};

exports.apiEstaDeletando = apiEstaDeletando;

var apiEstaAtivando = function apiEstaAtivando(opApi) {
  return OPERACOES_API.ATIVANDO === opApi;
};

exports.apiEstaAtivando = apiEstaAtivando;

var apiEstaInativando = function apiEstaInativando(opApi) {
  return OPERACOES_API.INATIVANDO === opApi;
};

exports.apiEstaInativando = apiEstaInativando;

var apiEstaRestaurando = function apiEstaRestaurando(opApi) {
  return OPERACOES_API.RESTAURANDO === opApi;
};

exports.apiEstaRestaurando = apiEstaRestaurando;

var apiEstaPesquisando = function apiEstaPesquisando(opApi) {
  return OPERACOES_API.PESQUISANDO === opApi;
};

exports.apiEstaPesquisando = apiEstaPesquisando;

var apiEstaSalvando = function apiEstaSalvando(opApi) {
  return OPERACOES_API.SALVANDO === opApi;
};

exports.apiEstaSalvando = apiEstaSalvando;

var uiEstaDeletando = function uiEstaDeletando(opUi) {
  return OPERACOES_UI.DELETANDO === opUi;
};

exports.uiEstaDeletando = uiEstaDeletando;

var uiEstaAtivando = function uiEstaAtivando(opUi) {
  return OPERACOES_UI.ATIVANDO === opUi;
};

exports.uiEstaAtivando = uiEstaAtivando;

var uiEstaInativando = function uiEstaInativando(opUi) {
  return OPERACOES_UI.INATIVANDO === opUi;
};

exports.uiEstaInativando = uiEstaInativando;

var uiEstaRestaurando = function uiEstaRestaurando(opUi) {
  return OPERACOES_UI.RESTAURANDO === opUi;
};

exports.uiEstaRestaurando = uiEstaRestaurando;

var uiEstaAdicionando = function uiEstaAdicionando(opUi) {
  return OPERACOES_UI.ADICIONANDO === opUi;
};

exports.uiEstaAdicionando = uiEstaAdicionando;

var uiEstaEditando = function uiEstaEditando(opUi) {
  return OPERACOES_UI.EDITANDO === opUi;
};

exports.uiEstaEditando = uiEstaEditando;

var uiEstaEditandoOuAdicionando = function uiEstaEditandoOuAdicionando(opUi) {
  return [OPERACOES_UI.EDITANDO, OPERACOES_UI.ADICIONANDO].includes(opUi);
};

exports.uiEstaEditandoOuAdicionando = uiEstaEditandoOuAdicionando;