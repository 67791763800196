import * as contants from './constants';

const obterPesquisandoGtins = state => state[contants.NAME].gtins.pesquisando;
const obterGtins = state => state[contants.NAME].gtins.itens;

const obterPesquisandoTiposDocsEletronicos = state =>
  state[contants.NAME].tiposDocumentosEletronicos.pesquisando;

const obterTiposDocsEletronicos = state => state[contants.NAME].tiposDocumentosEletronicos.itens;

export {
  obterPesquisandoGtins,
  obterGtins,
  obterPesquisandoTiposDocsEletronicos,
  obterTiposDocsEletronicos,
};
