"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNAUTHORIZED = exports.LOGOFF_ERRO = exports.LOGOFF_SUCESSO = exports.LOGOFF_REQUISICAO = exports.LOGOFF = void 0;
var LOGOFF = 'LOGOFF';
exports.LOGOFF = LOGOFF;
var LOGOFF_REQUISICAO = 'LOGOFF_REQUISICAO';
exports.LOGOFF_REQUISICAO = LOGOFF_REQUISICAO;
var LOGOFF_SUCESSO = 'LOGOFF_SUCESSO';
exports.LOGOFF_SUCESSO = LOGOFF_SUCESSO;
var LOGOFF_ERRO = 'LOGOFF_ERRO';
exports.LOGOFF_ERRO = LOGOFF_ERRO;
var UNAUTHORIZED = 'UNAUTHORIZED';
exports.UNAUTHORIZED = UNAUTHORIZED;