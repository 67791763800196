export const RECUPERAR_QUANTIDADE_PRODUTOS_VIGENTES = 'RECUPERAR_QUANTIDADE_PRODUTOS_VIGENTES';
export const RECUPERAR_QUANTIDADE_PRODUTOS_VIGENTES_REQUISICAO = 'RECUPERAR_QUANTIDADE_PRODUTOS_VIGENTES_REQUISICAO';
export const RECUPERAR_QUANTIDADE_PRODUTOS_VIGENTES_SUCESSO = 'RECUPERAR_QUANTIDADE_PRODUTOS_VIGENTES_SUCESSO';
export const RECUPERAR_QUANTIDADE_PRODUTOS_VIGENTES_ERRO = 'RECUPERAR_QUANTIDADE_PRODUTOS_VIGENTES_ERRO';

export const RECUPERAR_RESUMO_VENDAS_POR_TURNO = 'RECUPERAR_RESUMO_VENDAS_POR_TURNO';
export const RECUPERAR_RESUMO_VENDAS_POR_TURNO_REQUISICAO = 'RECUPERAR_RESUMO_VENDAS_POR_TURNO_REQUISICAO';
export const RECUPERAR_RESUMO_VENDAS_POR_TURNO_SUCESSO = 'RECUPERAR_RESUMO_VENDAS_POR_TURNO_SUCESSO';
export const RECUPERAR_RESUMO_VENDAS_POR_TURNO_ERRO = 'RECUPERAR_RESUMO_VENDAS_POR_TURNO_ERRO';

export const RECUPERAR_RESUMO_VENDAS_POR_DATA = 'RECUPERAR_RESUMO_VENDAS_POR_DATA';
export const RECUPERAR_RESUMO_VENDAS_POR_DATA_REQUISICAO = 'RECUPERAR_RESUMO_VENDAS_POR_DATA_REQUISICAO';
export const RECUPERAR_RESUMO_VENDAS_POR_DATA_SUCESSO = 'RECUPERAR_RESUMO_VENDAS_POR_DATA_SUCESSO';
export const RECUPERAR_RESUMO_VENDAS_POR_DATA_ERRO = 'RECUPERAR_RESUMO_VENDAS_POR_DATA_ERRO';

export const RECUPERAR_RESUMO_VENDAS_POR_EMPRESA = 'RECUPERAR_RESUMO_VENDAS_POR_EMPRESA';
export const RECUPERAR_RESUMO_VENDAS_POR_EMPRESA_REQUISICAO = 'RECUPERAR_RESUMO_VENDAS_POR_EMPRESA_REQUISICAO';
export const RECUPERAR_RESUMO_VENDAS_POR_EMPRESA_SUCESSO = 'RECUPERAR_RESUMO_VENDAS_POR_EMPRESA_SUCESSO';
export const RECUPERAR_RESUMO_VENDAS_POR_EMPRESA_ERRO = 'RECUPERAR_RESUMO_VENDAS_POR_EMPRESA_ERRO';

export const RECUPERAR_TICKET_MEDIO = 'RECUPERAR_TICKET_MEDIO';
export const RECUPERAR_TICKET_MEDIO_REQUISICAO = 'RECUPERAR_TICKET_MEDIO_REQUISICAO';
export const RECUPERAR_TICKET_MEDIO_SUCESSO = 'RECUPERAR_TICKET_MEDIO_SUCESSO';
export const RECUPERAR_TICKET_MEDIO_ERRO = 'RECUPERAR_TICKET_MEDIO_ERRO';

export const RECUPERAR_COMPARATIVO_TIPO_PROMOCAO_GERAL = 'RECUPERAR_COMPARATIVO_TIPO_PROMOCAO_GERAL';
export const RECUPERAR_COMPARATIVO_TIPO_PROMOCAO_GERAL_REQUISICAO = 'RECUPERAR_COMPARATIVO_TIPO_PROMOCAO_GERAL_REQUISICAO';
export const RECUPERAR_COMPARATIVO_TIPO_PROMOCAO_GERAL_SUCESSO = 'RECUPERAR_COMPARATIVO_TIPO_PROMOCAO_GERAL_SUCESSO';
export const RECUPERAR_COMPARATIVO_TIPO_PROMOCAO_GERAL_ERRO = 'RECUPERAR_COMPARATIVO_TIPO_PROMOCAO_GERAL_ERRO';

export const RECUPERAR_COMPARATIVO_CLIENTES_VENDAS_GERAL = 'RECUPERAR_COMPARATIVO_CLIENTES_VENDAS_GERAL';
export const RECUPERAR_COMPARATIVO_CLIENTES_VENDAS_GERAL_REQUISICAO = 'RECUPERAR_COMPARATIVO_CLIENTES_VENDAS_GERAL_REQUISICAO';
export const RECUPERAR_COMPARATIVO_CLIENTES_VENDAS_GERAL_SUCESSO = 'RECUPERAR_COMPARATIVO_CLIENTES_VENDAS_GERAL_SUCESSO';
export const RECUPERAR_COMPARATIVO_CLIENTES_VENDAS_GERAL_ERRO = 'RECUPERAR_COMPARATIVO_CLIENTES_VENDAS_GERAL_ERRO';

export const RECUPERAR_QUANTIDADE_CLIENTES_POR_DATA_CADASTRO = 'RECUPERAR_QUANTIDADE_CLIENTES_POR_DATA_CADASTRO';
export const RECUPERAR_QUANTIDADE_CLIENTES_POR_DATA_CADASTRO_REQUISICAO = 'RECUPERAR_QUANTIDADE_CLIENTES_POR_DATA_CADASTRO_REQUISICAO';
export const RECUPERAR_QUANTIDADE_CLIENTES_POR_DATA_CADASTRO_SUCESSO = 'RECUPERAR_QUANTIDADE_CLIENTES_POR_DATA_CADASTRO_SUCESSO';
export const RECUPERAR_QUANTIDADE_CLIENTES_POR_DATA_CADASTRO_ERRO = 'RECUPERAR_QUANTIDADE_CLIENTES_POR_DATA_CADASTRO_ERRO';

export const RECUPERAR_QUANTIDADE_TOTAL_CLIENTES = 'RECUPERAR_QUANTIDADE_TOTAL_CLIENTES';
export const RECUPERAR_QUANTIDADE_TOTAL_CLIENTES_REQUISICAO = 'RECUPERAR_QUANTIDADE_TOTAL_CLIENTES_REQUISICAO';
export const RECUPERAR_QUANTIDADE_TOTAL_CLIENTES_SUCESSO = 'RECUPERAR_QUANTIDADE_TOTAL_CLIENTES_SUCESSO';
export const RECUPERAR_QUANTIDADE_TOTAL_CLIENTES_ERRO = 'RECUPERAR_QUANTIDADE_TOTAL_CLIENTES_ERRO';
