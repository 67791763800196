import * as constants from './constants';
import reducer from './reducer';
import * as actions from './actions';
import * as selectors from './selectors';

import EmpresasSelecao from './components/EmpresasSelecao';
import LojasAppSelecao from './components/LojasAppSelecao';

export default {
  reducer,
  constants,
  actions,
  selectors,
  EmpresasSelecao,
  LojasAppSelecao,
};
