import _ from 'lodash';
import { createSelector } from 'reselect';
import * as constants from './constants';

export const obterGruposVendedores = createSelector(
  state => state[constants.NAME].itens,
  itens => _.orderBy(_.map(itens, item => item), ['descricao'], ['asc']),
);
export const obterGruposVendedoresPaginacao = state => state[constants.NAME].paginacao;
export const obterGrupoVendedorCorrente = state => state[constants.NAME].corrente;
export const obterOperacaoApi = state => state[constants.NAME].operacaoApi;
