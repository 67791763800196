import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

let index;
let result;
let newData;
let previousItems;
let currentItems;
const usuarios = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_USUARIOS_REQUISICAO: {
      return {
        ...state, pesquisando: true,
      };
    }
    case actionTypes.PESQUISAR_USUARIOS_SUCESSO:
      result = action.payload.data.content;
      previousItems = state.itens || [];
      currentItems = result.pagination.pageIndex === 0
        ? result.items
        : [...previousItems, ...result.items];
      return {
        ...state,
        pesquisando: false,
        itensPaginacao: { ...state.itensPaginacao, ...result.pagination },
        itens: currentItems,
      };

    case actionTypes.CRIAR_USUARIO_REQUISICAO:
      return {
        ...state, salvando: true,
      };
    case actionTypes.CRIAR_USUARIO_SUCESSO:
      return {
        ...state, salvando: false, itens: [...state.itens, action.payload.data.content],
      };

    case actionTypes.ATUALIZAR_USUARIO_REQUISICAO:
      return {
        ...state, salvando: true,
      };
    case actionTypes.ATUALIZAR_USUARIO_SUCESSO:
      // TODO - Refatorar
      result = action.payload.data.content;
      index = state.itens.findIndex(item => item.id === result.id);
      newData = state.itens;
      newData[index] = result;
      return {
        ...state, salvando: false, itens: newData, current: { ...state.current, data: result },
      };

    case actionTypes.RECUPERAR_USUARIO_REQUISICAO:
      return {
        ...state, recuperando: true, current: {},
      };
    case actionTypes.RECUPERAR_USUARIO_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state, recuperando: false, current: { ...state.current, data: result },
      };

    case actionTypes.DELETAR_USUARIO_REQUISICAO:
      return {
        ...state, deletando: true,
      };
    case actionTypes.DELETAR_USUARIO_SUCESSO:
      result = action.meta.previousAction.payload.recordId;
      return {
        ...state, deletando: false, itens: state.itens.filter(item => item.id !== result),
      };

    case actionTypes.RECUPERAR_PERFIS_USUARIO_REQUISICAO:
      return {
        ...state, recuperandoPerfis: true,
      };
    case actionTypes.RECUPERAR_PERFIS_USUARIO_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state,
        recuperandoPerfis: false,
        current: { ...state.current, perfis: result },
      };

    case actionTypes.CONFIGURAR_PERFIS_USUARIO_REQUISICAO:
      return {
        ...state, configurandoPerfis: true,
      };
    case actionTypes.CONFIGURAR_PERFIS_USUARIO_SUCESSO:
      result = action.payload.data.content;
      return { ...state, configurandoPerfis: false, current: { ...state.current, perfis: result } };

    case action.ASSOCIAR_USUARIO_AO_PARCEIRO_REQUISICAO:
      return {
        ...state, salvando: true,
      };
    case actionTypes.ASSOCIAR_USUARIO_AO_PARCEIRO_SUCESSO:
      return {
        ...state, salvando: false,
      };
      // TODO - Ver como verificar se o nome do usuário esta disponivel, ou se o backEnd
      // Já vaui fazer o tratamento
    /* case actionTypes.VERIFICAR_NOME_USUARIO_DISPONIVEL_REQUISICAO:
      return {
        ...state, verificandoNomeUsuario: true, nomeUsuarioValido: undefined,
      };
    case actionTypes.VERIFICAR_NOME_USUARIO_DISPONIVEL_SUCESSO:
      return {
        ...state, verificandoNomeUsuario: false,
      }; */
    case actionTypes.CRIAR_USUARIO_ERRO:
    case actionTypes.ATUALIZAR_USUARIO_ERRO:
    case actionTypes.PESQUISAR_USUARIOS_ERRO:
    case actionTypes.RECUPERAR_USUARIO_ERRO:
    case actionTypes.RECUPERAR_PERFIS_USUARIO_ERRO:
    case actionTypes.ASSOCIAR_USUARIO_AO_PARCEIRO_ERRO:
    case actionTypes.CONFIGURAR_PERFIS_USUARIO_ERRO:
      return {
        ...state,
        pesquisando: false,
        recuperando: false,
        salvando: false,
        recuperandoPerfis: false,
        configurandoPerfis: false,
      };
    default:
      return state;
  }
};

export default usuarios;
