export const PESQUISAR_PRODUTOS_EM_PROMOCAO = 'PESQUISAR_PRODUTOS_EM_PROMOCAO';
export const PESQUISAR_PRODUTOS_EM_PROMOCAO_REQUISICAO = 'PESQUISAR_PRODUTOS_EM_PROMOCAO_REQUISICAO';
export const PESQUISAR_PRODUTOS_EM_PROMOCAO_SUCESSO = 'PESQUISAR_PRODUTOS_EM_PROMOCAO_SUCESSO';
export const PESQUISAR_PRODUTOS_EM_PROMOCAO_ERRO = 'PESQUISAR_PRODUTOS_EM_PROMOCAO_ERRO';

export const RECUPERAR_PROMOCOES_PRODUTO = 'RECUPERAR_PROMOCOES_PRODUTO';
export const RECUPERAR_PROMOCOES_PRODUTO_REQUISICAO = 'RECUPERAR_PROMOCOES_PRODUTO_REQUISICAO';
export const RECUPERAR_PROMOCOES_PRODUTO_SUCESSO = 'RECUPERAR_PROMOCOES_PRODUTO_SUCESSO';
export const RECUPERAR_PROMOCOES_PRODUTO_ERRO = 'RECUPERAR_PROMOCOES_PRODUTO_ERRO';

export const RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO = 'RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO';
export const RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO_REQUISICAO = 'RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO_REQUISICAO';
export const RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO_SUCESSO = 'RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO_SUCESSO';
export const RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO_ERRO = 'RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO_ERRO';

export const SUBSTITUIR_PRODUTO_EM_PROMOCAO = 'SUBSTITUIR_PRODUTO_EM_PROMOCAO';
export const SETAR_PRODUTO_SELECIONADO = 'SETAR_PRODUTO_SELECIONADO';
