export const NAME = 'produtoImagens';
export const TAB_PAGE_IMAGENS_PRODUTO = 'TAB_PAGE_IMAGENS_PRODUTO';
export const INITIAL_REDUX_STATE = {
  uploadingImagemProduto: false,
  atachandoImagem: false,
  atualizando: false,
  pesquisando: false,
  produtoImagens: {
    itens: {},
    pagination: {},
  },
  pesquisandoImagensSelecao: false,
  produtoImagensSelecao: [],
  imagemSelecionada: undefined,
  recuperando: false,
  promocoesProduto: [],
  deletando: false,
};

export const TABS_ID = {
  upload: 'TAB_UPLOAD',
  bancoImagem: 'TAB_BANCO_IMAGEM',
  imagensPrivadas: 'TAB_IMAGENS_TENANT',
};

const FEATURE = 'SG_CADASTROS_PRODUTOS_IMAGENS';
const PERMISSAO_COMUM = {
  module: 'SG_CADASTROS',
  feature: FEATURE,
};

export const PERMISSAO_PRODUTOS_IMAGENS = {
  CONSULTAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CONSULTAR`,
  },
  VISUALIZAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_VISUALIZAR`,
  },
  INCLUIR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_INCLUIR`,
  },
  EDITAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_EDITAR`,
  },
  EXCLUIR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_EXCLUIR`,
  },
};
