import _ from 'lodash';
import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

let result;
const ePromoNotificacoes = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_NOTIFICACOES_REQUISICAO:
      return {
        ...state, pesquisando: true, itens: {},
      };
    case actionTypes.PESQUISAR_NOTIFICACOES_SUCESSO:
      return {
        ...state,
        pesquisando: false,
        itens: _.mapKeys(action.payload.data.content, 'id'),
      };

    case actionTypes.CRIAR_NOTIFICACAO_REQUISICAO:
    case actionTypes.ATUALIZAR_NOTIFICACAO_REQUISICAO:
      return { ...state, salvando: true };

    case actionTypes.CRIAR_NOTIFICACAO_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state, salvando: false, itens: { ...state.itens, [result.id]: result },
      };
    case actionTypes.ATUALIZAR_NOTIFICACAO_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state,
        salvando: false,
        itens: { ...state.itens, [result.id]: result },
        current: result,
      };

    case actionTypes.RECUPERAR_NOTIFICACAO_REQUISICAO:
      return {
        ...state,
        recuperando: true,
        current: undefined,
      };
    case actionTypes.RECUPERAR_NOTIFICACAO_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state,
        recuperando: false,
        current: result,
        // Não precisaria desta linha caso seja implementado a notificação quando o processo de
        // publicação for concluido
        itens: { ...state.itens, [result.id]: result },
      };

    case actionTypes.ENVIAR_PARA_PUBLICACAO_REQUISICAO:
      return {
        ...state, enviandoParaPublicacao: true,
      };
    case actionTypes.ENVIAR_PARA_PUBLICACAO_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state,
        enviandoParaPublicacao: false,
        itens: { ...state.itens, [result.id]: result },
        current: result,
      };

    case actionTypes.CANCELAR_NOTIFICACAO_REQUISICAO:
      return {
        ...state, cancelando: true,
      };
    case actionTypes.CANCELAR_NOTIFICACAO_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state,
        cancelando: false,
        itens: { ...state.itens, [result.id]: result },
        current: result,
      };

    case actionTypes.CRIAR_NOTIFICACAO_ERRO:
    case actionTypes.ATUALIZAR_NOTIFICACAO_ERRO:
    case actionTypes.PESQUISAR_NOTIFICACOES_ERRO:
    case actionTypes.RECUPERAR_NOTIFICACAO_ERRO:
    case actionTypes.ENVIAR_PARA_PUBLICACAO_ERRO:
    case actionTypes.CANCELAR_NOTIFICACAO_ERRO:
      return {
        ...state,
        pesquisando: false,
        recuperando: false,
        salvando: false,
        enviandoParaPublicacao: false,
        cancelando: false,
      };
    default:
      return state;
  }
};

export default ePromoNotificacoes;
