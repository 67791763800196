import * as actionTypes from './actionTypes';
import { PERMISSAO_USUARIOS } from './constants';

export function criarUsuario(data) {
  return {
    type: actionTypes.CRIAR_USUARIO,
    payload: {
      request: {
        data,
        url: 'seguranca/usuarios',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'usuario.mensagens.post.httpSucesso',
        },
        failure: {
          key: 'usuario.mensagens.post.httpFalha',
        },
      },
    },
  };
}

export function pesquisarUsuarios(data = {}) {
  return {
    type: actionTypes.PESQUISAR_USUARIOS,
    payload: {
      request: {
        data,
        url: 'seguranca/usuarios/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: false,
        failure: {
          key: 'usuario.mensagens.getAll.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_USUARIOS.CONSULTAR,
    },
  };
}

export function recuperarUsuario(id) {
  return {
    type: actionTypes.RECUPERAR_USUARIO,
    payload: {
      request: {
        url: `seguranca/usuarios/${id}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: false,
        failure: {
          key: 'usuario.mensagens.get.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_USUARIOS.CONSULTAR,
    },
  };
}

export function configurarPerfisUsuario(id, data) {
  return {
    type: actionTypes.CONFIGURAR_PERFIS_USUARIO,
    payload: {
      request: {
        data,
        url: `seguranca/usuarios/${id}/perfis`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'usuario.mensagens.configurarPerfis.httpSucesso',
        },
        failure: {
          key: 'usuario.mensagens.configurarPerfis.httpFalha',
        },
      },
    },
  };
}

export function atualizarUsuario(data, id) {
  return {
    type: actionTypes.ATUALIZAR_USUARIO,
    payload: {
      request: {
        data,
        url: `seguranca/usuarios/${id}`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'usuario.mensagens.put.httpSucesso',
        },
        failure: {
          key: 'usuario.mensagens.put.httpFalha',
        },
      },
    },
  };
}

export function deletarUsuario(id) {
  return {
    type: actionTypes.DELETAR_USUARIO,
    payload: {
      request: {
        url: `seguranca/usuarios/${id}`,
        method: 'DELETE',
      },
      recordId: id,
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'usuario.mensagens.delete.httpSucesso',
        },
        failure: {
          key: 'usuario.mensagens.delete.httpFalha',
        },
      },
    },
  };
}

export function recuperarPerfisUsuario(id) {
  return {
    type: actionTypes.RECUPERAR_PERFIS_USUARIO,
    payload: {
      request: {
        url: `seguranca/usuarios/${id}/perfis/vinculados`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: false,
        failure: {
          key: 'usuario.mensagens.carregarPerfis.httpFalha',
        },
      },
    },
  };
}

export function verificarUsuarioExistentePorEmail(email) {
  return {
    type: actionTypes.VERIFICAR_USUARIO_EXISTENTE_POR_EMAIL,
    payload: {
      request: {
        url: `/seguranca/usuarios/email/${email}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: false,
        failure: {
          key: 'usuario.mensagens.verificacaoUsuarioExistente.httpFalha',
        },
      },
    },
  };
}

export function associarUsuarioAoParceiro(data, idUsuario, idParceiro) {
  return {
    type: actionTypes.ASSOCIAR_USUARIO_AO_PARCEIRO,
    payload: {
      request: {
        data,
        url: `/seguranca/usuarios/${idUsuario}/parceiros/${idParceiro}/incluir`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'usuario.mensagens.usuarioAssociado.httpSucesso',
        },
        failure: {
          key: 'usuario.mensagens.usuarioAssociado.httpFalha',
        },
      },
    },
  };
}
