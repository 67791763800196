import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import ModalForm from 'linear-react-components-ui/lib/dialog/form';
import Icon from 'linear-react-components-ui/lib/icons';
import Button, { PrimaryButton, SuccessButton } from 'linear-react-components-ui/lib/buttons';
import Fieldset from 'linear-react-components-ui/lib/fieldset';
import TextArea from 'linear-react-components-ui/lib/inputs/textarea';
import LabelMessages from 'linear-react-components-ui/lib/labelMessages';

class Confirmacao extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    const { downloadRequisitadoComSucesso } = props;
    if (downloadRequisitadoComSucesso !== state.requisitado) {
      return {
        requisitado: downloadRequisitadoComSucesso,
      };
    }
    return null;
  }

  state = {
    destinatarios: '',
    requisitado: this.props.downloadRequisitadoComSucesso,
  }

  confirmar = () => {
    const { destinatarios } = this.state;
    let emails = [];
    if (destinatarios.trim() !== '') {
      destinatarios.split(',').forEach((d) => {
        if (d.trim() !== '') emails = [...emails, { email: d.trim() }];
      });
    }
    this.props.handlerRequisitarDownload(emails);
  }

  renderizarButtons = () => {
    const { t, handlerClose, solicitandoDownloadLote } = this.props;
    const { requisitado } = this.state;
    if (!requisitado) {
      return ([
        <PrimaryButton
          key="botao-confirmar"
          label={t('commons:buttons.confirmar')}
          onClick={() => { this.confirmar(); }}
          disabled={solicitandoDownloadLote}
          isLoading={solicitandoDownloadLote} />,
        <Button
          key="botao-cancelar"
          label={t('commons:buttons.cancelar')}
          onClick={() => { handlerClose(); }} />,
      ]);
    }
    return ([
      <Button
        key="botao-fechar"
        label={t('commons:buttons.fechar')}
        onClick={() => { handlerClose(); }} />,
    ]);
  }

  render() {
    const { t, handlerClose, handlerExibirDownload } = this.props;
    const { requisitado } = this.state;
    return (
      <ModalForm
        title={t('documentosEletronicos:downloadLote.labels.tituloModal')}
        width="550px"
        height={requisitado ? '300px' : '540px'}
        icon={<Icon name="file_download" color="#A9A8A8" size={24} />}
        handlerClose={handlerClose}
        closeOnEsc
        styleForContent={{ padding: '10px 0 0 0' }}
        buttons={this.renderizarButtons()}>
        {requisitado ? (
          <Fragment>
            <LabelMessages type="info" style={{ marginBottom: 10 }}>
              {t('documentosEletronicos:downloadLote.labels.mensagemSucessoRequisicao')}
            </LabelMessages>
            <div>
              <SuccessButton
                label={t('documentosEletronicos:downloadLote.labels.linkAcompnharDownload')}
                onClick={() => { handlerExibirDownload(); }} />
            </div>
          </Fragment>
        ) : (
          <Fieldset
            title={t('documentosEletronicos:downloadLote.labels.emailInfo')}
            gridCols="12 12 12 12"
            style={{ padding: '10px' }}>
            <LabelMessages type="info" style={{ marginBottom: 10 }}>
              {t('documentosEletronicos:downloadLote.labels.info')}
            </LabelMessages>
            <LabelMessages type="warning" style={{ marginBottom: 10 }}>
              {t('documentosEletronicos:downloadLote.labels.importante')}
            </LabelMessages>
            <TextArea
              name="confirmacaoSenha"
              label={t('documentosEletronicos:downloadLote.campos.destinatarios')}
              hint={t('documentosEletronicos:downloadLote.labels.destinatarioHint')}
              rows="5"
              onChange={(e) => { this.setState({ destinatarios: e.target.value }); }} />
          </Fieldset>
        )}
      </ModalForm>
    );
  }
}

Confirmacao.propTypes = {
  t: PropTypes.func.isRequired,
  handlerClose: PropTypes.func.isRequired,
  handlerRequisitarDownload: PropTypes.func.isRequired,
  handlerExibirDownload: PropTypes.func.isRequired,
  solicitandoDownloadLote: PropTypes.bool.isRequired,
  downloadRequisitadoComSucesso: PropTypes.bool.isRequired,
};

export default Confirmacao;
