import React from 'react';
import * as constants from './constants';
import reducer from './reducer';
import * as actions from './actions';

const Container = React.lazy(() => import('./components/Container'));

export default {
  Container,
  reducer,
  constants,
  actions,
};
