import * as actionTypes from './actionTypes';
import { MODOS_VISUALIZACAO_COMPARATIVOS } from './constants';

export function recuperarQuantidadeProdutosVigentes(data) {
  return {
    type: actionTypes.RECUPERAR_QUANTIDADE_PRODUTOS_VIGENTES,
    payload: {
      request: {
        data,
        url: 'app/promocoes/quantidade-produtos-vigentes-por-tipo',
        method: 'POST',
      },
      feedbackMessages: {
        success: false,
      },
    },
  };
}

export function recuperarResumoVendasPorTurno(data) {
  return {
    type: actionTypes.RECUPERAR_RESUMO_VENDAS_POR_TURNO,
    payload: {
      request: {
        data,
        url: 'vendas/pdv/consultas/resumo-por-turno',
        method: 'POST',
      },
      feedbackMessages: {
        success: false,
      },
    },
  };
}

export function recuperarResumoVendasPorData(data) {
  return {
    type: actionTypes.RECUPERAR_RESUMO_VENDAS_POR_DATA,
    payload: {
      request: {
        data,
        url: 'vendas/pdv/consultas/resumo-por-data',
        method: 'POST',
      },
      feedbackMessages: {
        success: false,
      },
    },
  };
}

export function recuperarResumoVendasPorEmpresa(data) {
  return {
    type: actionTypes.RECUPERAR_RESUMO_VENDAS_POR_EMPRESA,
    payload: {
      request: {
        data,
        url: 'vendas/pdv/consultas/resumo-por-empresa',
        method: 'POST',
      },
      feedbackMessages: {
        success: false,
      },
    },
  };
}

export function recuperarTicketsMedio(data, agrupado = false) {
  return {
    type: actionTypes.RECUPERAR_TICKET_MEDIO,
    agrupado,
    payload: {
      request: {
        data,
        url: 'vendas/pdv/consultas/ticket-medio',
        method: 'POST',
      },
      feedbackMessages: {
        success: false,
      },
    },
  };
}

const endPoints = {
  [MODOS_VISUALIZACAO_COMPARATIVOS.porProdutosUnicos]: 'por-produtos',
  [MODOS_VISUALIZACAO_COMPARATIVOS.porQuantidadeTotal]: 'por-quantidade',
  [MODOS_VISUALIZACAO_COMPARATIVOS.porValorTotal]: 'por-valor',
};

export function recuperarComparativoTipoPromocaoGeral(modoVisualizacao, data, agrupado = false) {
  return {
    type: actionTypes.RECUPERAR_COMPARATIVO_TIPO_PROMOCAO_GERAL,
    modoVisualizacao,
    agrupado,
    payload: {
      request: {
        data,
        url: `vendas/pdv/consultas/comparativo-tipo-promocao/${endPoints[modoVisualizacao]}`,
        method: 'POST',
      },
      feedbackMessages: {
        success: false,
      },
    },
  };
}

export function recuperarComparativoClientesVendasGeral(modoVisualizacao, data, agrupado = false) {
  return {
    type: actionTypes.RECUPERAR_COMPARATIVO_CLIENTES_VENDAS_GERAL,
    modoVisualizacao,
    agrupado,
    payload: {
      request: {
        data,
        url: `vendas/pdv/consultas/comparativo-clientes-vendas/${endPoints[modoVisualizacao]}`,
        method: 'POST',
      },
      feedbackMessages: {
        success: false,
      },
    },
  };
}

export function recuperarQuantidadeClientesPorCadastro(data) {
  return {
    type: actionTypes.RECUPERAR_QUANTIDADE_CLIENTES_POR_DATA_CADASTRO,
    payload: {
      request: {
        data,
        url: 'app/clientes/quantidade-por-data-cadastro',
        method: 'POST',
      },
      feedbackMessages: {
        success: false,
      },
    },
  };
}

export function recuperarQuantidadeTotalClientes(data, agrupado = false) {
  return {
    type: actionTypes.RECUPERAR_QUANTIDADE_TOTAL_CLIENTES,
    agrupado,
    payload: {
      request: {
        data,
        url: 'app/clientes/quantidade-total',
        method: 'POST',
      },
      feedbackMessages: {
        success: false,
      },
    },
  };
}
