import React from 'react';
import { E_PROMO } from '@linear-front-end/infra/lib/projectModules';

export const NAME = 'urlLojas';
export const TAB_PAGE_URL_LOJAS = 'TAB_PAGE_URL_LOJAS';

export const INITIAL_REDUX_STATE = {
  urlsLojas: {},
  recuperando: false,
  salvando: false,
};

export const TIPO_URL = {
  eCommerce: 1,
  site: 2,
};

export const UrlLojasContext = React.createContext();

const FEATURE = `${E_PROMO}_CONFIGURACOES_URL_LOJAS`;
const PERMISSAO_COMUM = { module: E_PROMO, feature: FEATURE };

export const PERMISSAO_URL_LOJAS = {
  CONSULTAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CONSULTAR`,
  },
  CONFIGURAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CONFIGURAR`,
  },
};
