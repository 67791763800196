import React from 'react';
import { E_PROMO } from '@linear-front-end/infra/lib/projectModules';

export const NAME = 'banner';
export const TAB_PAGE_BANNERS = 'TAB_PAGE_BANNERS';

export const INITIAL_REDUX_STATE = {
  itens: {},
  pesquisando: false,
  recuperando: false,
  salvando: false,
  current: undefined,
  deletando: false,
  defaultData: {
    descricao: '',
    dataExpiracao: '',
    imagem: '',
    permanente: false,
  },
};

export const BannerContext = React.createContext();

const FEATURE = `${E_PROMO}_CONFIGURACOES_BANNER`;
const PERMISSAO_COMUM = { module: E_PROMO, feature: FEATURE };

export const PERMISSAO_BANNER = {
  INCLUIR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_INCLUIR`,
  },
  EDITAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_EDITAR`,
  },
  CONSULTAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CONSULTAR`,
  },
  VISUALIZAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_VISUALIZAR`,
  },
  EXCLUIR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_EXCLUIR`,
  },
};
