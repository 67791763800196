export const CRIAR_PARCEIRO = 'CRIAR_PARCEIRO';
export const CRIAR_PARCEIRO_REQUISICAO = 'CRIAR_PARCEIRO_REQUISICAO';
export const CRIAR_PARCEIRO_SUCESSO = 'CRIAR_PARCEIRO_SUCESSO';
export const CRIAR_PARCEIRO_ERRO = 'CRIAR_PARCEIRO_ERRO';

export const ATIVAR_PARCEIRO = 'ATIVAR_PARCEIRO';
export const ATIVAR_PARCEIRO_REQUISICAO = 'ATIVAR_PARCEIRO_REQUISICAO';
export const ATIVAR_PARCEIRO_SUCESSO = 'ATIVAR_PARCEIRO_SUCESSO';
export const ATIVAR_PARCEIRO_ERRO = 'ATIVAR_PARCEIRO_ERRO';

export const INATIVAR_PARCEIRO = 'INATIVAR_PARCEIRO';
export const INATIVAR_PARCEIRO_REQUISICAO = 'INATIVAR_PARCEIRO_REQUISICAO';
export const INATIVAR_PARCEIRO_SUCESSO = 'INATIVAR_PARCEIRO_SUCESSO';
export const INATIVAR_PARCEIRO_ERRO = 'INATIVAR_PARCEIRO_ERRO';

export const RESTAURAR_PARCEIRO = 'RESTAURAR_PARCEIRO';
export const RESTAURAR_PARCEIRO_REQUISICAO = 'RESTAURAR_PARCEIRO_REQUISICAO';
export const RESTAURAR_PARCEIRO_SUCESSO = 'RESTAURAR_PARCEIRO_SUCESSO';
export const RESTAURAR_PARCEIRO_ERRO = 'RESTAURAR_PARCEIRO_ERRO';

export const PESQUISAR_PARCEIROS = 'PESQUISAR_PARCEIROS';
export const PESQUISAR_PARCEIROS_REQUISICAO = 'PESQUISAR_PARCEIROS_REQUISICAO';
export const PESQUISAR_PARCEIROS_SUCESSO = 'PESQUISAR_PARCEIROS_SUCESSO';
export const PESQUISAR_PARCEIROS_ERRO = 'PESQUISAR_PARCEIROS_ERRO';

export const RECUPERAR_PARCEIRO = 'RECUPERAR_PARCEIRO';
export const RECUPERAR_PARCEIRO_REQUISICAO = 'RECUPERAR_PARCEIRO_REQUISICAO';
export const RECUPERAR_PARCEIRO_SUCESSO = 'RECUPERAR_PARCEIRO_SUCESSO';
export const RECUPERAR_PARCEIRO_ERRO = 'RECUPERAR_PARCEIRO_ERRO';

export const ATUALIZAR_PARCEIRO = 'ATUALIZAR_PARCEIRO';
export const ATUALIZAR_PARCEIRO_REQUISICAO = 'ATUALIZAR_PARCEIRO_REQUISICAO';
export const ATUALIZAR_PARCEIRO_SUCESSO = 'ATUALIZAR_PARCEIRO_SUCESSO';
export const ATUALIZAR_PARCEIRO_ERRO = 'ATUALIZAR_PARCEIRO_ERRO';

export const DELETAR_PARCEIRO = 'DELETAR_PARCEIRO';
export const DELETAR_PARCEIRO_REQUISICAO = 'DELETAR_PARCEIRO_REQUISICAO';
export const DELETAR_PARCEIRO_SUCESSO = 'DELETAR_PARCEIRO_SUCESSO';
export const DELETAR_PARCEIRO_ERRO = 'DELETAR_PARCEIRO_ERRO';

export const RECUPERAR_CARACTERISTICAS_PARCEIRO = 'RECUPERAR_CARACTERISTICAS_PARCEIRO';
export const RECUPERAR_CARACTERISTICAS_PARCEIRO_REQUISICAO = 'RECUPERAR_CARACTERISTICAS_PARCEIRO_REQUISICAO';
export const RECUPERAR_CARACTERISTICAS_PARCEIRO_SUCESSO = 'RECUPERAR_CARACTERISTICAS_PARCEIRO_SUCESSO';
export const RECUPERAR_CARACTERISTICAS_PARCEIRO_ERRO = 'RECUPERAR_CARACTERISTICAS_PARCEIRO_ERRO';

export const ATUALIZAR_COLABORADOR_APOS_CRIAR_USUARIO = 'ATUALIZAR_COLABORADOR_APOS_CRIAR_USUARIO';
