import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

const documentosEletronicos = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_DOCUMENTOS_ELETRONICOS_REQUISICAO:
      return { ...state, pesquisando: true };
    case actionTypes.PESQUISAR_DOCUMENTOS_ELETRONICOS_SUCESSO:
      return {
        ...state, pesquisando: false, itens: action.payload.data.content,
      };

    case actionTypes.RECUPERAR_DOCUMENTO_ELETRONICO_REQUISICAO:
      return { ...state, recuperando: true, current: {} };
    case actionTypes.RECUPERAR_DOCUMENTO_ELETRONICO_SUCESSO:
      return {
        ...state, recuperando: false, current: action.payload.data.content,
      };

    case actionTypes.RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO_REQUISICAO:
      return { ...state, recuperandoEventos: true, eventos: [] };
    case actionTypes.RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO_SUCESSO:
      return {
        ...state, recuperandoEventos: false, eventos: action.payload.data.content,
      };

    case actionTypes.RECUPERAR_CONTEUDO_DOCUMENTO_REQUISICAO:
      return {
        ...state, recuperandoConteudo: true, idDocumentoConteudo: action.payload.request.data.id,
      };

    case actionTypes.RECUPERAR_CONTEUDO_DOCUMENTO_SUCESSO:
      return {
        ...state, recuperandoConteudo: false,
      };

    case actionTypes.DOWNLOAD_LOTE_XML_NOTAS_FISCAIS_REQUISICAO:
      return {
        ...state, solicitandoDownloadLote: true,
      };

    case actionTypes.DOWNLOAD_LOTE_XML_NOTAS_FISCAIS_SUCESSO:
      return {
        ...state, solicitandoDownloadLote: false,
      };

    case actionTypes.PESQUISAR_DOCUMENTOS_ELETRONICOS_ERRO:
    case actionTypes.RECUPERAR_DOCUMENTO_ELETRONICO_ERRO:
    case actionTypes.RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO_ERRO:
    case actionTypes.RECUPERAR_CONTEUDO_DOCUMENTO_ERRO:
    case actionTypes.DOWNLOAD_LOTE_XML_NOTAS_FISCAIS_ERRO:
      return {
        ...state,
        pesquisando: false,
        recuperando: false,
        recuperandoEventos: false,
        recuperandoConteudo: false,
        solicitandoDownloadLote: false,
      };
    default:
      return state;
  }
};

export default documentosEletronicos;
