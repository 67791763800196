import _ from 'lodash';
import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

let result;
const banner = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_BANNERS_REQUISICAO:
      return {
        ...state, pesquisando: true, itens: {},
      };
    case actionTypes.PESQUISAR_BANNERS_SUCESSO:
      return {
        ...state,
        pesquisando: false,
        itens: _.mapKeys(action.payload.data, 'id'),
      };

    case actionTypes.CRIAR_BANNER_REQUISICAO:
    case actionTypes.ATUALIZAR_BANNER_REQUISICAO:
      return { ...state, salvando: true };

    case actionTypes.CRIAR_BANNER_SUCESSO:
      result = action.payload.data;
      return {
        ...state, salvando: false, itens: { ...state.itens, [result.id]: result },
      };
    case actionTypes.ATUALIZAR_BANNER_SUCESSO:
      result = action.payload.data;
      return {
        ...state,
        salvando: false,
        itens: { ...state.itens, [result.id]: result },
        current: result,
      };

    case actionTypes.RECUPERAR_BANNER_REQUISICAO:
      return {
        ...state,
        recuperando: true,
        current: undefined,
      };
    case actionTypes.RECUPERAR_BANNER_SUCESSO:
      result = action.payload.data;
      return {
        ...state, recuperando: false, current: result,
      };

    case actionTypes.DELETAR_BANNER_REQUISICAO:
      return {
        ...state, deletando: true,
      };
    case actionTypes.DELETAR_BANNER_SUCESSO:
      result = action.payload.data;
      return {
        ...state, deletando: false, itens: _.omit(state.itens, result),
      };

    case actionTypes.CRIAR_BANNER_ERRO:
    case actionTypes.ATUALIZAR_BANNER_ERRO:
    case actionTypes.PESQUISAR_BANNERS_ERRO:
    case actionTypes.RECUPERAR_BANNER_ERRO:
    case actionTypes.DELETAR_BANNER_ERRO:
      return {
        ...state,
        pesquisando: false,
        recuperando: false,
        salvando: false,
        recuperandoCaracteristicas: false,
      };
    default:
      return state;
  }
};

export default banner;
