/**
 * Um usuário sempre será gerado a partir de um Parceiro, podendo ser uma derivação
 * de um Contato ou Colaborador
 */
import React, { Component } from 'react';
import ModalForm from 'linear-react-components-ui/lib/dialog/form';
import { SaveButton, CancelButton } from 'linear-react-components-ui/lib/buttons';
import Form from 'linear-react-components-ui/lib/form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as selectors from '../../selectors';
import * as actions from '../../actions';
import Campos from './Campos';
import parceiros from '../../../../cadastros/parceiros';
import contatos from '../../../../cadastros/contatos';

class FormUsuario extends Component {
  constructor(props) {
    super(props);
    const {
      usuario, defaultData, parceiro, adicionando,
    } = props;
    let data = usuario || defaultData;
    if (adicionando) {
      data = {
        ...data,
        nome: parceiro.pessoaFisica.nome,
        // parceiro."idParceiro" somente quando o parceiro vem do contato
        idParceiro: parceiro.idParceiro || parceiro.id,
        credencialLocal: {
          email: parceiro.email,
          nomeUsuario: this.comporNomeUsuario(),
        },
      };
    }
    this.state = {
      data: { ...data },
      formValido: false,
      usuarioCadastrado: undefined,
      verificandoUsuario: false,
      perfilIds: [],
    };
  }

  componentDidMount() {
    const { email } = this.props.parceiro;
    this.verificarUsuarioExistente(email);
  }

  verificarUsuarioExistente = (email) => {
    if (email && email.trim() !== '') {
      this.props.verificarUsuarioExistentePorEmail(email)
        .then((response) => {
          const { data } = response.payload;
          if (data) {
            this.setState({ verificandoUsuario: false, usuarioCadastrado: data.content });
          } else {
            this.setState({ verificandoUsuario: false, usuarioCadastrado: undefined });
          }
        })
        .catch(() => {
          this.setState({ verificandoUsuario: false, usuarioCadastrado: undefined });
        });
      this.setState({ verificandoUsuario: true });
    }
  }

  comporNomeUsuario = () => {
    const { pessoaFisica } = this.props.parceiro;
    const userName = `${pessoaFisica.nome}.${pessoaFisica.sobreNome}`;
    return userName.replace(' ', '').toLowerCase();
  }

  salvar = (data) => {
    const { adicionando, criarUsuario, atualizarUsuario } = this.props;
    const action = adicionando ? criarUsuario : atualizarUsuario;
    action(data, data.id).then((response) => {
      const { id } = response.payload.data.content;
      // Caso esteja criando um usuário através de um contato, após concluir,
      // deverá atualizar a propriedade possuiUsuario para true.
      // Existe um tratamento na listagem de contatos para não permitir entrar
      // novamente na tela de geração do usuário caso já seja um.
      this.finaliarGravacao(id);
    });
  }

  associar = () => {
    const { associarUsuarioAoParceiro, parceiro } = this.props;
    const { perfilIds, usuarioCadastrado } = this.state;
    const idParceiro = parceiro.idParceiro || parceiro.id;
    associarUsuarioAoParceiro(perfilIds, usuarioCadastrado.id, idParceiro)
      .then(() => { this.finaliarGravacao(usuarioCadastrado.id); });
  }

  finaliarGravacao = (idUsuario) => {
    const {
      adicionando, handlerFecharModal, parceiro, atualizarContatoAposCriarUsuario, idContato,
      atualizarColaboradorAposCriarUsuario,
    } = this.props;
    if (adicionando &&
        parceiro.caracteristicas.includes(parceiros.constants.CARACTERISTICAS.contato)) {
      atualizarContatoAposCriarUsuario(idContato);
    } else {
      atualizarColaboradorAposCriarUsuario(parceiro.id, idUsuario);
    }
    handlerFecharModal();
  }

  render() {
    const { formValido, usuarioCadastrado } = this.state;
    const {
      handlerFecharModal, salvando, t, editando,
    } = this.props;
    return (
      <ModalForm
        title={t(`seguranca:usuario.modalTitulo${editando ? 'Alteracao' : 'Inclusao'}`)}
        width="960px"
        height="98%"
        handlerClose={() => { handlerFecharModal(); }}
        closeOnEsc
        buttons={[
          !usuarioCadastrado ? (
            <SaveButton
              key="botao-salvar"
              label={t('commons:buttons.salvar')}
              onClick={() => { this.handlerSubmit(); }}
              isLoading={salvando}
              disabled={salvando || !formValido} />
          ) : (
            <SaveButton
              key="botao-associar"
              label={t('commons:buttons.associar')}
              onClick={() => { this.associar(); }}
              isLoading={salvando}
              disabled={salvando || !formValido} />
          ),
          <CancelButton
            key="botao-cancelar"
            label={t('commons:buttons.cancelar')}
            onClick={() => { handlerFecharModal(); }}
            disabled={salvando} />,
        ]}>
        <Form
          dataSource={this.state.data}
          onSubmit={(jsonData) => { this.salvar(jsonData); }}
          onValidateForm={(valido) => { this.setState({ formValido: valido }); }}
          handlerSubmit={(handler) => { this.handlerSubmit = handler; }}>
          <Campos
            handlerFieldChange={this.onFieldChange}
            handlerAtualizarPerfis={(perfilIds) => { this.setState({ perfilIds }); }}
            handlerVerificarUsuarioExistente={this.verificarUsuarioExistente}
            {...this.props}
            {...this.state} />
        </Form>
      </ModalForm>
    );
  }
}

FormUsuario.propTypes = {
  defaultData: PropTypes.object.isRequired,
  salvando: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  handlerFecharModal: PropTypes.func.isRequired,
  parceiro: PropTypes.object.isRequired,
  atualizarUsuario: PropTypes.func.isRequired,
  criarUsuario: PropTypes.func.isRequired,
  atualizarContatoAposCriarUsuario: PropTypes.func.isRequired,
  verificarUsuarioExistentePorEmail: PropTypes.func.isRequired,
  associarUsuarioAoParceiro: PropTypes.func.isRequired,
  atualizarColaboradorAposCriarUsuario: PropTypes.func.isRequired,
  usuario: PropTypes.object,
  editando: PropTypes.bool,
  adicionando: PropTypes.bool,
  idContato: PropTypes.number,
};

FormUsuario.defaultProps = {
  usuario: undefined,
  editando: false,
  adicionando: false,
  idContato: undefined,
};

const mapStateToProps = state => ({
  salvando: selectors.obterSalvando(state),
  defaultData: selectors.getDefaultData(state),
});

const connectRedux = connect(mapStateToProps, {
  ...actions,
  atualizarContatoAposCriarUsuario: contatos.actions.atualizarContatoAposCriarUsuario,
  atualizarColaboradorAposCriarUsuario: parceiros.actions.atualizarColaboradorAposCriarUsuario,
});

export default connectRedux(FormUsuario);
