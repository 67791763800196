import _ from 'lodash';
import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

const urlLoja = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.RECUPERAR_URL_LOJAS_REQUISICAO:
      return { ...state, recuperando: true, urlsLojas: {} };
    case actionTypes.RECUPERAR_URL_LOJAS_SUCESSO:
      return {
        ...state,
        recuperando: false,
        urlsLojas: _.mapKeys(action.payload.data.content, 'cnpj'),
      };

    case actionTypes.CONFIGURAR_URL_LOJAS_REQUISICAO:
      return { ...state, salvando: true };
    case actionTypes.CONFIGURAR_URL_LOJAS_SUCESSO: {
      const urlsLojas = { ...state.urlsLojas };
      const result = action.payload.data.content;
      result.forEach((url) => { urlsLojas[url.cnpj] = url; });
      return { ...state, salvando: false, urlsLojas };
    }
    case actionTypes.RECUPERAR_URL_LOJAS_ERRO:
    case actionTypes.CONFIGURAR_URL_LOJAS_ERRO:
      return { ...state, salvando: false, recuperando: false };

    default:
      return state;
  }
};

export default urlLoja;
