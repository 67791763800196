"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RECUPERAR_FUNCIONALIDADES_ERRO = exports.RECUPERAR_FUNCIONALIDADES_SUCESSO = exports.RECUPERAR_FUNCIONALIDADES_REQUISICAO = exports.RECUPERAR_FUNCIONALIDADES = exports.RECUPERAR_MODULOS_ERRO = exports.RECUPERAR_MODULOS_SUCESSO = exports.RECUPERAR_MODULOS_REQUISICAO = exports.RECUPERAR_MODULOS = void 0;
var RECUPERAR_MODULOS = 'RECUPERAR_MODULOS';
exports.RECUPERAR_MODULOS = RECUPERAR_MODULOS;
var RECUPERAR_MODULOS_REQUISICAO = 'RECUPERAR_MODULOS_REQUISICAO';
exports.RECUPERAR_MODULOS_REQUISICAO = RECUPERAR_MODULOS_REQUISICAO;
var RECUPERAR_MODULOS_SUCESSO = 'RECUPERAR_MODULOS_SUCESSO';
exports.RECUPERAR_MODULOS_SUCESSO = RECUPERAR_MODULOS_SUCESSO;
var RECUPERAR_MODULOS_ERRO = 'RECUPERAR_MODULOS_ERRO';
exports.RECUPERAR_MODULOS_ERRO = RECUPERAR_MODULOS_ERRO;
var RECUPERAR_FUNCIONALIDADES = 'RECUPERAR_FUNCIONALIDADES';
exports.RECUPERAR_FUNCIONALIDADES = RECUPERAR_FUNCIONALIDADES;
var RECUPERAR_FUNCIONALIDADES_REQUISICAO = 'RECUPERAR_FUNCIONALIDADES_REQUISICAO';
exports.RECUPERAR_FUNCIONALIDADES_REQUISICAO = RECUPERAR_FUNCIONALIDADES_REQUISICAO;
var RECUPERAR_FUNCIONALIDADES_SUCESSO = 'RECUPERAR_FUNCIONALIDADES_SUCESSO';
exports.RECUPERAR_FUNCIONALIDADES_SUCESSO = RECUPERAR_FUNCIONALIDADES_SUCESSO;
var RECUPERAR_FUNCIONALIDADES_ERRO = 'RECUPERAR_FUNCIONALIDADES_ERRO';
exports.RECUPERAR_FUNCIONALIDADES_ERRO = RECUPERAR_FUNCIONALIDADES_ERRO;