import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import SpinnerLoading from 'linear-react-components-ui/lib/spinner';
import Table, { TRow, TBody, Td, Th, THeader } from 'linear-react-components-ui/lib/table';
import Panel, { PanelContent, PanelHeader } from 'linear-react-components-ui/lib/panel';
import ToolBar, { ToolBarGroup, ButtonBar } from 'linear-react-components-ui/lib/toolbar';
import SearchField from 'linear-react-components-ui/lib/inputs/search';
import Icon from 'linear-react-components-ui/lib/icons';
import Checkbox from 'linear-react-components-ui/lib/checkbox';
import * as actions from '../actions';
import * as selectors from '../selectors';

const TenantsAppSelecaoContainer = (props) => {
  const {
    recuperarTenantsDisponiveisAPP, handlerMudarSelecionados, showToolbar, tenantsApp, recuperando,
    t, showTableHeader, showTableBorder, showRowBorder,
  } = props;
  const [tenants, setTenants] = useState(undefined);
  const [state, setState] = useState({
    idsSelecionados: props.idsSelecionados, idsFromProps: props.idsSelecionados,
  });
  const { idsSelecionados, idsFromProps } = state;

  useEffect(() => {
    recuperarTenantsDisponiveisAPP();
  }, []);

  useEffect(() => {
    const ids = props.idsSelecionados;
    if (idsFromProps.length !== ids.length) {
      setState({ idsSelecionados: ids, idsFromProps: ids });
    }
    if (!tenants && tenantsApp.length > 0) setTenants(tenantsApp);
  });

  const onCheckboxClick = (id) => {
    let ids = idsSelecionados;
    if (idsSelecionados.includes(id)) {
      ids = idsSelecionados.filter(item => item !== id);
    } else {
      ids = [...idsSelecionados, id];
    }
    setState({ ...state, idsSelecionados: ids });
    if (handlerMudarSelecionados) handlerMudarSelecionados(ids);
  };

  const checkAll = () => {
    setState({ ...state, idsSelecionados: tenants.map(m => m.id) });
  };

  const uncheckAll = () => {
    setState({ ...state, idsSelecionados: [] });
  };

  const search = (value = '') => {
    let itens = JSON.parse(JSON.stringify(tenantsApp));
    if (value.trim() !== '') {
      itens = itens.filter(tenant => (tenant.nome.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                                      tenant.cnpj.indexOf(value) > -1));
    }
    setTenants(itens);
  };

  const renderizarTenants = () => (
    <Fragment>
      <Table condensed selectable={false} bordered={showTableBorder}>
        {showTableHeader &&
          <THeader>
            <TRow>
              <Th />
              <Th>{t('commons:pessoas.juridica.nomeFantasia')}</Th>
            </TRow>
          </THeader>}
        <TBody>
          {tenants && tenants.map(tenant => (
            <TRow
              key={`tenant-${tenant.id}`}
              onClick={() => { onCheckboxClick(tenant.id); }}
              rowBorder={showRowBorder}>
              <Td style={{ width: 30 }}>
                <Checkbox checked={idsSelecionados.includes(tenant.id)} />
              </Td>
              <Td>{`${tenant.nome} (${tenant.cnpj})`}</Td>
            </TRow>
          ))}
        </TBody>
      </Table>
      <div>{recuperando && <SpinnerLoading />}</div>
    </Fragment>
  );

  const renderizarComToolbar = () => (
    <Panel style={{ height: 150, display: 'flex', flexDirection: 'column' }}>
      <PanelHeader
        title={t('commons:labels.grupoLojas')}
        styleForTitle={{ fontSize: 14 }}
        style={{ padding: '5px 0 5px 0' }}>
        <ToolBar>
          <ToolBarGroup style={{ float: 'right' }}>
            <SearchField
              onChange={(e) => { search(e.target.value); }}
              onReset={() => { search(); }}
              placeHolder={t('commons:pessoas.hintPesquisaNomeCnpj')} />
            <ButtonBar
              transparent
              size="small"
              iconAlign="left"
              icon={<Icon name="stackCheck" />}
              tooltip={t('commons:buttons.marcarTodos')}
              tooltipPosition="left"
              onClick={() => { checkAll(); }} />
            <ButtonBar
              transparent
              size="small"
              iconAlign="left"
              icon={<Icon name="stackCancel" />}
              tooltip={t('commons:buttons.desmarcarTodos')}
              tooltipPosition="left"
              onClick={() => { uncheckAll(); }} />
          </ToolBarGroup>
        </ToolBar>
      </PanelHeader>
      <PanelContent style={{ padding: 6 }}>
        {renderizarTenants()}
      </PanelContent>
    </Panel>
  );

  if (tenantsApp && tenantsApp.length <= 1) return <noscript />;
  return (
    showToolbar ? (
      renderizarComToolbar()
    ) : (
      renderizarTenants()
    )
  );
};

TenantsAppSelecaoContainer.propTypes = {
  recuperando: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  recuperarTenantsDisponiveisAPP: PropTypes.func.isRequired,
  tenantsApp: PropTypes.arrayOf(PropTypes.objectOf),
  idsSelecionados: PropTypes.arrayOf(PropTypes.number),
  handlerMudarSelecionados: PropTypes.func,
  showTableHeader: PropTypes.bool,
  showTableBorder: PropTypes.bool,
  showRowBorder: PropTypes.bool,
  showToolbar: PropTypes.bool,
};

TenantsAppSelecaoContainer.defaultProps = {
  tenantsApp: [],
  idsSelecionados: [],
  handlerMudarSelecionados: undefined, // Propriedade necessária para atender o FieldArray do form
  showTableHeader: true,
  showTableBorder: false,
  showRowBorder: true,
  showToolbar: false,
};

const mapStateToProps = state => ({
  recuperando: selectors.obterRecuperando(state),
  tenantsApp: selectors.obterTenantsApp(state),
});

const connectRedux = connect(mapStateToProps, { ...actions });
export default connectRedux(translate(['common', 'cadastros'])(TenantsAppSelecaoContainer));
