export const PESQUISAR_GRUPOS_VENDEDORES = 'PESQUISAR_GRUPOS_VENDEDORES';
export const PESQUISAR_GRUPOS_VENDEDORES_REQUISICAO = 'PESQUISAR_GRUPOS_VENDEDORES_REQUISICAO';
export const PESQUISAR_GRUPOS_VENDEDORES_SUCESSO = 'PESQUISAR_GRUPOS_VENDEDORES_SUCESSO';
export const PESQUISAR_GRUPOS_VENDEDORES_ERRO = 'PESQUISAR_GRUPOS_VENDEDORES_ERRO';

export const RECUPERAR_GRUPO_VENDEDOR = 'RECUPERAR_GRUPO_VENDEDOR';
export const RECUPERAR_GRUPO_VENDEDOR_REQUISICAO = 'RECUPERAR_GRUPO_VENDEDOR_REQUISICAO';
export const RECUPERAR_GRUPO_VENDEDOR_SUCESSO = 'RECUPERAR_GRUPO_VENDEDOR_SUCESSO';
export const RECUPERAR_GRUPO_VENDEDOR_ERRO = 'RECUPERAR_GRUPO_VENDEDOR_ERRO';

export const CRIAR_GRUPO_VENDEDOR = 'CRIAR_GRUPO_VENDEDOR';
export const CRIAR_GRUPO_VENDEDOR_REQUISICAO = 'CRIAR_GRUPO_VENDEDOR_REQUISICAO';
export const CRIAR_GRUPO_VENDEDOR_SUCESSO = 'CRIAR_GRUPO_VENDEDOR_SUCESSO';
export const CRIAR_GRUPO_VENDEDOR_ERRO = 'CRIAR_GRUPO_VENDEDOR_ERRO';

export const ATUALIZAR_GRUPO_VENDEDOR = 'ATUALIZAR_GRUPO_VENDEDOR';
export const ATUALIZAR_GRUPO_VENDEDOR_REQUISICAO = 'ATUALIZAR_GRUPO_VENDEDOR_REQUISICAO';
export const ATUALIZAR_GRUPO_VENDEDOR_SUCESSO = 'ATUALIZAR_GRUPO_VENDEDOR_SUCESSO';
export const ATUALIZAR_GRUPO_VENDEDOR_ERRO = 'ATUALIZAR_GRUPO_VENDEDOR_ERRO';

export const DELETAR_GRUPO_VENDEDOR = 'DELETAR_GRUPO_VENDEDOR';
export const DELETAR_GRUPO_VENDEDOR_REQUISICAO = 'DELETAR_GRUPO_VENDEDOR_REQUISICAO';
export const DELETAR_GRUPO_VENDEDOR_SUCESSO = 'DELETAR_GRUPO_VENDEDOR_SUCESSO';
export const DELETAR_GRUPO_VENDEDOR_ERRO = 'DELETAR_GRUPO_VENDEDOR_ERRO';

export const RESTAURAR_GRUPO_VENDEDOR = 'RESTAURAR_GRUPO_VENDEDOR';
export const RESTAURAR_GRUPO_VENDEDOR_REQUISICAO = 'RESTAURAR_GRUPO_VENDEDOR_REQUISICAO';
export const RESTAURAR_GRUPO_VENDEDOR_SUCESSO = 'RESTAURAR_GRUPO_VENDEDOR_SUCESSO';
export const RESTAURAR_GRUPO_VENDEDOR_ERRO = 'RESTAURAR_GRUPO_VENDEDOR_ERRO';
