import React from 'react';
import ToolBar, { ToolBarGroup, ButtonBar } from 'linear-react-components-ui/lib/toolbar';
import SearchField from 'linear-react-components-ui/lib/inputs/search';
import { ContatoContext } from '../constants';
import { PERMISSAO_PARCEIROS } from '../../parceiros/constants';

const ActionBar = () => (
  <ContatoContext.Consumer>
    {({
      t,
      handlerAdicionar,
      handlerFiltrarContatos,
    }) => (
      <ToolBar customClass="default-toolbar">
        <ToolBarGroup style={{ float: 'left' }} align="left">
          <ButtonBar
            label={t('cadastros:contato.labels.adicionarNovo')}
            size="medium"
            type="add"
            onClick={() => { handlerAdicionar(); }}
            permissionAttr={{ ...PERMISSAO_PARCEIROS.CONTATOS_GRAVAR, onDenied: 'disabled' }} />
        </ToolBarGroup>
        <ToolBarGroup>
          <SearchField
            placeHolder={t('commons:labels.pesquisar')}
            onChange={(e) => { handlerFiltrarContatos(e.target.value); }}
            onReset={() => { handlerFiltrarContatos(''); }} />
        </ToolBarGroup>
      </ToolBar>
    )}
  </ContatoContext.Consumer>
);

export default ActionBar;
