import _ from 'lodash';
import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

let result;
const contato = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.RECUPERAR_CONTATOS_REQUISICAO: {
      const { atualizandoLista } = action;
      const itens = atualizandoLista ? state.itens : {};
      return {
        ...state, recuperandoContatos: true, itens,
      };
    }
    case actionTypes.RECUPERAR_CONTATOS_SUCESSO:
      return {
        ...state, recuperandoContatos: false, itens: _.mapKeys(action.payload.data.content, 'id'),
      };

    case actionTypes.RECUPERAR_CONTATO_REQUISICAO:
      return {
        ...state, recuperando: true, current: {},
      };
    case actionTypes.RECUPERAR_CONTATO_SUCESSO:
      return {
        ...state, recuperando: false, current: action.payload.data.content,
      };

    case actionTypes.CRIAR_CONTATO_REQUISICAO:
    case actionTypes.ATUALIZAR_CONTATO_REQUISICAO:
      return { ...state, salvando: true };

    case actionTypes.CRIAR_CONTATO_SUCESSO:
      return {
        ...state, salvando: false,
      };

    case actionTypes.ATUALIZAR_CONTATO_SUCESSO: {
      return {
        ...state, salvando: false,
      };
    }
    case actionTypes.RECUPERAR_CONTATO_POR_CPF_REQUISICAO:
      return {
        ...state, contatoRecuperadoPorCPF: undefined,
      };
    case actionTypes.RECUPERAR_CONTATO_POR_CPF_SUCESSO:
      result = action.payload.data.content.items;
      return {
        ...state, contatoRecuperadoPorCPF: result[0],
      };

    case actionTypes.RESETAR_CONTATO_RECUPERADO_POR_EMAIL:
      return {
        ...state, contatoRecuperadoPorCPF: undefined,
      };

    case actionTypes.ASSOCIAR_CONTATO_AO_PARCEIRO_REQUISICAO:
      return {
        ...state, salvando: true,
      };
    case actionTypes.ASSOCIAR_CONTATO_AO_PARCEIRO_SUCESSO:
      result = action.meta.previousAction.payload.contato;
      return {
        ...state, salvando: false,
      };

    case actionTypes.DESASSOCIAR_CONTATO_DO_PARCEIRO_REQUISICAO:
      return {
        ...state, desassociando: true,
      };
    case actionTypes.DESASSOCIAR_CONTATO_DO_PARCEIRO_SUCESSO: {
      const id = action.meta.previousAction.payload.request.data[0];
      return {
        ...state, desassociando: false, itens: _.omit(state.itens, id),
      };
    }

    case actionTypes.ATUALIZAR_CONTATO_APOS_CRIAR_USUARIO: {
      const { idContato } = action;
      return {
        ...state,
        itens: { ...state.itens, [idContato]: { ...state.itens[idContato], possuiUsuario: true } },
      };
    }

    case actionTypes.CRIAR_CONTATO_ERRO:
    case actionTypes.RECUPERAR_CONTATO_POR_CPF_ERRO:
    case actionTypes.ASSOCIAR_CONTATO_AO_PARCEIRO_ERRO:
    case actionTypes.DESASSOCIAR_CONTATO_DO_PARCEIRO_ERRO:
    case actionTypes.RECUPERAR_CONTATO_ERRO:
    case actionTypes.ATUALIZAR_CONTATO_ERRO:
      return {
        ...state,
        recuperandoContatos: false,
        recuperando: false,
        salvando: false,
        deletando: false,
      };
    default:
      return state;
  }
};

export default contato;
