import React from 'react';
import * as constants from './constants';
import reducer from './reducer';
import * as actions from './actions';
import TelaSelecao from './components/selecao';

const Container = React.lazy(() => import('./components/Container'));

export default {
  TelaSelecao,
  Container,
  reducer,
  constants,
  actions,
};
