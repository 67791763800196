import _ from 'lodash';
import * as constants from './constants';

const obterBanners = state => (
  _.orderBy(_.map(state[constants.NAME].itens, item => item), ['dataExpiracao'], ['desc'])
);
const obterPesquisando = state => state[constants.NAME].pesquisando;
const obterRecuperando = state => state[constants.NAME].recuperando;
const obterSalvando = state => state[constants.NAME].salvando;
const getDefaultData = state => JSON.parse(JSON.stringify(state[constants.NAME].defaultData));
const obterBannerSelecionado = state => state[constants.NAME].current;
const obterDeletando = state => state[constants.NAME].deletando;

export {
  obterPesquisando,
  obterSalvando,
  obterBanners,
  getDefaultData,
  obterRecuperando,
  obterBannerSelecionado,
  obterDeletando,
};
