"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.INITIAL_REDUX_STATE = exports.NOME_COOKIE_MODULOS = exports.NAME = void 0;

var _frontUrls = require("@linear-front-end/infra/lib/frontUrls");

var projectNames = _interopRequireWildcard(require("@linear-front-end/infra/lib/projectNames"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var urlForModule = function urlForModule(projectName, resource) {
  var url = "".concat((0, _frontUrls.getFrontBaseUrl)()[projectName]).concat(projectNames.paths(projectName)).concat(resource);
  if (url[url.length - 1] === '/') return url.slice(0, -1);
  return url;
};

var NAME = 'modulos';
exports.NAME = NAME;
var NOME_COOKIE_MODULOS = 'modulos_sistema';
exports.NOME_COOKIE_MODULOS = NOME_COOKIE_MODULOS;
var INITIAL_REDUX_STATE = {
  todos: [],
  buscando: false,
  recuperandoFuncionalidades: false,
  config: {
    SG_SEGURANCA: {
      rootPath: urlForModule(projectNames.ERP, 'seguranca'),
      iconName: 'user_lock'
    },
    SG_CADASTROS: {
      rootPath: urlForModule(projectNames.ERP, 'cadastros'),
      iconName: 'folder_plus'
    },
    SG_CENTRAL_NEGOCIOS: {
      rootPath: urlForModule(projectNames.TERCEIROS, 'central_negocio'),
      iconName: 'city'
    },
    SG_COTACOES: {
      rootPath: urlForModule(projectNames.TERCEIROS, 'cotacao'),
      iconName: 'basket'
    },
    SG_ADMINISTRATIVO: {
      rootPath: urlForModule(projectNames.ADMINISTRATIVO, 'cadastros'),
      iconName: 'cog3'
    },
    SG_DOCUMENTOS_ELETRONICOS: {
      rootPath: urlForModule(projectNames.ERP, 'documentos_eletronicos'),
      iconName: 'fileText'
    },
    SG_E_PROMO: {
      rootPath: urlForModule(projectNames.ERP, 'e_promo'),
      iconName: 'mobile'
    },
    SG_PRODUTOS: {
      rootPath: urlForModule(projectNames.PRODUTOS, 'cadastros'),
      iconName: 'barcode2'
    },
    SG_FINANCEIRO: {
      rootPath: urlForModule(projectNames.FINANCEIRO, ''),
      iconName: 'coin_dollar'
    },
    SG_FISCAL: {
      rootPath: urlForModule(projectNames.FISCAL, ''),
      iconName: 'drawer3'
    },
    SG_VENDAS: {
      rootPath: urlForModule(projectNames.VENDAS, ''),
      iconName: 'bag'
    },
    SG_COMPRAS: {
      rootPath: urlForModule(projectNames.COMPRAS, ''),
      iconName: 'boxAdd'
    }
  },
  funcionalidades: []
};
exports.INITIAL_REDUX_STATE = INITIAL_REDUX_STATE;