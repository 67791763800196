import * as actionTypes from './actionTypes';

export function pesquisarClientesApp(data = {}) {
  return {
    type: actionTypes.PESQUISAR_CLIENTES_APP,
    payload: {
      request: {
        data,
        url: 'app/clientes/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'ePromo',
        success: false,
        failure: {
          key: 'clientesApp.mensagens.pesquisa.httpFalha',
        },
      },
    },
  };
}
