import React from 'react';
import { OPCOES_STATUS } from '@linear-front-end/infra/lib/statusHelpers';

export const TIPO_PESSOA = {
  fisica: 'PF',
  juridica: 'PJ',
};

export const NAME = 'parceiro';
export const TAB_PAGE_PARCEIRO = 'TAB_PAGE_PARCEIRO';

export const INITIAL_REDUX_STATE = {
  itens: {},
  current: undefined,
  paginacao: {},
  recuperandoCaracteristicas: false,
  caracteristicas: [],
  defaultData: {
    tipoPessoa: TIPO_PESSOA.fisica,
    email: '',
    telefone: '',
    pessoaFisica: {},
    pessoaJuridica: {},
    caracteristicas: [],
    versao: 0,
  },
};
export const ParceiroContext = React.createContext();
export const TABS_IDS = {
  gerais: 'tabInformacoesGerais',
  contatos: 'tabContatos',
  colaborador: 'tabColaborador',
};

export const FORM_TABS_IDS = {
  basicos: 'dadosBasicos',
  perfil: 'perfil',
  colaborador: 'colaborador',
};

export const TIPOS_CLIENTE = {
  balcao: 1,
  app: 2,
};

export const TIPOS_FUNCOES = {
  vendedor: 'vendedor',
  comprador: 'comprador',
};

export const CARACTERISTICAS = {
  colaborador: 1,
  fornecedor: 2,
  cliente: 4,
  contato: 8,
};

export const CRITERIOS_FILTRO = {
  nome: '',
  documento: '',
  tipoPessoa: '',
  caracteristicas: [
    CARACTERISTICAS.colaborador, CARACTERISTICAS.cliente, CARACTERISTICAS.fornecedor,
  ],
  tiposCliente: [TIPOS_CLIENTE.balcao],
  status: [OPCOES_STATUS.ativo, OPCOES_STATUS.inativo],
};

export const MODULE = 'SG_CADASTROS';
export const FEATURE = `${MODULE}_PARCEIROS`;
const COMUM = { module: MODULE, feature: FEATURE };

export const PERMISSAO_PARCEIROS = {
  CONSULTAR: { ...COMUM, operation: `${FEATURE}_CONSULTAR` },
  RESTAURAR: { ...COMUM, operation: `${FEATURE}_RESTAURAR` },
  ATIVAR: { ...COMUM, operation: `${FEATURE}_ATIVAR` },
  INATIVAR: { ...COMUM, operation: `${FEATURE}_INATIVAR` },
  EXCLUIR: { ...COMUM, operation: `${FEATURE}_EXCLUIR` },
  GRAVAR: { ...COMUM, operation: `${FEATURE}_GRAVAR` },
  CONTATOS_GRAVAR: { ...COMUM, operation: `${FEATURE}_CONTATOS_GRAVAR` },
  CONTATOS_CONSULTAR: { ...COMUM, operation: `${FEATURE}_CONTATOS_CONSULTAR` },
};
