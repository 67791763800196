import * as actionTypes from './actionTypes';

export function carregarProdutosCurvaAbcVendasApp(data = {}) {
  return {
    type: actionTypes.CARREGAR_PRODUTOS_CURVA_ABC_VENDAS_APP,
    payload: {
      request: {
        data,
        url: 'vendas/pdv/consultas/curva-abc',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'ePromo',
        success: false,
        failure: {
          key: 'curvaAbcVendasApp.mensagens.pesquisa.httpFalha',
        },
      },
    },
  };
}
