import _ from 'lodash';
import { ocultarRegistroRestaurado } from '@linear-front-end/infra/lib/statusHelpers';
import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE, OPERACOES_API_CONSTANTS } from './constants';

let result;
const gruposEmpresas = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_GRUPOS_EMPRESAS_REQUISICAO: {
      const { pageIndex } = action.payload.request.data.pagination;
      return {
        ...state,
        itens: pageIndex === 0 ? {} : state.itens,
        operacaoApi: OPERACOES_API_CONSTANTS.PESQUISANDO,
      };
    }
    case actionTypes.PESQUISAR_GRUPOS_EMPRESAS_SUCESSO: {
      const { items, pagination } = action.payload.data.content;
      let itens = _.mapKeys(items, 'id');
      if (pagination.pageIndex > 0) itens = { ...state.itens, ...itens };

      return {
        ...state,
        operacaoApi: null,
        itens,
        paginacao: { ...state.paginacao, ...pagination },
      };
    }

    case actionTypes.RECUPERAR_EMPRESAS_DISPONIVEIS_REQUISICAO: {
      return {
        ...state,
        empresasDisponiveis: {},
        recuperandoEmpresasDisponiveis: true,
      };
    }
    case actionTypes.RECUPERAR_EMPRESAS_DISPONIVEIS_SUCESSO:
      return {
        ...state,
        recuperandoEmpresasDisponiveis: false,
        empresasDisponiveis: _.mapKeys(action.payload.data.content, 'id'),
      };

    case actionTypes.RECUPERAR_EMPRESAS_VINCULADAS_REQUISICAO:
      return { ...state, empresasVinculadas: {}, recuperandoEmpresasVinculadas: true };

    case actionTypes.RECUPERAR_EMPRESAS_VINCULADAS_SUCESSO:
      return {
        ...state,
        recuperandoEmpresasVinculadas: false,
        empresasVinculadas: _.mapKeys(action.payload.data.content, 'id'),
      };

    case actionTypes.RECUPERAR_GRUPO_EMPRESA_REQUISICAO:
      return {
        ...state,
        operacaoApi: action.unauthorized ? null : OPERACOES_API_CONSTANTS.RECUPERANDO,
        corrente: {},
      };
    case actionTypes.RECUPERAR_GRUPO_EMPRESA_SUCESSO:
      return {
        ...state, operacaoApi: null, corrente: action.payload.data.content,
      };

    case actionTypes.CRIAR_GRUPO_EMPRESA_REQUISICAO:
    case actionTypes.ATUALIZAR_GRUPO_EMPRESA_REQUISICAO:
      return {
        ...state, operacaoApi: OPERACOES_API_CONSTANTS.SALVANDO,
      };
    case actionTypes.CRIAR_GRUPO_EMPRESA_SUCESSO:
    case actionTypes.ATUALIZAR_GRUPO_EMPRESA_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state,
        itens: { ...state.itens, [result.id]: result },
        corrente: result,
        operacaoApi: null,
      };

    case actionTypes.REMOVER_EMPRESA_REQUISICAO:
      return { ...state, operacaoApi: OPERACOES_API_CONSTANTS.REMOVENDO };

    case actionTypes.REMOVER_EMPRESA_SUCESSO:
      return {
        ...state,
        operacaoApi: null,
        empresasVinculadas: _.omit(state.empresasVinculadas, action.payload.data.content),
      };

    case actionTypes.DELETAR_GRUPO_EMPRESA_REQUISICAO:
      return { ...state, operacaoApi: OPERACOES_API_CONSTANTS.DELETANDO };

    case actionTypes.DELETAR_GRUPO_EMPRESA_SUCESSO:
      return {
        ...state, operacaoApi: null, itens: _.omit(state.itens, action.payload.data.content.id),
      };

    case actionTypes.RESTAURAR_GRUPO_EMPRESA_REQUISICAO:
      return { ...state, operacaoApi: OPERACOES_API_CONSTANTS.RESTAURANDO };
    case actionTypes.RESTAURAR_GRUPO_EMPRESA_SUCESSO: {
      result = action.payload.data.content;
      if (ocultarRegistroRestaurado(action)) {
        return { ...state, operacaoApi: null, itens: _.omit(state.itens, result.id) };
      }
      return {
        ...state,
        operacaoApi: null,
        itens: { ...state.itens, [result.id]: result },
        corrente: result,
      };
    }

    case actionTypes.PESQUISAR_GRUPOS_EMPRESAS_ERRO:
    case actionTypes.RECUPERAR_EMPRESAS_DISPONIVEIS_ERRO:
    case actionTypes.RECUPERAR_EMPRESAS_VINCULADAS_ERRO:
    case actionTypes.RECUPERAR_GRUPO_EMPRESA_ERRO:
    case actionTypes.CRIAR_GRUPO_EMPRESA_ERRO:
    case actionTypes.ATUALIZAR_GRUPO_EMPRESA_ERRO:
    case actionTypes.REMOVER_EMPRESA_ERRO:
    case actionTypes.DELETAR_GRUPO_EMPRESA_ERRO:
    case actionTypes.RESTAURAR_GRUPO_EMPRESA_ERRO:
      return {
        ...state,
        operacaoApi: null,
        recuperandoEmpresasVinculadas: false,
        recuperandoEmpresasDisponiveis: false,
      };
    default:
      return state;
  }
};

export default gruposEmpresas;
