import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

const tabelasSistema = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_GTINS_REQUISICAO:
      return {
        ...state, gtins: { pesquisando: true, itens: [] },
      };
    case actionTypes.PESQUISAR_GTINS_SUCESSO:
      return {
        ...state, gtins: { pesquisando: false, itens: action.payload.data },
      };

    case actionTypes.LIMPAR_GTINS:
      return {
        ...state, gtins: { pesquisando: false, itens: [] },
      };
    case actionTypes.PESQUISAR_GTINS_ERRO:
      return {
        ...state, gtins: { ...state.gtins, pesquisando: false },
      };

    case actionTypes.PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS_REQUISICAO:
      return {
        ...state, tiposDocumentosEletronicos: { pesquisando: true, itens: [] },
      };
    case actionTypes.PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS_SUCESSO:
      return {
        ...state,
        tiposDocumentosEletronicos: { pesquisando: false, itens: action.payload.data.content },
      };
    case actionTypes.PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS_ERRO:
      return {
        ...state, tiposDocumentosEletronicos: { itens: [], pesquisando: false },
      };

    default:
      return state;
  }
};

export default tabelasSistema;
