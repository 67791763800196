import React, { Fragment } from 'react';
import Table, { THeader, TRow, TBody, Th, Td } from 'linear-react-components-ui/lib/table';
import SpinnerLoading from 'linear-react-components-ui/lib/spinner';
import Button from 'linear-react-components-ui/lib/buttons';
import List, { ListItem } from 'linear-react-components-ui/lib/list';
import { InfoLabel } from 'linear-react-components-ui/lib/labels';
import { ContatoContext } from '../constants';

const menuUsuario = (contato, handlerGerarUsuario, t) => {
  const { possuiUsuario } = contato;
  const label = t(`cadastros:parceiro.labels.${possuiUsuario ? 'geradoUsuarioContato' : 'gerarUsuario'}`);
  const callback = possuiUsuario ? undefined : () => { handlerGerarUsuario(contato.id); };
  return (
    <ListItem text={label} leftIconName="user" disabled={possuiUsuario} onClick={callback} />
  );
};

const Lista = () => (
  <ContatoContext.Consumer>
    {({
      t, contatosFiltrados, recuperandoContatos, handlerDeletar, handlerGerarUsuario,
      handlerEditar,
    }) => (
      <Fragment>
        <Table
          bordered
          hovered
          headerColored
          condensed>
          <THeader>
            <TRow>
              <Th>{t('commons:pessoas.fisica.nome')}</Th>
              <Th>{t('commons:pessoas.fisica.sobreNome')}</Th>
              <Th>{t('commons:pessoas.fisica.cpf')}</Th>
              <Th>{t('commons:labels.telefone')}</Th>
              <Th>{t('commons:labels.email')}</Th>
              <Th>{t('cadastros:parceiro.campos.caracteristicas')}</Th>
              <Th />
            </TRow>
          </THeader>
          <TBody>
            {contatosFiltrados.map(contato => (
              <TRow
                key={`contato-${contato.id}`}
                rowId={`contato-${contato.id}`}
                dataId={contato.id}>
                <Td>{contato.pessoaFisica.nome}</Td>
                <Td>{contato.pessoaFisica.sobreNome}</Td>
                <Td>{contato.pessoaFisica.cpf}</Td>
                <Td>{contato.telefone}</Td>
                <Td>{contato.email}</Td>
                <Td>
                  {contato.caracteristicasEnum.map(item => (
                    <InfoLabel size="small" style={{ marginRight: 5 }} key={item.name}>
                      {item.name}
                    </InfoLabel>
                  ))}
                </Td>
                <Td ignoreTableColClick displayContent="onRowHover" style={{ minWidth: '60px' }}>
                  <Button
                    dropdown
                    dropdownAlign="right"
                    size="mini"
                    showIconDropdown={false}
                    iconName="menu"
                    shadow={false}>
                    <List condensed>
                      <ListItem
                        text={t('commons:buttons.remover')}
                        leftIconName="bin"
                        onClick={() => { handlerDeletar(contato.id); }} />
                      <ListItem
                        text={t('commons:buttons.editar')}
                        leftIconName="pencil"
                        onClick={() => { handlerEditar(contato.id); }} />
                      {menuUsuario(contato, handlerGerarUsuario, t)}
                    </List>
                  </Button>
                </Td>
              </TRow>
            ))}
          </TBody>
        </Table>
        {recuperandoContatos &&
          <div><SpinnerLoading text={t('cadastros:contato.labels.carregandoContatos')} /></div>
        }
      </Fragment>
    )}
  </ContatoContext.Consumer>
);

export default Lista;
