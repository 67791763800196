"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.obterInfoLogin = exports.obterContatoClientes = exports.obterContatoFornecedores = exports.obterTokenAusente = exports.getIsLogged = void 0;

var getIsLogged = function getIsLogged(state) {
  return state.logged.isLogged;
};
/*
  O state tokenAusente é retornado como true pelo middleware no momento em que o
  usuário tenta emitir qualquer ação porém está com o token expirado.
*/


exports.getIsLogged = getIsLogged;

var obterTokenAusente = function obterTokenAusente(state) {
  return state.logged.tokenAusente;
};
/**
 * Esta situação irá se aplicar apenas para usuários que sejam contatos de um ou mais parceiros.
 * A função irá retornar os Ids de Parceiros, que neste caso são fornecedores, em que o contato esta
 * associado.
 * Ex:
 * - Fornecedor: Nestlé
 * - Usuário(vendedor da Nestlé): José
 * 0 José ao logar no sistema, será retornado o id da Nestlé
 * Precisamos desta informação, pois quando o josé logar na aplicação para realizar lançamentos de
 * preços de cotação, será enviado para o BackEnd os ids de fornecedores em que ele tem vinculo
 * Obs: Atualmente esta informação é utilizada na Cotação WEB, visão do fornecedor que lança os
 * preços
 * @param {*} state
 * @return {array} Array de Ids
 */


exports.obterTokenAusente = obterTokenAusente;

var obterContatoFornecedores = function obterContatoFornecedores(state) {
  var contatos = state.logged.infoLogin.contatos;

  if (contatos) {
    return contatos.fornecedores;
  }

  return [];
};
/**
 * Esta situação irá se aplicar apenas para usuários que sejam contatos de um ou mais parceiros.
 * A função irá retornar os Ids de Parceiros, que neste caso são clientes, em que o contato esta
 * associado.
 * Ex:
 * - Cliente: Supermercado Preco Bom
 * - Usuário(Comprador do Supermercado Preco Bom): José
 * 0 José ao logar no sistema, será retornado o id do Supermercado Preco Bom
 * Precisamos desta informação, pois quando o josé logar na aplicação para realizar lançamentos de
 * central de negocios, será enviado para o BackEnd os ids de clientes em que ele é associado
 * Obs: Atualmente esta informação é utilizada na Central de negócio Web, visão do Associado que
 * lança as quantidades que deseja comprar
 * @param {*} state
 * @return {array} Array de Ids
 */


exports.obterContatoFornecedores = obterContatoFornecedores;

var obterContatoClientes = function obterContatoClientes(state) {
  var contatos = state.logged.infoLogin.contatos;

  if (contatos) {
    return contatos.clientes;
  }

  return [];
};

exports.obterContatoClientes = obterContatoClientes;

var obterInfoLogin = function obterInfoLogin(state) {
  var token = state.logged.infoLogin.token;
  var jwtbase64 = token.split('.')[1];
  var usr = '_usr';
  var infoLogin = {}; // Replace abaixo resolve caracteres do token JWT não reconhecido pelo encode base64

  var base64 = jwtbase64.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = atob(base64);
  infoLogin = JSON.parse(jsonPayload)[usr];
  return infoLogin;
};

exports.obterInfoLogin = obterInfoLogin;