import React, { useContext } from 'react';
import { OPCOES_STATUS } from '@linear-front-end/infra/lib/statusHelpers';
import { montarPermissoes } from '@linear-front-end/infra/lib/permissionHelpers';
import { CADASTROS } from '@linear-front-end/infra/lib/projectModules';

export const NAME = 'gruposVendedores';
export const TAB_PAGE_GRUPOS_VENDEDORES = 'TAB_PAGE_GRUPOS_VENDEDORES';
export const GruposVendedoresContext = React.createContext();
export const useFeatureContext = () => useContext(GruposVendedoresContext);

export const INITIAL_REDUX_STATE = {
  itens: {},
  corrente: {},
  paginacao: {},
  operacaoApi: null,
};

export const CRITERIOS_FILTRO = {
  descricao: '',
  status: [OPCOES_STATUS.ativo],
};

export const DEFAULT_FORM_DATA = {
  descricao: '',
  versao: 0,
};

export const PERMISSAO_GRUPOS_VENDEDORES = montarPermissoes(CADASTROS, `${CADASTROS}_TABELAS_AUXILIARES_GRUPO_VENDEDOR`);
