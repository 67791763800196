export const PESQUISAR_GTINS = 'PESQUISAR_GTINS';
export const PESQUISAR_GTINS_REQUISICAO = 'PESQUISAR_GTINS_REQUISICAO';
export const PESQUISAR_GTINS_SUCESSO = 'PESQUISAR_GTINS_SUCESSO';
export const PESQUISAR_GTINS_ERRO = 'PESQUISAR_GTINS_ERRO';
export const LIMPAR_GTINS = 'LIMPAR_GTINS';

export const PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS = 'PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS';
export const PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS_REQUISICAO = 'PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS_REQUISICAO';
export const PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS_SUCESSO = 'PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS_SUCESSO';
export const PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS_ERRO = 'PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS_ERRO';
