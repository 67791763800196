import * as actionTypes from './actionTypes';
import { PERMISSAO_URL_LOJAS } from './constants';

export function recuperarUrlLojas() {
  return {
    type: actionTypes.RECUPERAR_URL_LOJAS,
    payload: {
      request: {
        url: '/app/configuracoes/url-lojas',
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: false,
        failure: {
          key: 'urlLojas.mensagens.getAll.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_URL_LOJAS.CONSULTAR,
    },
  };
}

export function configurarUrlLojas(data = {}) {
  return {
    type: actionTypes.CONFIGURAR_URL_LOJAS,
    payload: {
      request: {
        data,
        url: '/app/configuracoes/url-lojas',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: {
          key: 'urlLojas.mensagens.post.httpSucesso',
        },
        failure: {
          key: 'urlLojas.mensagens.post.httpFalha',
        },
      },
    },
  };
}
