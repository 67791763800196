export const PESQUISAR_DOCUMENTOS_ELETRONICOS = 'PESQUISAR_DOCUMENTOS_ELETRONICOS';
export const PESQUISAR_DOCUMENTOS_ELETRONICOS_REQUISICAO = 'PESQUISAR_DOCUMENTOS_ELETRONICOS_REQUISICAO';
export const PESQUISAR_DOCUMENTOS_ELETRONICOS_SUCESSO = 'PESQUISAR_DOCUMENTOS_ELETRONICOS_SUCESSO';
export const PESQUISAR_DOCUMENTOS_ELETRONICOS_ERRO = 'PESQUISAR_DOCUMENTOS_ELETRONICOS_ERRO';

export const DOWNLOAD_LOTE_XML_NOTAS_FISCAIS = 'DOWNLOAD_LOTE_XML_NOTAS_FISCAIS';
export const DOWNLOAD_LOTE_XML_NOTAS_FISCAIS_REQUISICAO = 'DOWNLOAD_LOTE_XML_NOTAS_FISCAIS_REQUISICAO';
export const DOWNLOAD_LOTE_XML_NOTAS_FISCAIS_SUCESSO = 'DOWNLOAD_LOTE_XML_NOTAS_FISCAIS_SUCESSO';
export const DOWNLOAD_LOTE_XML_NOTAS_FISCAIS_ERRO = 'DOWNLOAD_LOTE_XML_NOTAS_FISCAIS_ERRO';

export const RECUPERAR_DOCUMENTO_ELETRONICO = 'RECUPERAR_DOCUMENTO_ELETRONICO';
export const RECUPERAR_DOCUMENTO_ELETRONICO_REQUISICAO = 'RECUPERAR_DOCUMENTO_ELETRONICO_REQUISICAO';
export const RECUPERAR_DOCUMENTO_ELETRONICO_SUCESSO = 'RECUPERAR_DOCUMENTO_ELETRONICO_SUCESSO';
export const RECUPERAR_DOCUMENTO_ELETRONICO_ERRO = 'RECUPERAR_DOCUMENTO_ELETRONICO_ERRO';

export const RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO = 'RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO';
export const RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO_REQUISICAO = 'RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO_REQUISICAO';
export const RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO_SUCESSO = 'RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO_SUCESSO';
export const RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO_ERRO = 'RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO_ERRO';

export const RECUPERAR_CONTEUDO_DOCUMENTO = 'RECUPERAR_CONTEUDO_DOCUMENTO';
export const RECUPERAR_CONTEUDO_DOCUMENTO_REQUISICAO = 'RECUPERAR_CONTEUDO_DOCUMENTO_REQUISICAO';
export const RECUPERAR_CONTEUDO_DOCUMENTO_SUCESSO = 'RECUPERAR_CONTEUDO_DOCUMENTO_SUCESSO';
export const RECUPERAR_CONTEUDO_DOCUMENTO_ERRO = 'RECUPERAR_CONTEUDO_DOCUMENTO_ERRO';
