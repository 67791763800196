import React from 'react';
import { E_PROMO } from '@linear-front-end/infra/lib/projectModules';

export const NAME = 'ePromoNotificacoes';
export const TAB_PAGE_E_PROMO_NOTIFICACOES = 'TAB_PAGE_E_PROMO_NOTIFICACOES';

export const INITIAL_REDUX_STATE = {
  itens: {},
  pesquisando: false,
  recuperando: false,
  salvando: false,
  current: undefined,
  cancelando: false,
  enviandoParaPublicacao: false,
  defaultData: {
    titulo: '',
    mensagem: '',
    dataAgendamento: '',
    horaAgendamento: '',
  },
};

export const NOTIFICACAO_STATUS = {
  aguardandoPublicacao: 0,
  emPublicacao: 1,
  publicado: 2,
  cancelado: 3,
  falhaDePublicacao: 4,
};

export const NotificacaoContext = React.createContext();

export const FEATURE = `${E_PROMO}_NOTIFICACOES`;
const PERMISSAO_COMUM = { module: E_PROMO, feature: FEATURE };

export const PERMISSAO_NOTIFICACOES = {
  INCLUIR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_INCLUIR`,
  },
  EDITAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_EDITAR`,
  },
  CONSULTAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CONSULTAR`,
  },
  VISUALIZAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_VISUALIZAR`,
  },
  CANCELAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CANCELAR`,
  },
};
