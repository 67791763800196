import * as actionTypes from './actionTypes';
import { PERMISSAO_DOCUMENTOS_ELETRONICOS_NOTA_FISCAL_EMITIDA } from './constants';

export function pesquisarDocumentosEletronicos(data = {}) {
  return {
    type: actionTypes.PESQUISAR_DOCUMENTOS_ELETRONICOS,
    payload: {
      request: {
        data,
        url: 'documentos-eletronicos/notas-fiscais/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'documentosEletronicos',
        success: false,
        failure: {
          key: 'notasFiscais.mensagens.getAll.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_DOCUMENTOS_ELETRONICOS_NOTA_FISCAL_EMITIDA.PESQUISAR,
    },
  };
}

export function downloadEmLoteNotasFiscais(data = {}) {
  return {
    type: actionTypes.DOWNLOAD_LOTE_XML_NOTAS_FISCAIS,
    payload: {
      request: {
        data,
        url: 'documentos-eletronicos/notas-fiscais/download-lote',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'documentosEletronicos',
        success: false,
        failure: {
          key: 'downloadLote.mensagens.post.httpFalha',
        },
      },
    },
  };
}

export function recuperarDocumentoEletronico(id) {
  return {
    type: actionTypes.RECUPERAR_DOCUMENTO_ELETRONICO,
    payload: {
      request: {
        url: `documentos-eletronicos/notas-fiscais/${id}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'documentosEletronicos',
        success: false,
        failure: {
          key: 'notasFiscais.mensagens.get.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_DOCUMENTOS_ELETRONICOS_NOTA_FISCAL_EMITIDA.VISUALIZAR,
    },
  };
}

export function recuperarEventosDocumentosEletronicos() {
  return {
    type: actionTypes.RECUPERAR_EVENTOS_DOCUMENTO_ELETRONICO,
    payload: {
      request: {
        url: 'documentos-eletronicos/notas-fiscais/eventos',
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'documentosEletronicos',
        success: false,
        failure: {
          key: 'notasFiscais.mensagens.getEventos.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_DOCUMENTOS_ELETRONICOS_NOTA_FISCAL_EMITIDA.EVENTOS,
    },
  };
}

export function recuperarConteudoDocumento(data) {
  return {
    type: actionTypes.RECUPERAR_CONTEUDO_DOCUMENTO,
    payload: {
      request: {
        data,
        url: 'documentos-eletronicos/notas-fiscais/recuperar-conteudo',
        method: 'POST',
      },
      feedbackMessages: {
        success: false,
      },
      permissionAttr: PERMISSAO_DOCUMENTOS_ELETRONICOS_NOTA_FISCAL_EMITIDA.DOWNLOAD,
    },
  };
}
