export const NAME = 'tabelasSistema';
export const INITIAL_REDUX_STATE = {
  gtins: {
    pesquisando: false,
    itens: [],
  },
  tiposDocumentosEletronicos: {
    pesquisando: false,
    itens: [],
  },
};
