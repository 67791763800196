import * as actionTypes from './actionTypes';

export function pesquisarGtins(data = {}) {
  return {
    type: actionTypes.PESQUISAR_GTINS,
    payload: {
      request: {
        data,
        url: 'tabelas-sistema/gtins/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
      },
    },
  };
}

export function limparGtins() {
  return {
    type: actionTypes.LIMPAR_GTINS,
  };
}

export function pesquisarTiposDocumentosEletronicos(data = {}) {
  return {
    type: actionTypes.PESQUISAR_TIPOS_DOCUMENTOS_ELETRONICOS,
    payload: {
      request: {
        data,
        url: 'tabelas-sistema/tipos-documentos-eletronicos/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
      },
    },
  };
}
