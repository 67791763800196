import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

const clientesApp = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_CLIENTES_APP_REQUISICAO:
      return {
        ...state, pesquisando: true, itens: [],
      };
    case actionTypes.PESQUISAR_CLIENTES_APP_SUCESSO:
      return {
        ...state, pesquisando: false, itens: action.payload.data.content,
      };

    case actionTypes.PESQUISAR_CLIENTES_APP_ERRO:
      return {
        ...state,
        pesquisando: false,
      };
    default:
      return state;
  }
};

export default clientesApp;
