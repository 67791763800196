import _ from 'lodash';
import { createSelector } from 'reselect';
import * as constants from './constants';

export const obterGruposEmpresas = createSelector(
  state => state[constants.NAME].itens,
  itens => _.orderBy(_.map(itens, item => item), ['descricao'], ['asc']),
);
export const obterEmpresasDisponiveis = createSelector(
  state => state[constants.NAME].empresasDisponiveis,
  empresasDisponiveis => _.orderBy(_.map(empresasDisponiveis, item => item), ['razaoSocial'], ['asc']),
);
export const obterEmpresasVinculadas = createSelector(
  state => state[constants.NAME].empresasVinculadas,
  empresasVinculadas => _.orderBy(_.map(empresasVinculadas, item => item), ['razaoSocial'], ['asc']),
);
export const obterGruposEmpresasPaginacao = state => state[constants.NAME].paginacao;
export const obterGrupoEmpresaCorrente = state => state[constants.NAME].corrente;
export const obterOperacaoApi = state => state[constants.NAME].operacaoApi;
export const obterRecuperandoEmpresasVinculadas =
  state => state[constants.NAME].recuperandoEmpresasVinculadas;
export const obterRecuperandoEmpresasDisponiveis =
  state => state[constants.NAME].recuperandoEmpresasDisponiveis;
