import { combineReducers } from 'redux';
/* import login from './modules/autenticacao/login'; */
import logged from '@linear-front-end/commons/lib/usuario/logged';
import credenciais from '@linear-front-end/commons/lib/usuario/credenciais';
import modulos from '@linear-front-end/commons/lib/modulos';
import feedbackMessages from '@linear-front-end/infra/lib/feedback_messages';
import usuarios from './modules/seguranca/usuarios';
import perfis from './modules/seguranca/perfis';
import parceiros from './modules/cadastros/parceiros';
import contatos from './modules/cadastros/contatos';
import empresas from './modules/cadastros/empresas';

import promocoes from './modules/cadastros/produtos/promocoes';
import imagemProduto from './modules/cadastros/produtos/imagens';
import tabelasSistema from './modules/tabelas_sistema';
import documentosEletronicos from './modules/documentos_eletronicos/notas_fiscais';
import downloadDocumentosEletronicos from './modules/documentos_eletronicos/download';
import clientesApp from './modules/e_promo/clientes';
import curvaAbcApp from './modules/e_promo/curva_abc';
import dashboard from './modules/e_promo/dashboard';
import banners from './modules/e_promo/configuracoes/banners';
import campanhas from './modules/e_promo/configuracoes/campanhas';
import urlLojas from './modules/e_promo/configuracoes/url_lojas';
import notificacoes from './modules/e_promo/notificacoes';
import tenants from './modules/cadastros/tenants';
import grupoVendedor from './modules/cadastros/grupo_vendedor';
import grupoEmpresa from './modules/cadastros/grupo_empresa';

export default combineReducers({
  [logged.constants.NAME]: logged.reducer,
  [modulos.constants.NAME]: modulos.reducer,
  [feedbackMessages.constants.NAME]: feedbackMessages.reducer,
  [usuarios.constants.NAME]: usuarios.reducer,
  [perfis.constants.NAME]: perfis.reducer,
  [parceiros.constants.NAME]: parceiros.reducer,
  [contatos.constants.NAME]: contatos.reducer,
  [empresas.constants.NAME]: empresas.reducer,
  [banners.constants.NAME]: banners.reducer,
  [promocoes.lista.constants.NAME]: promocoes.lista.reducer,
  [dashboard.constants.NAME]: dashboard.reducer,
  [tabelasSistema.constants.NAME]: tabelasSistema.reducer,
  [imagemProduto.constants.NAME]: imagemProduto.reducer,
  [documentosEletronicos.constants.NAME]: documentosEletronicos.reducer,
  [downloadDocumentosEletronicos.constants.NAME]: downloadDocumentosEletronicos.reducer,
  [clientesApp.constants.NAME]: clientesApp.reducer,
  [curvaAbcApp.constants.NAME]: curvaAbcApp.reducer,
  [notificacoes.constants.NAME]: notificacoes.reducer,
  [credenciais.constants.NAME]: credenciais.reducer,
  [tenants.constants.NAME]: tenants.reducer,
  [campanhas.constants.NAME]: campanhas.reducer,
  [urlLojas.constants.NAME]: urlLojas.reducer,
  [grupoVendedor.constants.NAME]: grupoVendedor.reducer,
  [grupoEmpresa.constants.NAME]: grupoEmpresa.reducer,
});
