import _ from 'lodash';
import { OPERACOES_API } from '@linear-front-end/infra/lib/operationsCRUDHelpers';
import { ocultarRegistroRestaurado } from '@linear-front-end/infra/lib/statusHelpers';
import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

let result;
const parceiro = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_PARCEIROS_REQUISICAO: {
      const { pageIndex } = action.payload.request.data.pagination;
      return {
        ...state,
        operacaoApi: OPERACOES_API.PESQUISANDO,
        itens: pageIndex === 0 ? {} : state.itens,
      };
    }
    case actionTypes.PESQUISAR_PARCEIROS_SUCESSO: {
      const { items, pagination } = action.payload.data.content;
      let itens = _.mapKeys(items, 'id');

      if (pagination.pageIndex > 0) {
        itens = { ...state.itens, ...itens };
      }

      return {
        ...state,
        operacaoApi: null,
        itens,
        paginacao: { ...state.paginacao, ...pagination },
      };
    }
    case actionTypes.CRIAR_PARCEIRO_REQUISICAO:
    case actionTypes.ATUALIZAR_PARCEIRO_REQUISICAO:
      return { ...state, operacaoApi: OPERACOES_API.SALVANDO };
    case actionTypes.ATIVAR_PARCEIRO_REQUISICAO:
      return { ...state, operacaoApi: OPERACOES_API.ATIVANDO };
    case actionTypes.INATIVAR_PARCEIRO_REQUISICAO:
      return { ...state, operacaoApi: OPERACOES_API.INATIVANDO };
    case actionTypes.RESTAURAR_PARCEIRO_REQUISICAO:
      return { ...state, operacaoApi: OPERACOES_API.RESTAURANDO };

    case actionTypes.CRIAR_PARCEIRO_SUCESSO:
    case actionTypes.ATIVAR_PARCEIRO_SUCESSO:
    case actionTypes.ATUALIZAR_PARCEIRO_SUCESSO:
    case actionTypes.INATIVAR_PARCEIRO_SUCESSO: {
      result = action.payload.data.content;
      return {
        ...state,
        itens: { ...state.itens, [result.id]: result },
        corrente: result,
        operacaoApi: null,
      };
    }
    case actionTypes.RESTAURAR_PARCEIRO_SUCESSO: {
      result = action.payload.data.content;
      if (ocultarRegistroRestaurado(action)) {
        return { ...state, operacaoApi: null, itens: _.omit(state.itens, result.id) };
      }

      return {
        ...state,
        operacaoApi: null,
        itens: { ...state.itens, [result.id]: result },
        current: result,
      };
    }
    case actionTypes.RECUPERAR_PARCEIRO_REQUISICAO:
      return {
        ...state,
        current: undefined,
        operacaoApi: action.unauthorized ? null : OPERACOES_API.RECUPERANDO,
      };
    case actionTypes.RECUPERAR_PARCEIRO_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state, operacaoApi: null, current: result,
      };

    case actionTypes.DELETAR_PARCEIRO_REQUISICAO:
      return { ...state, operacaoApi: OPERACOES_API.DELETANDO };
    case actionTypes.DELETAR_PARCEIRO_SUCESSO:
      result = action.meta.previousAction.payload.recordId;
      return {
        ...state,
        operacaoApi: null,
        itens: _.omit(state.itens, result),
      };

    case actionTypes.RECUPERAR_CARACTERISTICAS_PARCEIRO_REQUISICAO:
      return { ...state, recuperandoCaracteristicas: true };
    case actionTypes.RECUPERAR_CARACTERISTICAS_PARCEIRO_SUCESSO:
      return {
        ...state, recuperandoCaracteristicas: false, caracteristicas: action.payload.data.content,
      };

    case actionTypes.ATUALIZAR_COLABORADOR_APOS_CRIAR_USUARIO:
      return {
        ...state, current: { ...state.current, possuiUsuario: true, usuarioId: action.idUsuario },
      };
    case actionTypes.CRIAR_PARCEIRO_ERRO:
    case actionTypes.ATUALIZAR_PARCEIRO_ERRO:
    case actionTypes.PESQUISAR_PARCEIROS_ERRO:
    case actionTypes.RECUPERAR_PARCEIRO_ERRO:
    case actionTypes.RECUPERAR_CARACTERISTICAS_PARCEIRO_ERRO:
    case actionTypes.ATIVAR_PARCEIRO_ERRO:
    case actionTypes.INATIVAR_PARCEIRO_ERRO:
    case actionTypes.RESTAURAR_PARCEIRO_ERRO:
    case actionTypes.DELETAR_PARCEIRO_ERRO:
      return {
        ...state,
        recuperandoCaracteristicas: false,
        operacaoApi: null,
      };
    default:
      return state;
  }
};

export default parceiro;
