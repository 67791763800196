"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.recuperarCredenciais = recuperarCredenciais;
exports.criarCredencialErp = criarCredencialErp;
exports.atualizarCredencial = atualizarCredencial;
exports.trocarSenhaUsuario = trocarSenhaUsuario;
exports.recuperarUrlsAvatarUsuario = recuperarUrlsAvatarUsuario;
exports.recuperarUrlsAvatarUsuarioLocal = recuperarUrlsAvatarUsuarioLocal;
exports.uploadAvatarUsuario = uploadAvatarUsuario;

var actionTypes = _interopRequireWildcard(require("./actionTypes"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function recuperarCredenciais(idUsuario, idTenant) {
  var headers = idTenant ? {
    'Linear-IdTenant': idTenant
  } : {};
  return {
    type: actionTypes.RECUPERAR_CREDENCIAIS,
    payload: {
      request: _objectSpread({
        url: "seguranca/usuarios/".concat(idUsuario, "/credenciais"),
        method: 'GET'
      }, headers),
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: false,
        failure: {
          key: 'credencial.mensagens.falhaCarregarCredenciais'
        }
      }
    }
  };
}

function criarCredencialErp(data, idUsuario) {
  return {
    type: actionTypes.ATUALIZAR_CREDENCIAL,
    payload: {
      request: {
        data: data,
        url: "seguranca/usuarios/".concat(idUsuario, "/credenciais/local"),
        method: 'POST'
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'credencial.mensagens.post.httpSucesso',
          options: {
            timeout: 10000
          }
        },
        failure: {
          key: 'credencial.mensagens.post.httpFalha'
        }
      }
    }
  };
}

function atualizarCredencial(data, idUsuario, idCredencial, idTenant) {
  var headers = idTenant ? {
    'Linear-IdTenant': idTenant
  } : {};
  return {
    type: actionTypes.ATUALIZAR_CREDENCIAL,
    payload: {
      request: {
        data: data,
        url: "seguranca/usuarios/".concat(idUsuario, "/credenciais/").concat(idCredencial, "/local"),
        method: 'PUT',
        headers: headers
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'credencial.mensagens.put.httpSucesso'
        },
        failure: {
          key: 'credencial.mensagens.put.httpFalha'
        }
      }
    }
  };
}

function trocarSenhaUsuario(data) {
  return {
    type: actionTypes.TROCAR_SENHA_USUARIO,
    payload: {
      request: {
        data: data,
        url: 'seguranca/usuarios/senha/trocar',
        method: 'PUT'
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'credencial.mensagens.trocaSenha.httpSucesso'
        },
        failure: {
          key: 'credencial.mensagens.trocaSenha.httpFalha'
        }
      }
    }
  };
}

function recuperarUrlsAvatarUsuario() {
  return {
    type: actionTypes.RECUPERAR_URLS_AVATAR_USUARIO,
    payload: {
      request: {
        url: 'seguranca/usuarios/credenciais/local/avatar-urls?tamanhos=thumb&tamanhos=small',
        method: 'GET'
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: false,
        failure: false
      }
    }
  };
}

function recuperarUrlsAvatarUsuarioLocal(data) {
  return {
    type: actionTypes.RECUPERAR_URLS_AVATAR_USUARIO_LOCAL,
    data: data
  };
}

function uploadAvatarUsuario(data) {
  return {
    type: actionTypes.UPLOAD_AVATAR_USUARIO,
    payload: {
      request: {
        data: data,
        url: 'seguranca/usuarios/credenciais/local/upload-avatar',
        method: 'PUT'
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'credencial.mensagens.uploadAvatar.httpSucesso'
        },
        failure: {
          key: 'credencial.mensagens.uploadAvatar.httpFalha'
        }
      }
    }
  };
}