import React from 'react';
import Table, { TRow, TBody, Td } from 'linear-react-components-ui/lib/table';
import Panel, { PanelContent, PanelHeader } from 'linear-react-components-ui/lib/panel';
import { DonwnloadDocumentosContext } from '../../constants';

const Destinatarios = () => (
  <DonwnloadDocumentosContext.Consumer>
    {({ t, downloadCorrente: download }) => (
      <Panel style={{ height: 'auto' }}>
        <PanelHeader
          headerColored={false}
          title={t('documentosEletronicos:downloadLote.labels.panelDestinatarios.title')} />
        <PanelContent>
          <Table hovered condensed>
            <TBody>
              {download && download.metadados.destinatarios.map(email => (
                <TRow key={`email-${email}`}><Td>{email}</Td></TRow>
              ))}
            </TBody>
          </Table>
        </PanelContent>
      </Panel>
    )}
  </DonwnloadDocumentosContext.Consumer>
);

export default Destinatarios;
