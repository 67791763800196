import _ from 'lodash';
import * as constants from './constants';

export const obterContatos = state => _.map(state[constants.NAME].itens, item => item);
export const obterRecuperandoContatos = state => state[constants.NAME].recuperandoContatos;
export const obterRecuperando = state => state[constants.NAME].recuperando;
export const obterSalvando = state => state[constants.NAME].salvando;
export const obterContatoSelecionado = state => state[constants.NAME].current;
export const obterContatoRecuperadoPorCPF = state => state[constants.NAME].contatoRecuperadoPorCPF;

export const getDefaultData = (state) => {
  const { defaultData } = state[constants.NAME];
  return JSON.parse(JSON.stringify(defaultData));
};
