import React, { useContext } from 'react';
import { OPCOES_STATUS } from '@linear-front-end/infra/lib/statusHelpers';
import { montarPermissoes } from '@linear-front-end/infra/lib/permissionHelpers';
import { OPERACOES_UI, OPERACOES_API } from '@linear-front-end/infra/lib/operationsCRUDHelpers';
import { CADASTROS } from '@linear-front-end/infra/lib/projectModules';

export const NAME = 'gruposEmpresas';
export const TAB_PAGE_GRUPOS_EMPRESAS = 'TAB_PAGE_GRUPOS_EMPRESAS';
export const GruposEmpresasContext = React.createContext();
export const useFeatureContext = () => useContext(GruposEmpresasContext);

export const INITIAL_REDUX_STATE = {
  itens: {},
  empresasDisponiveis: {},
  empresasVinculadas: {},
  corrente: {},
  paginacao: {},
  operacaoApi: null,
  recuperandoEmpresasVinculadas: false,
  recuperandoEmpresasDisponiveis: false,
};

export const CRITERIOS_FILTRO = {
  descricao: '',
  cnpjEmpresa: '',
  nomeFantasiaEmpresa: '',
  status: [OPCOES_STATUS.ativo],
};

export const DEFAULT_FORM_DATA = {
  descricao: '',
  empresasIds: [],
  versao: 0,
};

export const TABS_IDS = {
  dadosBasicos: 'tabDadosBasicos',
  empresas: 'tabEmpresas',
};

export const OPERACOES_UI_CONSTANTS = {
  ...OPERACOES_UI,
  REMOVENDO: 'REMOVENDO',
};

export const OPERACOES_API_CONSTANTS = {
  ...OPERACOES_API,
  REMOVENDO: 'REMOVENDO',
};

export const apiEstaRemovendo = opApi => OPERACOES_API_CONSTANTS.REMOVENDO === opApi;
export const uiEstaRemovendo = opUi => OPERACOES_UI_CONSTANTS.REMOVENDO === opUi;

export const PERMISSAO_GRUPOS_EMPRESAS =
  montarPermissoes(CADASTROS, `${CADASTROS}_TABELAS_AUXILIARES_GRUPO_EMPRESA`);
