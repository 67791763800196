export const NAME = 'empresas';
export const INITIAL_REDUX_STATE = {
  pesquisando: false,
  itens: [],
  lojasApp: [],
};

export const TIPO_SELECAO = {
  empresas: 'empresas',
  lojasApp: 'lojas',
};
