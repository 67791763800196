import * as constants from './constants';

const obterPesquisando = state => state[constants.NAME].pesquisando;
const obterPerfis = state => state[constants.NAME].itens;
const obterSalvando = state => state[constants.NAME].salvando;
const getItemById = (state, id) => state[constants.NAME].itens.find(perfil => perfil.id === id);
const obterRecuperando = state => state[constants.NAME].recuperando;
const obterPerfilCorrente = state => state[constants.NAME].perfilCorrente;
const obterRecursosVinculados = state => state[constants.NAME].recursosVinculados;
const obterRecursos = state => state[constants.NAME].recursos;
const obterDeletando = state => state[constants.NAME].deletando;
const obterItensPaginacao = state => state[constants.NAME].itensPaginacao;

export {
  obterPesquisando,
  obterSalvando,
  obterPerfis,
  getItemById,
  obterRecuperando,
  obterPerfilCorrente,
  obterRecursosVinculados,
  obterRecursos,
  obterDeletando,
  obterItensPaginacao,
};
