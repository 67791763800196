import * as actionTypes from './actionTypes';

export function pesquisarEmpresasTenant(data = {}) {
  return {
    type: actionTypes.PESQUISAR_EMPRESAS,
    payload: {
      request: {
        data,
        url: 'cadastros/empresas/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        failure: {
          key: 'empresas.mensagens.getAll.httpFalha',
        },
      },
    },
  };
}

export function pesquisarLojasAppTenant() {
  return {
    type: actionTypes.PESQUISAR_LOJAS_APP,
    payload: {
      request: {
        url: 'app/empresas/lojas-disponiveis',
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        failure: {
          key: 'empresas.mensagens.getAll.httpFalha',
        },
      },
    },
  };
}
