export const PESQUISAR_DOWNLOADS_DOUMENTOS = 'PESQUISAR_DOWNLOADS_DOUMENTOS';
export const PESQUISAR_DOWNLOADS_DOUMENTOS_REQUISICAO = 'PESQUISAR_DOWNLOADS_DOUMENTOS_REQUISICAO';
export const PESQUISAR_DOWNLOADS_DOUMENTOS_SUCESSO = 'PESQUISAR_DOWNLOADS_DOUMENTOS_SUCESSO';
export const PESQUISAR_DOWNLOADS_DOUMENTOS_ERRO = 'PESQUISAR_DOWNLOADS_DOUMENTOS_ERRO';
export const LIMPAR_DOWNLOADS_DOCUMENTOS = 'LIMPAR_DOWNLOADS_DOCUMENTOS';

export const RECUPERAR_DOWNLOAD_DOCUMENTOS = 'RECUPERAR_DOWNLOAD_DOCUMENTOS';
export const RECUPERAR_DOWNLOAD_DOCUMENTOS_REQUISICAO = 'RECUPERAR_DOWNLOAD_DOCUMENTOS_REQUISICAO';
export const RECUPERAR_DOWNLOAD_DOCUMENTOS_SUCESSO = 'RECUPERAR_DOWNLOAD_DOCUMENTOS_SUCESSO';
export const RECUPERAR_DOWNLOAD_DOCUMENTOS_ERRO = 'RECUPERAR_DOWNLOAD_DOCUMENTOS_ERRO';
