import reducer from './reducer';
import * as constants from './constants';
import Confirmacao from './components/Confirmacao';
import Container from './components/Container';

export default {
  Confirmacao,
  Container,
  reducer,
  constants,
};
