export const NAME = 'promocao';
export const TAB_PAGE_PRODUTO_EM_PROMOCAO = 'TAB_PAGE_PRODUTO_EM_PROMOCAO';
export const INITIAL_REDUX_STATE = {
  pesquisandoProdutoEmPromocao: false,
  produtosEmPromocao: {
    itens: {},
    pagination: {},
    produtoSelecionado: undefined,
  },
  recuperandoPromocoesProduto: false,
  promocoesProduto: [],
  recuperandoSemelhantesEmPromocao: false,
  semelhantesEmPromocao: [],
};

export const FEATURE = 'SG_CADASTROS_PRODUTOS_EM_PROMOCAO';
const PERMISSAO_COMMON = {
  module: 'SG_CADASTROS',
  feature: FEATURE,
};

export const PERMISSAO_PRODUTOS_EM_PROMOCAO = {
  CONSULTAR: {
    ...PERMISSAO_COMMON,
    operation: `${FEATURE}_CONSULTAR`,
  },
  VISUALIZAR: {
    ...PERMISSAO_COMMON,
    operation: `${FEATURE}_VISUALIZAR`,
  },
  NAO_EXPIRADAS: {
    ...PERMISSAO_COMMON,
    operation: `${FEATURE}_NAO_EXPIRADAS`,
  },
  UPLOAD_IMAGEM: {
    ...PERMISSAO_COMMON,
    operation: `${FEATURE}_UPLOAD_IMAGEM`,
  },
  TROCAR_IMAGEM: {
    ...PERMISSAO_COMMON,
    operation: `${FEATURE}_TROCAR_IMAGEM`,
  },
};
