import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

const downloadDocumentosEletronicos = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_DOWNLOADS_DOUMENTOS_REQUISICAO: {
      return { ...state, pesquisando: true };
    }
    case actionTypes.PESQUISAR_DOWNLOADS_DOUMENTOS_SUCESSO:
      return {
        ...state, pesquisando: false, itens: action.payload.data.content,
      };

    case actionTypes.RECUPERAR_DOWNLOAD_DOCUMENTOS_REQUISICAO:
      return { ...state, recuperando: true };
    case actionTypes.RECUPERAR_DOWNLOAD_DOCUMENTOS_SUCESSO: {
      const current = action.payload.data.content;
      const { itens } = state;
      const index = itens.findIndex(i => i.id === current.id);
      itens[index] = current;
      return {
        ...state,
        recuperando: false,
        current,
        itens,
      };
    }
    case actionTypes.LIMPAR_DOWNLOADS_DOCUMENTOS:
      return {
        ...state,
        itens: [],
        current: undefined,
      };

    case actionTypes.PESQUISAR_DOWNLOADS_DOUMENTOS_ERRO:
      return {
        ...state,
        pesquisando: false,
        recuperando: false,
      };
    default:
      return state;
  }
};

export default downloadDocumentosEletronicos;
