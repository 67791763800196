export const CRIAR_CAMPANHA = 'CRIAR_CAMPANHA';
export const CRIAR_CAMPANHA_REQUISICAO = 'CRIAR_CAMPANHA_REQUISICAO';
export const CRIAR_CAMPANHA_SUCESSO = 'CRIAR_CAMPANHA_SUCESSO';
export const CRIAR_CAMPANHA_ERRO = 'CRIAR_CAMPANHA_ERRO';

export const PESQUISAR_CAMPANHAS = 'PESQUISAR_CAMPANHAS';
export const PESQUISAR_CAMPANHAS_REQUISICAO = 'PESQUISAR_CAMPANHAS_REQUISICAO';
export const PESQUISAR_CAMPANHAS_SUCESSO = 'PESQUISAR_CAMPANHAS_SUCESSO';
export const PESQUISAR_CAMPANHAS_ERRO = 'PESQUISAR_CAMPANHAS_ERRO';

export const RECUPERAR_CAMPANHA = 'RECUPERAR_CAMPANHA';
export const RECUPERAR_CAMPANHA_REQUISICAO = 'RECUPERAR_CAMPANHA_REQUISICAO';
export const RECUPERAR_CAMPANHA_SUCESSO = 'RECUPERAR_CAMPANHA_SUCESSO';
export const RECUPERAR_CAMPANHA_ERRO = 'RECUPERAR_CAMPANHA_ERRO';

export const ATUALIZAR_CAMPANHA = 'ATUALIZAR_CAMPANHA';
export const ATUALIZAR_CAMPANHA_REQUISICAO = 'ATUALIZAR_CAMPANHA_REQUISICAO';
export const ATUALIZAR_CAMPANHA_SUCESSO = 'ATUALIZAR_CAMPANHA_SUCESSO';
export const ATUALIZAR_CAMPANHA_ERRO = 'ATUALIZAR_CAMPANHA_ERRO';

export const CANCELAR_CAMPANHA = 'CANCELAR_CAMPANHA';
export const CANCELAR_CAMPANHA_REQUISICAO = 'CANCELAR_CAMPANHA_REQUISICAO';
export const CANCELAR_CAMPANHA_SUCESSO = 'CANCELAR_CAMPANHA_SUCESSO';
export const CANCELAR_CAMPANHA_ERRO = 'CANCELAR_CAMPANHA_ERRO';

export const PREPARAR_VIGENCIA_CAMPANHA = 'PREPARAR_VIGENCIA_CAMPANHA';
export const PREPARAR_VIGENCIA_CAMPANHA_REQUISICAO = 'PREPARAR_VIGENCIA_CAMPANHA_REQUISICAO';
export const PREPARAR_VIGENCIA_CAMPANHA_SUCESSO = 'PREPARAR_VIGENCIA_CAMPANHA_SUCESSO';
export const PREPARAR_VIGENCIA_CAMPANHA_ERRO = 'PREPARAR_VIGENCIA_CAMPANHA_ERRO';

export const FINALIZAR_CAMPANHA = 'FINALIZAR_CAMPANHA';
export const FINALIZAR_CAMPANHA_REQUISICAO = 'FINALIZAR_CAMPANHA_REQUISICAO';
export const FINALIZAR_CAMPANHA_SUCESSO = 'FINALIZAR_CAMPANHA_SUCESSO';
export const FINALIZAR_CAMPANHA_ERRO = 'FINALIZAR_CAMPANHA_ERRO';

export const CRIAR_LOJAS_PARTICIPANTES = 'CRIAR_LOJAS_PARTICIPANTES';
export const CRIAR_LOJAS_PARTICIPANTES_REQUISICAO = 'CRIAR_LOJAS_PARTICIPANTES_REQUISICAO';
export const CRIAR_LOJAS_PARTICIPANTES_SUCESSO = 'CRIAR_LOJAS_PARTICIPANTES_SUCESSO';
export const CRIAR_LOJAS_PARTICIPANTES_ERRO = 'CRIAR_LOJAS_PARTICIPANTES_ERRO';

export const RECUPERAR_LOJAS_PARTICIPANTES = 'RECUPERAR_LOJAS_PARTICIPANTES';
export const RECUPERAR_LOJAS_PARTICIPANTES_REQUISICAO = 'RECUPERAR_LOJAS_PARTICIPANTES_REQUISICAO';
export const RECUPERAR_LOJAS_PARTICIPANTES_SUCESSO = 'RECUPERAR_LOJAS_PARTICIPANTES_SUCESSO';
export const RECUPERAR_LOJAS_PARTICIPANTES_ERRO = 'RECUPERAR_LOJAS_PARTICIPANTES_ERRO';

export const DELETAR_LOJA_PARTICIPANTE = 'DELETAR_LOJA_PARTICIPANTE';
export const DELETAR_LOJA_PARTICIPANTE_REQUISICAO = 'DELETAR_LOJA_PARTICIPANTE_REQUISICAO';
export const DELETAR_LOJA_PARTICIPANTE_SUCESSO = 'DELETAR_LOJA_PARTICIPANTE_SUCESSO';
export const DELETAR_LOJA_PARTICIPANTE_ERRO = 'DELETAR_LOJA_PARTICIPANTE_ERRO';

export const RECUPERAR_INELEGIVEIS = 'RECUPERAR_INELEGIVEIS';
export const RECUPERAR_INELEGIVEIS_REQUISICAO = 'RECUPERAR_INELEGIVEIS_REQUISICAO';
export const RECUPERAR_INELEGIVEIS_SUCESSO = 'RECUPERAR_INELEGIVEIS_SUCESSO';
export const RECUPERAR_INELEGIVEIS_ERRO = 'RECUPERAR_INELEGIVEIS_ERRO';

export const CRIAR_INELEGIVEL = 'CRIAR_INELEGIVEL';
export const CRIAR_INELEGIVEL_REQUISICAO = 'CRIAR_INELEGIVEL_REQUISICAO';
export const CRIAR_INELEGIVEL_SUCESSO = 'CRIAR_INELEGIVEL_SUCESSO';
export const CRIAR_INELEGIVEL_ERRO = 'CRIAR_INELEGIVEL_ERRO';

export const DELETAR_INELEGIVEL = 'DELETAR_INELEGIVEL';
export const DELETAR_INELEGIVEL_REQUISICAO = 'DELETAR_INELEGIVEL_REQUISICAO';
export const DELETAR_INELEGIVEL_SUCESSO = 'DELETAR_INELEGIVEL_SUCESSO';
export const DELETAR_INELEGIVEL_ERRO = 'DELETAR_INELEGIVEL_ERRO';

export const RECUPERAR_SORTEIOS = 'RECUPERAR_SORTEIOS';
export const RECUPERAR_SORTEIOS_REQUISICAO = 'RECUPERAR_SORTEIOS_REQUISICAO';
export const RECUPERAR_SORTEIOS_SUCESSO = 'RECUPERAR_SORTEIOS_SUCESSO';
export const RECUPERAR_SORTEIOS_ERRO = 'RECUPERAR_SORTEIOS_ERRO';

export const CRIAR_SORTEIO = 'CRIAR_SORTEIO';
export const CRIAR_SORTEIO_REQUISICAO = 'CRIAR_SORTEIO_REQUISICAO';
export const CRIAR_SORTEIO_SUCESSO = 'CRIAR_SORTEIO_SUCESSO';
export const CRIAR_SORTEIO_ERRO = 'CRIAR_SORTEIO_ERRO';

export const ATUALIZAR_SORTEIO = 'ATUALIZAR_SORTEIO';
export const ATUALIZAR_SORTEIO_REQUISICAO = 'ATUALIZAR_SORTEIO_REQUISICAO';
export const ATUALIZAR_SORTEIO_SUCESSO = 'ATUALIZAR_SORTEIO_SUCESSO';
export const ATUALIZAR_SORTEIO_ERRO = 'ATUALIZAR_SORTEIO_ERRO';

export const DELETAR_SORTEIO = 'DELETAR_SORTEIO';
export const DELETAR_SORTEIO_REQUISICAO = 'DELETAR_SORTEIO_REQUISICAO';
export const DELETAR_SORTEIO_SUCESSO = 'DELETAR_SORTEIO_SUCESSO';
export const DELETAR_SORTEIO_ERRO = 'DELETAR_SORTEIO_ERRO';

export const ELEGER_GANHADORES_SORTEIO = 'ELEGER_GANHADORES_SORTEIO';
export const ELEGER_GANHADORES_SORTEIO_REQUISICAO = 'ELEGER_GANHADORES_SORTEIO_REQUISICAO';
export const ELEGER_GANHADORES_SORTEIO_SUCESSO = 'ELEGER_GANHADORES_SORTEIO_SUCESSO';
export const ELEGER_GANHADORES_SORTEIO_ERRO = 'ELEGER_GANHADORES_SORTEIO_ERRO';

export const RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE = 'RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE';
export const RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE_REQUISICAO = 'RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE_REQUISICAO';
export const RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE_SUCESSO = 'RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE_SUCESSO';
export const RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE_ERRO = 'RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE_ERRO';

export const PESQUISAR_NUMEROS_DA_SORTE = 'PESQUISAR_NUMEROS_DA_SORTE';
export const PESQUISAR_NUMEROS_DA_SORTE_REQUISICAO = 'PESQUISAR_NUMEROS_DA_SORTE_REQUISICAO';
export const PESQUISAR_NUMEROS_DA_SORTE_SUCESSO = 'PESQUISAR_NUMEROS_DA_SORTE_SUCESSO';
export const PESQUISAR_NUMEROS_DA_SORTE_ERRO = 'PESQUISAR_NUMEROS_DA_SORTE_ERRO';

export const LIMPAR_NUMEROS_DA_SORTE = 'LIMPAR_NUMEROS_DA_SORTE';
export const LIMPAR_TOTALIZACAO_NUMEROS_DA_SORTE = 'LIMPAR_TOTALIZACAO_NUMEROS_DA_SORTE';

export const RECUPERAR_LISTA_CAMPANHAS = 'RECUPERAR_LISTA_CAMPANHAS';
export const RECUPERAR_LISTA_CAMPANHAS_REQUISICAO = 'RECUPERAR_LISTA_CAMPANHAS_REQUISICAO';
export const RECUPERAR_LISTA_CAMPANHAS_SUCESSO = 'RECUPERAR_LISTA_CAMPANHAS_SUCESSO';
export const RECUPERAR_LISTA_CAMPANHAS_ERRO = 'RECUPERAR_LISTA_CAMPANHAS_ERRO';
