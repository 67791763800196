import _ from 'lodash';
import * as constants from './constants';

const obterCampanhas = state => (
  _.orderBy(_.map(state[constants.NAME].itens, item => item), ['dataInicio'], ['desc'])
);
const obterPesquisando = state => state[constants.NAME].pesquisando;
const obterRecuperando = state => state[constants.NAME].recuperando;
const obterSalvando = state => state[constants.NAME].salvando;
const getDefaultData = state => JSON.parse(JSON.stringify(state[constants.NAME].defaultData));
const obterCampanhaSelecionado = state => state[constants.NAME].current;
const obterCancelando = state => state[constants.NAME].cancelando;
const obterListaCampanhas = state => state[constants.NAME].listaCampanhas;
const obterRecuperandoListaCampanhas = state => state[constants.NAME].recuperandoListaCampanhas;

const obterRecuperandoLojasParticipantes = state =>
  state[constants.NAME].recuperandoLojasParticipantes;

// Reeselect
const obterLojasParticipantes = (state) => {
  const itens = _.map(state[constants.NAME].lojasParticipantes, l => l);
  return _.orderBy(itens, [i => i.nomeFantasia.toLowerCase()]);
};
const obterCriandoLojasParticipantes = state => state[constants.NAME].criandoLojasParticipantes;
const obterDeletandoLojaParticipante = state => state[constants.NAME].deletandoLojaParticipante;

const obterTotalizacaoNumerosGeradosPorLoja = state =>
  state[constants.NAME].totalizacaoNumerosGeradosPorLoja;
const obterRecuperandoTotalizacaoNumerosPorLoja = state =>
  state[constants.NAME].recuperandoTotalizacaoNumerosPorLoja;

const obterNumerosPorLoja = state => state[constants.NAME].numerosSorte.porLoja.numeros;
const obterNumerosPaginationPorLoja = state =>
  state[constants.NAME].numerosSorte.porLoja.pagination;
const obterPesquisandoNumerosPorLoja = state =>
  state[constants.NAME].numerosSorte.porLoja.pesquisando;

const obterNumerosPorCliente = state => state[constants.NAME].numerosSorte.porCliente.numeros;
const obterNumerosPaginationPorCliente = state =>
  state[constants.NAME].numerosSorte.porCliente.pagination;
const obterPesquisandoNumerosPorCliente = state =>
  state[constants.NAME].numerosSorte.porCliente.pesquisando;

const obterRecuperandoIlegiveis = state => state[constants.NAME].recuperandoIlegiveis;
const obterIlegiveis = state => _.map(state[constants.NAME].inelegiveis, l => l);
const obterDeletandoIlegivel = state => state[constants.NAME].deletandoIlegivel;
const obterRecuperandoSorteios = state => state[constants.NAME].recuperandoSorteios;
const obterSorteios = state => _.map(state[constants.NAME].sorteios, l => l);
const obterSalvandoSorteio = state => state[constants.NAME].salvandoSorteio;
const obterDeletandoSorteio = state => state[constants.NAME].deletandoSorteio;

export {
  obterPesquisando,
  obterSalvando,
  obterCampanhas,
  getDefaultData,
  obterRecuperando,
  obterCampanhaSelecionado,
  obterCancelando,
  obterRecuperandoLojasParticipantes,
  obterLojasParticipantes,
  obterCriandoLojasParticipantes,
  obterDeletandoLojaParticipante,
  obterRecuperandoIlegiveis,
  obterIlegiveis,
  obterDeletandoIlegivel,
  obterRecuperandoSorteios,
  obterSorteios,
  obterSalvandoSorteio,
  obterDeletandoSorteio,
  obterTotalizacaoNumerosGeradosPorLoja,
  obterRecuperandoTotalizacaoNumerosPorLoja,
  obterNumerosPorLoja,
  obterPesquisandoNumerosPorLoja,
  obterNumerosPaginationPorLoja,
  obterNumerosPorCliente,
  obterPesquisandoNumerosPorCliente,
  obterNumerosPaginationPorCliente,
  obterListaCampanhas,
  obterRecuperandoListaCampanhas,
};
