import React, { Component } from 'react';
import TextField from 'linear-react-components-ui/lib/inputs/text';
import { CpfField, PhoneField } from 'linear-react-components-ui/lib/inputs/mask';
import DateField from 'linear-react-components-ui/lib/inputs/date';
import { GridRow } from 'linear-react-components-ui/lib/gridlayout';
import { Field } from 'linear-react-components-ui/lib/form';
import * as validators from '@linear-front-end/infra/lib/fieldValidators';
import { FormContext } from '../../constants';

class Campos extends Component {
  componentDidMount() {
    if (!this.focoSetado) {
      setTimeout(() => {
        this.inputRef.focus();
        this.focoSetado = true;
      }, 100);
    }
  }

  render() {
    return (
      <FormContext.Consumer>
        {({
          t, recuperarContatoPorCPF, contato, editando,
        }) => (
          <GridRow>
            <Field
              component={CpfField}
              label={t('commons:pessoas.fisica.cpf')}
              name="pessoaFisica.cpf"
              gridLayout="12 6 6 6"
              required
              validators={[validators.required]}
              inputRef={(r) => { this.inputRef = r; }}
              onBlur={(e) => {
                const { value } = e.target;
                if (value !== '' && (!editando || (editando && value !== contato.pessoaFisica.cpf))) {
                  recuperarContatoPorCPF({ documento: value });
                }
              }} />
            <Field
              component={TextField}
              label={t('commons:labels.email')}
              name="email"
              gridLayout="12 6 6 6"
              validators={[validators.email, validators.maxLength(100)]} />
            <Field
              component={TextField}
              label={t('commons:pessoas.fisica.nome')}
              name="pessoaFisica.nome"
              gridLayout="12 6 6 6"
              required
              validators={[validators.required]} />
            <Field
              component={TextField}
              label={t('commons:pessoas.fisica.sobreNome')}
              name="pessoaFisica.sobreNome"
              required
              gridLayout="12 6 6 6"
              validators={[validators.required]} />
            <Field
              component={DateField}
              label={t('commons:pessoas.fisica.dataNascimento')}
              name="pessoaFisica.nascimento"
              gridLayout="12 6 6 6" />
            <Field
              component={PhoneField}
              label={t('commons:labels.telefone')}
              name="telefone"
              gridLayout="12 6 6 6" />
          </GridRow>
        )}
      </FormContext.Consumer>
    );
  }
}

export default Campos;
