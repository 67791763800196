import React from 'react';
import LabelMessages from 'linear-react-components-ui/lib/labelMessages';
import { FormContext } from '../../constants';

const AssociarContato = () => (
  <FormContext.Consumer>
    {({ contatoRecuperadoPorCPF, t, contatoJaAssociado }) => (
      contatoJaAssociado ? (
        <LabelMessages type="warning" style={{ height: '60px' }}>
          {t('cadastros:contato.mensagens.contatoJaAssociadoAoParceiro')}
        </LabelMessages>
      ) : (
        <LabelMessages type="info" style={{ height: '200px' }}>
          {t('cadastros:contato.mensagens.contatoJaCadastrado', {
            cpf: contatoRecuperadoPorCPF.pessoaFisica.cpf,
            nome: contatoRecuperadoPorCPF.pessoaFisica.nome,
            sobreNome: contatoRecuperadoPorCPF.pessoaFisica.sobreNome,
          })}
        </LabelMessages>
      )
    )}
  </FormContext.Consumer>
);

export default AssociarContato;
