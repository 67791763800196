import _ from 'lodash';

/**
 * Função responsável por montar objeto de rotas com com titulos vindos do storage,
 * @param {object} routes Configurações das rotas
 *  Ex:
 *  {
 *    iconName: 'database1',
 *    id: ID_PAGE,
 *    rootPath: formatUrlProdutos('cadastros'),
 *    path: formatUrlProdutos('cadastros/grupos'),
 *    component: grupos.Container
 *  }
 * @param {string} module Nome do módulo do Projeto
 *  Ex: SG_PRODUTOS
 * @returns Objeto de rotas com dado de título preenchido
 */

export const parserRoutes = (routes = {}, module) => {
  const permissionsStorage = JSON.parse(sessionStorage.getItem('permissionsStorage'));
  const newRoutes = routes;

  if (!_.isEmpty(permissionsStorage)) {
    _.forEach(routes, (value, key) => {
      _.map(permissionsStorage[module], (permission) => {
        if (permission.codigo === key) {
          newRoutes[key] = { ...value, title: permission.nome };
        } else {
          permission.itens.forEach((item) => {
            if (item.codigo === key) {
              newRoutes[key] = { ...value, title: item.nome };
            }
          });
        }
      });
    });

    return newRoutes;
  }

  return {};
};
