export const CRIAR_NOTIFICACAO = 'CRIAR_NOTIFICACAO';
export const CRIAR_NOTIFICACAO_REQUISICAO = 'CRIAR_NOTIFICACAO_REQUISICAO';
export const CRIAR_NOTIFICACAO_SUCESSO = 'CRIAR_NOTIFICACAO_SUCESSO';
export const CRIAR_NOTIFICACAO_ERRO = 'CRIAR_NOTIFICACAO_ERRO';

export const PESQUISAR_NOTIFICACOES = 'PESQUISAR_NOTIFICACOES';
export const PESQUISAR_NOTIFICACOES_REQUISICAO = 'PESQUISAR_NOTIFICACOES_REQUISICAO';
export const PESQUISAR_NOTIFICACOES_SUCESSO = 'PESQUISAR_NOTIFICACOES_SUCESSO';
export const PESQUISAR_NOTIFICACOES_ERRO = 'PESQUISAR_NOTIFICACOES_ERRO';

export const RECUPERAR_NOTIFICACAO = 'RECUPERAR_NOTIFICACAO';
export const RECUPERAR_NOTIFICACAO_REQUISICAO = 'RECUPERAR_NOTIFICACAO_REQUISICAO';
export const RECUPERAR_NOTIFICACAO_SUCESSO = 'RECUPERAR_NOTIFICACAO_SUCESSO';
export const RECUPERAR_NOTIFICACAO_ERRO = 'RECUPERAR_NOTIFICACAO_ERRO';

export const ATUALIZAR_NOTIFICACAO = 'ATUALIZAR_NOTIFICACAO';
export const ATUALIZAR_NOTIFICACAO_REQUISICAO = 'ATUALIZAR_NOTIFICACAO_REQUISICAO';
export const ATUALIZAR_NOTIFICACAO_SUCESSO = 'ATUALIZAR_NOTIFICACAO_SUCESSO';
export const ATUALIZAR_NOTIFICACAO_ERRO = 'ATUALIZAR_NOTIFICACAO_ERRO';

export const CANCELAR_NOTIFICACAO = 'CANCELAR_NOTIFICACAO';
export const CANCELAR_NOTIFICACAO_REQUISICAO = 'CANCELAR_NOTIFICACAO_REQUISICAO';
export const CANCELAR_NOTIFICACAO_SUCESSO = 'CANCELAR_NOTIFICACAO_SUCESSO';
export const CANCELAR_NOTIFICACAO_ERRO = 'CANCELAR_NOTIFICACAO_ERRO';

export const USUARIO_HABILITADO_PUSH = 'USUARIO_HABILITADO_PUSH';
export const USUARIO_HABILITADO_PUSH_REQUISICAO = 'USUARIO_HABILITADO_PUSH_REQUISICAO';
export const USUARIO_HABILITADO_PUSH_SUCESSO = 'USUARIO_HABILITADO_PUSH_SUCESSO';
export const USUARIO_HABILITADO_PUSH_ERRO = 'USUARIO_HABILITADO_PUSH_ERRO';

export const ENVIAR_PUSH_TESTE = 'ENVIAR_PUSH_TESTE';
export const ENVIAR_PUSH_TESTE_REQUISICAO = 'ENVIAR_PUSH_TESTE_REQUISICAO';
export const ENVIAR_PUSH_TESTE_SUCESSO = 'ENVIAR_PUSH_TESTE_SUCESSO';
export const ENVIAR_PUSH_TESTE_ERRO = 'ENVIAR_PUSH_TESTE_ERRO';

export const ENVIAR_PARA_PUBLICACAO = 'ENVIAR_PARA_PUBLICACAO';
export const ENVIAR_PARA_PUBLICACAO_REQUISICAO = 'ENVIAR_PARA_PUBLICACAO_REQUISICAO';
export const ENVIAR_PARA_PUBLICACAO_SUCESSO = 'ENVIAR_PARA_PUBLICACAO_SUCESSO';
export const ENVIAR_PARA_PUBLICACAO_ERRO = 'ENVIAR_PARA_PUBLICACAO_ERRO';

export const ALCANCE_PUSH = 'ALCANCE_PUSH';
