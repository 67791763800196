import * as actionTypes from './actionTypes';
import { PERMISSAO_PRODUTOS_EM_PROMOCAO } from './constants';

export function pesquisarProdutosEmPromocao(data) {
  return {
    type: actionTypes.PESQUISAR_PRODUTOS_EM_PROMOCAO,
    payload: {
      request: {
        data,
        url: 'app/produtos/em-promocao',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
      },
      permissionAttr: PERMISSAO_PRODUTOS_EM_PROMOCAO.CONSULTAR,
    },
  };
}

export function recuperarProdutosSemelhantesEmPromocao(produto) {
  const { id, codigoSemelhanteErp } = produto;
  return {
    type: actionTypes.RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO,
    payload: {
      request: {
        url: `app/produtos/${id}/semelhante/${codigoSemelhanteErp}/em-promocao`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
      },
      permissionAttr: PERMISSAO_PRODUTOS_EM_PROMOCAO.CONSULTAR,
    },
  };
}

export function recuperarPromocoesProduto(idProduto) {
  return {
    type: actionTypes.RECUPERAR_PROMOCOES_PRODUTO,
    payload: {
      request: {
        url: `app/promocoes/produtos/${idProduto}/nao-expiradas`,
        method: 'GET',
      },
      permissionAttr: PERMISSAO_PRODUTOS_EM_PROMOCAO.NAO_EXPIRADAS,
    },
  };
}

export function substituirProdutoEmPromocao(produto) {
  return {
    type: actionTypes.SUBSTITUIR_PRODUTO_EM_PROMOCAO,
    produto,
  };
}

export function setarProdutoSelecionado(produto) {
  return {
    type: actionTypes.SETAR_PRODUTO_SELECIONADO,
    produto,
  };
}
