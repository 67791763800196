import * as actionTypes from './actionTypes';
import { PERMISSAO_PRODUTOS_IMAGENS } from './constants';

export function uploadImagemProduto(produtoId, data) {
  return {
    type: actionTypes.UPLOAD_IMAGEM_PRODUTO,
    payload: {
      request: {
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
        url: `app/produtos/${produtoId}/upload-imagem`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'produtoImagem.mensagens.upload.httpSucesso',
        },
        failure: {
          key: 'produtoImagem.mensagens.upload.httpFalha',
        },
      },
    },
  };
}

export function atacharImagemAoProduto(produtoId, data) {
  return {
    type: actionTypes.ATACHAR_IMAGEM_AO_PRODUTO,
    payload: {
      request: {
        data,
        url: `app/produtos/${produtoId}/atachar-imagem`,
        method: 'PATCH',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'produtoImagem.mensagens.atachar.httpSucesso',
        },
        failure: {
          key: 'produtoImagem.mensagens.atachar.httpFalha',
        },
      },
    },
  };
}

export function pesquisarImagens(data, selecao = false) {
  let type = actionTypes.PESQUISAR_IMAGENS_PRODUTOS;
  if (selecao) type = actionTypes.PESQUISAR_IMAGENS_PRODUTOS_SELECAO;
  return {
    type,
    payload: {
      request: {
        data,
        url: 'app/produto-imagens/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
      },
      permissionAttr: PERMISSAO_PRODUTOS_IMAGENS.CONSULTAR,
    },
  };
}

export function atualizarImagem(imagemId, data) {
  return {
    type: actionTypes.ATUALIZAR_IMAGEM_PRODUTO,
    payload: {
      request: {
        data,
        url: `app/produto-imagens/${imagemId}`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'produtoImagem.mensagens.atachar.httpSucesso',
        },
        failure: {
          key: 'produtoImagem.mensagens.atachar.httpFalha',
        },
      },
    },
  };
}

export function recuperarImagem(idImagem) {
  return {
    type: actionTypes.RECUPERAR_IMAGEM_PRODUTO,
    payload: {
      request: {
        url: `app/produto-imagens/${idImagem}`,
        method: 'GET',
      },
      permissionAttr: PERMISSAO_PRODUTOS_IMAGENS.VISUALIZAR,
    },
  };
}

export function deletarImagem(id) {
  return {
    type: actionTypes.DELETAR_IMAGEM_PRODUTO,
    produtoImagemId: id,
    payload: {
      request: {
        url: `app/produto-imagens/${id}`,
        method: 'DELETE',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'produtoImagem.mensagens.delete.httpSucesso',
        },
        failure: {
          key: 'produtoImagem.mensagens.delete.httpFalha',
        },
      },
    },
  };
}

export function liparImagensProdutosSelecao() {
  return {
    type: actionTypes.LIMPAR_IMAGENS_PRODUTOS_SELECAO,
  };
}
