import React, { Fragment, PureComponent } from 'react';
import SpinnerLoading from 'linear-react-components-ui/lib/spinner';
import Table, { TRow, Th, THeader, TBody, Td } from 'linear-react-components-ui/lib/table';
import Panel from 'linear-react-components-ui/lib/panel';
import { formatDateTime } from 'linear-react-commons/lib/utils/datetime_format';
import { DonwnloadDocumentosContext, STATUS } from '../constants';
import { statusLabel } from './helpers';

class Lista extends PureComponent {
  componentDidMount() {
    const { downloadId } = this.context;
    if (downloadId) this.setPooling({ id: downloadId, status: STATUS.pendente });
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  setPooling = ({ id, status }) => {
    const { recuperarDownloadDocumentos } = this.context;
    this.poolingCount = 0;
    if (![STATUS.falha, STATUS.concluido].includes(status)) {
      this.interval = setInterval(() => {
        this.poolingCount += 1;
        recuperarDownloadDocumentos(id).then((response) => {
          const { content } = response.payload.data;
          if ([STATUS.falha, STATUS.concluido].includes(content.status) ||
              this.poolingCount > 100) {
            clearInterval(this.interval);
          }
        });
      }, 7000);
    }
  }

  recuperarDownload = (download) => {
    const { recuperarDownloadDocumentos } = this.context;
    recuperarDownloadDocumentos(download.id);
    if (this.interval) clearInterval(this.interval);
    this.setPooling(download);
  }

  render() {
    return (
      <DonwnloadDocumentosContext.Consumer>
        {({ downloads, pesquisando, t, downloadId }) => (
          <Fragment>
            <Panel title={t('documentosEletronicos:downloadLote.labels.ultimasRequisicoes')}>
              <Table
                hovered
                headerColored
                condensed
                selectedRowId={downloadId && `${downloadId}`}>
                <THeader>
                  <TRow>
                    <Th>{t('documentosEletronicos:downloadLote.campos.dataSolicitacao')}</Th>
                    <Th>{t('commons:labels.status')}</Th>
                  </TRow>
                </THeader>
                <TBody>
                  {downloads.map(download => (
                    <TRow
                      key={`download-${download.id}`}
                      rowId={`${download.id}`}
                      dataId={download.id}
                      onClick={() => { this.recuperarDownload(download); }}>
                      <Td>{formatDateTime(download.criadoEm)}</Td>
                      <Td>{statusLabel(download)}</Td>
                    </TRow>
                  ))}
                </TBody>
              </Table>
              {pesquisando && <div><SpinnerLoading /></div>}
            </Panel>
          </Fragment>
        )}
      </DonwnloadDocumentosContext.Consumer>
    );
  }
}

Lista.contextType = DonwnloadDocumentosContext;

export default Lista;
