import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button, { PrimaryButton } from 'linear-react-components-ui/lib/buttons';
import Icon from 'linear-react-components-ui/lib/icons';
import withAlert from 'linear-react-components-ui/lib/alerts';

const tiposArquivosAceitos = 'image/x-png,image/png,image/gif,image/jpeg,image/jpg';
class TabUpload extends Component {
  state = {
    arquivoURL: undefined,
    arquivoUpload: undefined,
  }

  onChange = (event) => {
    const { alert, t } = this.props;
    const file = event.target.files[0];
    const tipoValido = this.tiposValidos(file);
    if (tipoValido) {
      this.setState({
        arquivoURL: URL.createObjectURL(event.target.files[0]),
        arquivoUpload: event.target.files[0],
      });
    } else if (tipoValido === false) {
      this.setState({ arquivoURL: undefined, arquivoUpload: undefined });
      alert.danger(
        t('commons:mensagensValidacoesCampos.restricaoArquivoImagem'),
        { position: 'centerTop', timeout: 10000 },
      );
    }
  }

  uploadImagem = () => {
    const formData = new FormData();
    formData.append('imagem', this.state.arquivoUpload);
    this.props.handlerUpload(formData);
  }

  tiposValidos = (file) => {
    if (!file) return undefined;
    const { type } = file;
    if ((type && tiposArquivosAceitos.indexOf(type) < 0) || type === '') return false;
    return true;
  }

  renderizarImagem = (arquivoURL) => {
    const { uploading, t } = this.props;
    return (
      <Fragment>
        <img src={arquivoURL} alt="" className="originalsize" />
        <div style={{ marginTop: 5 }}>
          <Button
            label={t('commons:buttons.trocarImagemLocal')}
            onClick={() => { this.arquivoUploader.click(); }}
            disabled={uploading} />
          <PrimaryButton
            label={t('commons:buttons.confirmarUpload')}
            onClick={() => { this.uploadImagem(); }}
            style={{ marginLeft: 5 }}
            isLoading={uploading}
            disabled={uploading} />
        </div>
      </Fragment>
    );
  }

  renderizarSelecao = () => {
    const { t } = this.props;
    return (
      <Fragment>
        <Icon name="stackPicture" size={128} color="#A8A8A8" />
        <br />
        <Button
          label={t('commons:buttons.selecionarImagemLocal')}
          onClick={() => { this.arquivoUploader.click(); }}
          style={{ marginTop: 5 }} />
      </Fragment>
    );
  }

  render() {
    const { arquivoURL } = this.state;
    return (
      <div className="image-container">
        {arquivoURL ? this.renderizarImagem(arquivoURL) : this.renderizarSelecao()}
        <input
          type="file"
          id="file"
          ref={(ref) => { this.arquivoUploader = ref; }}
          accept={tiposArquivosAceitos}
          style={{ display: 'none' }}
          onChange={this.onChange} />
      </div>
    );
  }
}

TabUpload.propTypes = {
  t: PropTypes.func.isRequired,
  produtoId: PropTypes.number.isRequired,
  handlerUpload: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  alert: PropTypes.object.isRequired,
};

export default withAlert(TabUpload);
