import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

const promocaoDashboard = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.RECUPERAR_QUANTIDADE_PRODUTOS_VIGENTES_SUCESSO:
      return {
        ...state, quantidadeProdutosVigentes: action.payload.data.content,
      };

    case actionTypes.RECUPERAR_RESUMO_VENDAS_POR_TURNO_SUCESSO:
      return {
        ...state, resumoVendasPorTurno: action.payload.data.content,
      };

    case actionTypes.RECUPERAR_RESUMO_VENDAS_POR_DATA_SUCESSO:
      return {
        ...state, resumoVendasPorData: action.payload.data.content,
      };

    case actionTypes.RECUPERAR_RESUMO_VENDAS_POR_EMPRESA_SUCESSO:
      return {
        ...state, resumoVendasPorEmpresa: action.payload.data.content,
      };

    case actionTypes.RECUPERAR_TICKET_MEDIO_SUCESSO: {
      const { agrupado } = action.meta.previousAction;
      const { content } = action.payload.data;

      let result = { ticketMedio: { geral: content } };
      if (agrupado) result = { ticketMedio: { ...state.ticketMedio, agrupado: content } };
      return {
        ...state,
        ...result,
      };
    }

    case actionTypes.RECUPERAR_COMPARATIVO_TIPO_PROMOCAO_GERAL_SUCESSO: {
      const { modoVisualizacao, agrupado } = action.meta.previousAction;
      const { content } = action.payload.data;
      let result = { comparativoTipoPromocaoAgrupado: content };
      if (!agrupado) {
        result = {
          comparativoTipoPromocaoGeral: {
            ...state.comparativoTipoPromocaoGeral, [modoVisualizacao]: content,
          },
        };
      }
      return { ...state, ...result };
    }

    case actionTypes.RECUPERAR_COMPARATIVO_CLIENTES_VENDAS_GERAL_SUCESSO: {
      const { modoVisualizacao, agrupado } = action.meta.previousAction;
      const { content } = action.payload.data;
      let result = { comparativoClientesVendasAgrupado: content };
      if (!agrupado) {
        result = {
          comparativoClientesVendasGeral: {
            ...state.comparativoClientesVendasGeral, [modoVisualizacao]: content,
          },
        };
      }
      return { ...state, ...result };
    }

    case actionTypes.RECUPERAR_QUANTIDADE_CLIENTES_POR_DATA_CADASTRO_SUCESSO:
      return {
        ...state,
        resumoClientesPorDataCadastro: action.payload.data.content,
      };

    case actionTypes.RECUPERAR_QUANTIDADE_TOTAL_CLIENTES_SUCESSO: {
      const { agrupado } = action.meta.previousAction;
      const { content } = action.payload.data;
      let result = { quantidadeTotalClientesAgrupado: content };
      if (!agrupado) result = { quantidadeTotalClientesGeral: content };
      return {
        ...state, ...result,
      };
    }

    default:
      return state;
  }
};

export default promocaoDashboard;
