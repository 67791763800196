"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.montarPermissoes = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Função responsável por montar objeto com as chaves de permissionamento com base no Módulo,
 * Funcionalidade e operações. Por padrão, serão retornadas as permissões para operações básicas:
 *  Incluir, Alterar, Pesquisar, Visualizar, Excluir
 * @param {string} modulo Nome do módulo em que esta sendo utilizado. EX: SG_PRODUTOS
 * @param {string} funcionalidade Nome da funcionalidade em questão:
 *  Ex: SG_PRODUTOS_CADASTROS_GRUPO_FAMILIA
 * @param {object} outrasOperacoes Permite inserir operações que não façam parte das operações
 *  básicas: Ex:
 *  {
 *    INATIVAR: { operationSufix: 'INATIVAR' },
 *    AUTORIZAR_DESCONTO: { operationSufix: 'AUTORIZAR_DESCONTO' }
 *  }
 * @returns Objeto com as configurações de permissões por operação
 */
var montarPermissoes = function montarPermissoes(modulo, funcionalidade) {
  var outrasOperacoes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var base = {
    module: modulo,
    feature: funcionalidade
  };
  var permissoes = {
    INCLUIR: _objectSpread(_objectSpread({}, base), {}, {
      operation: "".concat(funcionalidade, "_INCLUIR")
    }),
    ALTERAR: _objectSpread(_objectSpread({}, base), {}, {
      operation: "".concat(funcionalidade, "_ALTERAR")
    }),
    PESQUISAR: _objectSpread(_objectSpread({}, base), {}, {
      operation: "".concat(funcionalidade, "_PESQUISAR")
    }),
    VISUALIZAR: _objectSpread(_objectSpread({}, base), {}, {
      operation: "".concat(funcionalidade, "_VISUALIZAR")
    }),
    EXCLUIR: _objectSpread(_objectSpread({}, base), {}, {
      operation: "".concat(funcionalidade, "_EXCLUIR")
    }),
    RESTAURAR: _objectSpread(_objectSpread({}, base), {}, {
      operation: "".concat(funcionalidade, "_RESTAURAR")
    })
  };

  _lodash["default"].forEach(outrasOperacoes, function (value, key) {
    permissoes[key] = _objectSpread(_objectSpread({}, base), {}, {
      operation: "".concat(funcionalidade, "_").concat(value.operationSufix)
    });
  });

  return permissoes;
};

exports.montarPermissoes = montarPermissoes;