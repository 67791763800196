import React from 'react';
import { SEGURANCA } from '@linear-front-end/infra/lib/projectModules';

export const NAME = 'perfil';
export const TAB_PAGE_PERFIL = 'TAB_PAGE_PERFIL';
export const PerfilContext = React.createContext();

export const INITIAL_REDUX_STATE = {
  itens: [],
  itensPaginacao: {
    hasNextPage: false,
    pageIndex: 0,
  },
  pesquisando: false,
  salvando: false,
  deletando: false,
  defaultData: {
    nome: '',
    descricao: '',
    status: 0,
  },
  perfilCorrente: undefined,
  recursosVinculados: { recuperando: false, items: [] },
  recursos: { recuperando: false, items: [] },
};

export const defaultData = {
  nome: '',
  descricao: '',
};

export const MODO_DIALOG = {
  cadastroPerfil: 1,
  configuracaoRecursos: 2,
};

export const STATUS = {
  ativo: 0,
  inativo: 1,
  excluido: 2,
};

export const perfilAdminNome = 'Admin';

const FEATURE = `${SEGURANCA}_PERFIS`;
const PERMISSAO_COMUM = { module: SEGURANCA, feature: FEATURE };

export const PERMISSAO_PERFIS = {
  GRAVAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_GRAVAR`,
  },
  EXCLUIR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_EXCLUIR`,
  },
  CONSULTAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CONSULTAR`,
  },
};
