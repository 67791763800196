import * as actionTypes from './actionTypes';
import { PERMISSAO_GRUPOS_EMPRESAS } from './constants';

export function pesquisarGruposEmpresas(data) {
  return {
    type: actionTypes.PESQUISAR_GRUPOS_EMPRESAS,
    payload: {
      request: {
        data,
        url: 'cadastros/grupos-empresas/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        modelKey: 'gruposEmpresas.model.one',
        action: 'search',
      },
      permissionAttr: PERMISSAO_GRUPOS_EMPRESAS.PESQUISAR,
    },
  };
}

export function recuperarGrupoEmpresa(id, visualizacao = false) {
  return {
    type: actionTypes.RECUPERAR_GRUPO_EMPRESA,
    payload: {
      request: {
        url: `cadastros/grupos-empresas/${id}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        modelKey: 'gruposEmpresas.model.one',
      },
      permissionAttr: visualizacao ? PERMISSAO_GRUPOS_EMPRESAS.VISUALIZAR : undefined,
    },
  };
}

export function recuperarEmpresasDisponiveis() {
  return {
    type: actionTypes.RECUPERAR_EMPRESAS_DISPONIVEIS,
    payload: {
      request: {
        url: 'cadastros/grupos-empresas/empresas-disponiveis',
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        modelKey: 'gruposEmpresas.model.one',
      },
    },
  };
}

export function recuperarEmpresasVinculadas(idGrupo) {
  return {
    type: actionTypes.RECUPERAR_EMPRESAS_VINCULADAS,
    payload: {
      request: {
        url: `cadastros/grupos-empresas/${idGrupo}/empresas`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        modelKey: 'gruposEmpresas.model.one',
      },
      permissionAttr: [
        PERMISSAO_GRUPOS_EMPRESAS.INCLUIR,
        PERMISSAO_GRUPOS_EMPRESAS.ALTERAR,
      ],
    },
  };
}

export function removerEmpresa(idGrupo, idEmpresa) {
  return {
    type: actionTypes.REMOVER_EMPRESA,
    payload: {
      request: {
        url: `cadastros/grupos-empresas/${idGrupo}/empresas/${idEmpresa}/remover`,
        method: 'DELETE',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        modelKey: 'gruposEmpresas.model.one',
        action: 'remove',
      },
    },
  };
}

export function criarGrupoEmpresa(data) {
  return {
    type: actionTypes.CRIAR_GRUPO_EMPRESA,
    payload: {
      request: {
        data,
        url: 'cadastros/grupos-empresas',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        modelKey: 'gruposEmpresas.model.one',
      },
    },
  };
}

export function atualizarGrupoEmpresa(data, id) {
  return {
    type: actionTypes.ATUALIZAR_GRUPO_EMPRESA,
    payload: {
      request: {
        data,
        url: `cadastros/grupos-empresas/${id}`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        modelKey: 'gruposEmpresas.model.one',
      },
    },
  };
}

export function deletarGrupoEmpresa(id) {
  return {
    type: actionTypes.DELETAR_GRUPO_EMPRESA,
    payload: {
      request: {
        url: `cadastros/grupos-empresas/${id}`,
        method: 'DELETE',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        modelKey: 'gruposEmpresas.model.one',
      },
    },
  };
}

export function restaurarGrupoEmpresa(id, filtro) {
  return {
    type: actionTypes.RESTAURAR_GRUPO_EMPRESA,
    filtro,
    payload: {
      request: {
        url: `cadastros/grupos-empresas/${id}/restaurar`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        modelKey: 'gruposEmpresas.model.one',
        action: 'restore',
      },
    },
  };
}
