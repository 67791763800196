"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TIPO_CREDENCIAIS = exports.MENU_CONFIGURACOES_USUARIO = exports.DEFAULT_DATA = exports.INITIAL_REDUX_STATE = exports.AUTH_COOKIE_NAME = exports.NAME = void 0;

var _frontUrls = require("@linear-front-end/infra/lib/frontUrls");

var NAME = 'credenciais';
exports.NAME = NAME;
var AUTH_COOKIE_NAME = 'auth_info';
exports.AUTH_COOKIE_NAME = AUTH_COOKIE_NAME;
var INITIAL_REDUX_STATE = {
  itens: [],
  recuperando: false,
  salvando: false,
  recuperandoCredenciais: false,
  urlsAvatar: {},
  trocandoSenha: false,
  recuperandoAvatar: false,
  enviandoAvatar: false
};
exports.INITIAL_REDUX_STATE = INITIAL_REDUX_STATE;
var DEFAULT_DATA = {
  login: '',
  email: '',
  perfis: [],
  urlConfirmaCadastro: (0, _frontUrls.getUrlConfirmacaoCadastro)()
};
exports.DEFAULT_DATA = DEFAULT_DATA;
var MENU_CONFIGURACOES_USUARIO = {
  menuPerfil: 'MENU_PERFIL'
};
exports.MENU_CONFIGURACOES_USUARIO = MENU_CONFIGURACOES_USUARIO;
var TIPO_CREDENCIAIS = {
  erp: 0,
  app: 1
};
exports.TIPO_CREDENCIAIS = TIPO_CREDENCIAIS;