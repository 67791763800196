import React, { Component } from 'react';
import ModalForm from 'linear-react-components-ui/lib/dialog/form';
import Form from 'linear-react-components-ui/lib/form';
import { AddButton, CancelButton, SaveButton } from 'linear-react-components-ui/lib/buttons';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormContext } from '../../constants';
import Campos from './Campos';
import AssociarContato from './AssociarContato';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

class FormContato extends Component {
  static getDerivedStateFromProps(props, state) {
    const { contato, editando } = props;
    if (contato !== state.contato && editando) {
      return { data: JSON.parse(JSON.stringify(contato)), contato };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const {
      contato, editando, idParceiro, defaultData,
    } = props;
    let data = { ...defaultData, contato: { idParceiro } };
    if (editando && contato) data = JSON.parse(JSON.stringify(contato));
    this.state = {
      data,
      contato,
      formValido: false,
    };
  }

  componentWillUnmount() {
    this.props.resetarContatoRecuperadoPorCPF();
  }

  getContextValues = () => ({
    ...this.props,
    ...this.state,
    handlerFieldChange: this.onFieldChange,
    handlerAtualizarCaracteristicas: this.aoAtualizarCaracteristicas,
    contatoJaAssociado: this.verificarContatoJaAssociadoAoPaceiro(),
  });

  concluirOperacao = () => {
    const { idParceiro, recuperarContatos, handlerFecharModal } = this.props;
    handlerFecharModal();
    recuperarContatos(idParceiro, true);
  }

  salvar = (data) => {
    const {
      atualizarContato,
      criarContato,
      adicionando,
      contato,
    } = this.props;
    if (adicionando) {
      criarContato(data).then(() => { this.concluirOperacao(); });
    } else {
      atualizarContato(data, contato.idParceiro, contato.id).then(() => {
        this.concluirOperacao();
      });
    }
  }

  verificarContatoJaAssociadoAoPaceiro = () => {
    const { contatoRecuperadoPorCPF, contatos } = this.props;
    let item;
    if (contatoRecuperadoPorCPF) {
      item = contatos.find(c => c.idParceiro === contatoRecuperadoPorCPF.id);
    }
    return item !== undefined;
  };

  associarContatoAoParceiro = () => {
    const { idParceiro, associarContato, contatoRecuperadoPorCPF } = this.props;
    associarContato(idParceiro, [contatoRecuperadoPorCPF.id])
      .then(() => { this.concluirOperacao(); });
  }

  buttons = () => {
    const { formValido } = this.state;
    const {
      t, handlerFecharModal, salvando, contatoRecuperadoPorCPF,
    } = this.props;
    let buttons = [
      <CancelButton
        key="cancel-button"
        label={t('commons:buttons.cancelar')}
        onClick={() => { handlerFecharModal(); }}
        disabled={salvando} />,
    ];
    if (contatoRecuperadoPorCPF) {
      buttons = [
        <AddButton
          key="add-button"
          label={t('commons:buttons.add')}
          onClick={() => { this.associarContatoAoParceiro(); }}
          isLoading={salvando}
          disabled={salvando || this.verificarContatoJaAssociadoAoPaceiro()} />, ...buttons,
      ];
    } else {
      buttons = [
        <SaveButton
          key="save-button"
          label={t('commons:buttons.salvar')}
          onClick={() => { this.handlerSubmit(); }}
          isLoading={salvando}
          disabled={salvando || !formValido} />, ...buttons,
      ];
    }
    return buttons;
  }

  render() {
    const { data } = this.state;
    const contextValues = this.getContextValues();
    const {
      editando, t, handlerFecharModal, contatoRecuperadoPorCPF, recuperando,
    } = this.props;
    return (
      <FormContext.Provider value={contextValues}>
        <ModalForm
          title={t(`cadastros:contato.modalTitulo${editando ? 'Alteracao' : 'Inclusao'}`)}
          width="650px"
          height="400px"
          handlerClose={() => { handlerFecharModal(); }}
          buttons={this.buttons()}
          isWaiting={recuperando}>
          {contatoRecuperadoPorCPF && <AssociarContato {...this.props} />}
          {!contatoRecuperadoPorCPF &&
            <Form
              dataSource={data}
              onSubmit={(jsonData) => { this.salvar(jsonData); }}
              onValidateForm={(valido) => { this.setState({ formValido: valido }); }}
              handlerSubmit={(handler) => { this.handlerSubmit = handler; }}>
              <Campos t={t} />
            </Form>
          }
        </ModalForm>
      </FormContext.Provider>
    );
  }
}

FormContato.propTypes = {
  contato: PropTypes.object,
  editando: PropTypes.bool.isRequired,
  adicionando: PropTypes.bool.isRequired,
  salvando: PropTypes.bool.isRequired,
  recuperando: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  defaultData: PropTypes.object.isRequired,
  handlerFecharModal: PropTypes.func.isRequired,
  atualizarContato: PropTypes.func.isRequired,
  criarContato: PropTypes.func.isRequired,
  idParceiro: PropTypes.number.isRequired,
  recuperarContatos: PropTypes.func.isRequired,
  contatoRecuperadoPorCPF: PropTypes.object,
  associarContato: PropTypes.func.isRequired,
  resetarContatoRecuperadoPorCPF: PropTypes.func.isRequired,
  contatos: PropTypes.arrayOf(PropTypes.object),
};

FormContato.defaultProps = {
  contato: undefined,
  contatoRecuperadoPorCPF: undefined,
  contatos: [],
};

const mapStateToProps = state => ({
  contatoRecuperadoPorCPF: selectors.obterContatoRecuperadoPorCPF(state),
  salvando: selectors.obterSalvando(state),
  defaultData: selectors.getDefaultData(state),
});

export default connect(mapStateToProps, { ...actions })(translate(['common', 'cadastros'])(FormContato));
