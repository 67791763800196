import React from 'react';
import { SuccessLabel, WarningLabel, DangerLabel, InfoLabel } from 'linear-react-components-ui/lib/labels';
import Icon from 'linear-react-components-ui/lib/icons';
import SpinnerLoading from 'linear-react-components-ui/lib/spinner';
import { STATUS } from '../constants';

export const statusLabel = ({ status, descricaoStatus }) => ({
  [STATUS.pendente]: <InfoLabel>{descricaoStatus}</InfoLabel>,
  [STATUS.processamento]: <WarningLabel>{descricaoStatus}</WarningLabel>,
  [STATUS.falha]: <DangerLabel>{descricaoStatus}</DangerLabel>,
  [STATUS.concluido]: <SuccessLabel>{descricaoStatus}</SuccessLabel>,
})[status];

export const statusIcon = status => ({
  [STATUS.pendente]: <Icon name="pause2" size={16} color="#24cabc" />,
  // [STATUS.processamento]: <Icon name="hourGlass" size={16} color="#f39c12" />,
  [STATUS.processamento]: <SpinnerLoading size="28px" />,
  [STATUS.falha]: <Icon name="cancel" size={16} color="#b22d18" />,
  [STATUS.concluido]: <Icon name="checkmark" size={16} color="#379e6f" />,
})[status];
