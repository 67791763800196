export const CRIAR_BANNER = 'CRIAR_BANNER';
export const CRIAR_BANNER_REQUISICAO = 'CRIAR_BANNER_REQUISICAO';
export const CRIAR_BANNER_SUCESSO = 'CRIAR_BANNER_SUCESSO';
export const CRIAR_BANNER_ERRO = 'CRIAR_BANNER_ERRO';

export const PESQUISAR_BANNERS = 'PESQUISAR_BANNERS';
export const PESQUISAR_BANNERS_REQUISICAO = 'PESQUISAR_BANNERS_REQUISICAO';
export const PESQUISAR_BANNERS_SUCESSO = 'PESQUISAR_BANNERS_SUCESSO';
export const PESQUISAR_BANNERS_ERRO = 'PESQUISAR_BANNERS_ERRO';

export const RECUPERAR_BANNER = 'RECUPERAR_BANNER';
export const RECUPERAR_BANNER_REQUISICAO = 'RECUPERAR_BANNER_REQUISICAO';
export const RECUPERAR_BANNER_SUCESSO = 'RECUPERAR_BANNER_SUCESSO';
export const RECUPERAR_BANNER_ERRO = 'RECUPERAR_BANNER_ERRO';

export const ATUALIZAR_BANNER = 'ATUALIZAR_BANNER';
export const ATUALIZAR_BANNER_REQUISICAO = 'ATUALIZAR_BANNER_REQUISICAO';
export const ATUALIZAR_BANNER_SUCESSO = 'ATUALIZAR_BANNER_SUCESSO';
export const ATUALIZAR_BANNER_ERRO = 'ATUALIZAR_BANNER_ERRO';

export const DELETAR_BANNER = 'DELETAR_BANNER';
export const DELETAR_BANNER_REQUISICAO = 'DELETAR_BANNER_REQUISICAO';
export const DELETAR_BANNER_SUCESSO = 'DELETAR_BANNER_SUCESSO';
export const DELETAR_BANNER_ERRO = 'DELETAR_BANNER_ERRO';
