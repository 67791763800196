import React from 'react';
import Table, { TRow, TBody, Th, Td } from 'linear-react-components-ui/lib/table';
import { formatDateTime } from 'linear-react-commons/lib/utils/datetime_format';
import { statusLabel } from '../../helpers';

const fieldNameColumn = { width: '170px' };
const InfoTarefa = (tarefa, t) => (
  <div className="popup" style={{ marginLeft: -360, marginTop: -6 }}>
    <Table vertical condensed>
      <TBody>
        <TRow>
          <Th style={fieldNameColumn}>
            {t('commons:labels.status')}
          </Th>
          <Td>{statusLabel(tarefa)}</Td>
        </TRow>
        <TRow>
          <Th style={fieldNameColumn}>
            {t('documentosEletronicos:downloadLote.campos.inicioProcessamento')}
          </Th>
          <Td>{formatDateTime(tarefa.iniciadoEm)}</Td>
        </TRow>
        <TRow>
          <Th style={fieldNameColumn}>
            {t('documentosEletronicos:downloadLote.campos.terminoProcessamento')}
          </Th>
          <Td>{formatDateTime(tarefa.terminadoEm)}</Td>
        </TRow>
        <TRow>
          <Th style={fieldNameColumn}>
            {t('documentosEletronicos:downloadLote.campos.duracaoProcessamento')}
          </Th>
          <Td>{tarefa.duracaoProcessamento}</Td>
        </TRow>
        {tarefa.falhadoEm && (
          <TRow>
            <Th style={fieldNameColumn}>
              {t('documentosEletronicos:downloadLote.campos.falhadoProcessamento')}
            </Th>
            <Td>{formatDateTime(tarefa.falhadoEm)}</Td>
          </TRow>
        )}
      </TBody>
    </Table>
  </div>
);

export default InfoTarefa;
