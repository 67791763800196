export const CRIAR_CONTATO = 'CRIAR_CONTATO';
export const CRIAR_CONTATO_REQUISICAO = 'CRIAR_CONTATO_REQUISICAO';
export const CRIAR_CONTATO_SUCESSO = 'CRIAR_CONTATO_SUCESSO';
export const CRIAR_CONTATO_ERRO = 'CRIAR_CONTATO_ERRO';

export const RECUPERAR_CONTATOS = 'RECUPERAR_CONTATOS';
export const RECUPERAR_CONTATOS_REQUISICAO = 'RECUPERAR_CONTATOS_REQUISICAO';
export const RECUPERAR_CONTATOS_SUCESSO = 'RECUPERAR_CONTATOS_SUCESSO';
export const RECUPERAR_CONTATOS_ERRO = 'RECUPERAR_CONTATOS_ERRO';

export const RECUPERAR_CONTATO = 'RECUPERAR_CONTATO';
export const RECUPERAR_CONTATO_REQUISICAO = 'RECUPERAR_CONTATO_REQUISICAO';
export const RECUPERAR_CONTATO_SUCESSO = 'RECUPERAR_CONTATO_SUCESSO';
export const RECUPERAR_CONTATO_ERRO = 'RECUPERAR_CONTATO_ERRO';

export const ATUALIZAR_CONTATO = 'ATUALIZAR_CONTATO';
export const ATUALIZAR_CONTATO_REQUISICAO = 'ATUALIZAR_CONTATO_REQUISICAO';
export const ATUALIZAR_CONTATO_SUCESSO = 'ATUALIZAR_CONTATO_SUCESSO';
export const ATUALIZAR_CONTATO_ERRO = 'ATUALIZAR_CONTATO_ERRO';

export const REMOVER_CONTATO = 'REMOVER_CONTATO';
export const REMOVER_CONTATO_REQUISICAO = 'REMOVER_CONTATO_REQUISICAO';
export const REMOVER_CONTATO_SUCESSO = 'REMOVER_CONTATO_SUCESSO';
export const REMOVER_CONTATO_ERRO = 'REMOVER_CONTATO_ERRO';

export const RECUPERAR_CONTATO_POR_CPF = 'RECUPERAR_CONTATO_POR_CPF';
export const RECUPERAR_CONTATO_POR_CPF_REQUISICAO = 'RECUPERAR_CONTATO_POR_CPF_REQUISICAO';
export const RECUPERAR_CONTATO_POR_CPF_SUCESSO = 'RECUPERAR_CONTATO_POR_CPF_SUCESSO';
export const RECUPERAR_CONTATO_POR_CPF_ERRO = 'RECUPERAR_CONTATO_POR_CPF_ERRO';

export const ASSOCIAR_CONTATO_AO_PARCEIRO = 'ASSOCIAR_CONTATO_AO_PARCEIRO';
export const ASSOCIAR_CONTATO_AO_PARCEIRO_REQUISICAO = 'ASSOCIAR_CONTATO_AO_PARCEIRO_REQUISICAO';
export const ASSOCIAR_CONTATO_AO_PARCEIRO_SUCESSO = 'ASSOCIAR_CONTATO_AO_PARCEIRO_SUCESSO';
export const ASSOCIAR_CONTATO_AO_PARCEIRO_ERRO = 'ASSOCIAR_CONTATO_AO_PARCEIRO_ERRO';

export const RESETAR_CONTATO_RECUPERADO_POR_EMAIL = 'RESETAR_CONTATO_RECUPERADO_POR_EMAIL';

export const DESASSOCIAR_CONTATO_DO_PARCEIRO = 'DESASSOCIAR_CONTATO_DO_PARCEIRO';
export const DESASSOCIAR_CONTATO_DO_PARCEIRO_REQUISICAO = 'DESASSOCIAR_CONTATO_DO_PARCEIRO_REQUISICAO';
export const DESASSOCIAR_CONTATO_DO_PARCEIRO_SUCESSO = 'DESASSOCIAR_CONTATO_DO_PARCEIRO_SUCESSO';
export const DESASSOCIAR_CONTATO_DO_PARCEIRO_ERRO = 'DESASSOCIAR_CONTATO_DO_PARCEIRO_ERRO';

export const ATUALIZAR_CONTATO_APOS_CRIAR_USUARIO = 'ATUALIZAR_CONTATO_APOS_CRIAR_USUARIO';
