import React from 'react';
import { E_PROMO } from '@linear-front-end/infra/lib/projectModules';

export const NAME = 'campanhas';
export const TAB_PAGE_CAMPANHAS = 'TAB_PAGE_CAMPANHAS';

export const INITIAL_REDUX_STATE = {
  itens: {},
  pesquisando: false,
  recuperando: false,
  salvando: false,
  current: undefined,
  cancelando: false,
  defaultData: {
    nome: '',
    descricao: '',
    dataInicio: '',
    dataTermino: '',
    valorMinimo: 0,
    valorAcumulativo: false,
    serieLoja: false,
  },
  recuperandoLojasParticipantes: false,
  lojasParticipantes: {},
  deletandoLojaParticipante: false,
  recuperandoIlegiveis: false,
  inelegiveis: {},
  recuperandoSorteios: false,
  sorteios: {},
  deletandoSorteio: false,
  elegendoGanhadores: false,
  totalizacaoNumerosGeradosPorLoja: {},
  recuperandoTotalizacaoNumerosPorLoja: false,
  numerosSorte: {
    porLoja: {
      numeros: [],
      pagination: {},
      pesquisando: false,
    },
    porCliente: {
      numeros: [],
      pagination: {},
      pesquisando: false,
    },
  },
  listaCampanhas: [],
  recuperandoListaCampanhas: false,
};

export const CRITERIOS_FILTRO = {
  nome: '',
  dataInicial: '',
  dataFinal: '',
  status: [],
};

export const CampanhaContext = React.createContext();

export const CAMPANHA_STATUS = {
  aguardandoInicio: 1,
  vigente: 2,
  cancelada: 3,
  finalizada: 4,
};

export const TABS_VISUALIZACAO = {
  tabBasico: 'tab-basico',
  tabLojas: 'tab-lojas',
  tabInelegiveis: 'tab-inelegiveis',
  tabSorteios: 'tab-sorteios',
  tabNumerosDaSorte: 'tab-numerosdasorte',
};

export const TIPO_INCLUSAO_INELEGIVEL = {
  manual: 'manual',
  importacao: 'importacao',
};

export const TIPO_SORTEIOS = {
  global: 1,
  porLoja: 2,
};

export const SORTEIO_STATUS = {
  pendente: 1,
  aguardandoProcessamento: 2,
  emProcessamento: 3,
};

export const tipoSorteios = t => [
  {
    id: TIPO_SORTEIOS.global,
    descricao: t('configuracoes:campanha.labels.sorteios.tipoGlobal'),
  },
  {
    id: TIPO_SORTEIOS.porLoja,
    descricao: t('configuracoes:campanha.labels.sorteios.tipoPorLoja'),
  },
];

export const DEFAULT_DATA_SORTEIO = {
  descricaoPremio: '',
  tipo: TIPO_SORTEIOS.global,
  numerosSorteados: [],
};

export const NUMEROS_SORTE = {
  porLoja: 'porLoja',
  porCliente: 'porCliente',
};

const FEATURE = `${E_PROMO}_CONFIGURACOES_CAMPANHAS`;
const PERMISSAO_COMUM = { module: E_PROMO, feature: FEATURE };

export const PERMISSAO_CAMPANHAS = {
  CONSULTAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CONSULTAR`,
  },
  VISUALIZAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_VISUALIZAR`,
  },
  INCLUIR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_INCLUIR`,
  },
  EDITAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_EDITAR`,
  },
  CANCELAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CANCELAR`,
  },
  EXCLUIR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_EXCLUIR`,
  },
  MUDAR_STATUS: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_MUDAR_STATUS`,
  },
  CONFIGURAR_LOJAS: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CONFIGURAR_LOJAS`,
  },
  CONFIGURAR_INELEGIVEIS: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CONFIGURAR_INELEGIVEIS`,
  },
  CONFIGURAR_PREMIOS: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CONFIGURAR_PREMIOS`,
  },
  APURAR_GANHADORES: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_APURAR_GANHADORES`,
  },
};
