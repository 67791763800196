/* eslint-disable no-unreachable */
import _ from 'lodash';
import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

const empresas = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_EMPRESAS_REQUISICAO:
      return { ...state, pesquisando: true, itens: {} };
    case actionTypes.PESQUISAR_EMPRESAS_SUCESSO:
      return {
        ...state, pesquisando: false, itens: _.mapKeys(action.payload.data.content.items, 'id'),
      };

    case actionTypes.PESQUISAR_LOJAS_APP_REQUISICAO:
      return { ...state, pesquisando: true, lojasApp: [] };
    case actionTypes.PESQUISAR_LOJAS_APP_SUCESSO:
      return {
        ...state, pesquisando: false, lojasApp: action.payload.data.content,
      };

    case actionTypes.PESQUISAR_EMPRESAS_ERRO:
    case actionTypes.PESQUISAR_LOJAS_APP_ERRO:
      return {
        ...state, pesquisando: false,
      };
    default:
      return state;
  }
};

export default empresas;
