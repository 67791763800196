import * as constants from './constants';
import reducer from './reducer';
import * as actions from './actions';
import Form from './components/form';

import Container from './components/Container';

export default {
  Container,
  Form,
  reducer,
  constants,
  actions,
};
