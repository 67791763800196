import _ from 'lodash';
import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

let result;
const campanha = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_CAMPANHAS_REQUISICAO:
      return {
        ...state, pesquisando: true, itens: {},
      };
    case actionTypes.PESQUISAR_CAMPANHAS_SUCESSO:
      return {
        ...state,
        pesquisando: false,
        itens: _.mapKeys(action.payload.data.content, 'id'),
      };

    case actionTypes.CRIAR_CAMPANHA_REQUISICAO:
    case actionTypes.ATUALIZAR_CAMPANHA_REQUISICAO:
    case actionTypes.PREPARAR_VIGENCIA_CAMPANHA_REQUISICAO:
    case actionTypes.FINALIZAR_CAMPANHA_REQUISICAO:
      return { ...state, salvando: true };
    case actionTypes.CANCELAR_CAMPANHA_REQUISICAO:
      return {
        ...state, cancelando: true,
      };

    case actionTypes.CRIAR_CAMPANHA_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state, salvando: false, itens: { ...state.itens, [result.id]: result },
      };
    case actionTypes.ATUALIZAR_CAMPANHA_SUCESSO:
    case actionTypes.CANCELAR_CAMPANHA_SUCESSO:
    case actionTypes.PREPARAR_VIGENCIA_CAMPANHA_SUCESSO:
    case actionTypes.FINALIZAR_CAMPANHA_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state,
        salvando: false,
        cancelando: false,
        itens: { ...state.itens, [result.id]: result },
        current: result,
      };

    case actionTypes.RECUPERAR_CAMPANHA_REQUISICAO:
      return {
        ...state,
        recuperando: true,
        current: {},
      };
    case actionTypes.RECUPERAR_CAMPANHA_SUCESSO:
      result = action.payload.data.content;
      return {
        ...state, recuperando: false, current: result,
      };

    case actionTypes.RECUPERAR_LISTA_CAMPANHAS_REQUISICAO:
      return {
        ...state, recuperandoListaCampanhas: true,
      };
    case actionTypes.RECUPERAR_LISTA_CAMPANHAS_SUCESSO:
      return {
        ...state,
        recuperandoListaCampanhas: false,
        listaCampanhas: action.payload.data.content,
      };

    // Lojas participantes
    case actionTypes.CRIAR_LOJAS_PARTICIPANTES_REQUISICAO:
      return {
        ...state, criandoLojasParticipantes: true,
      };
    case actionTypes.CRIAR_LOJAS_PARTICIPANTES_SUCESSO:
      return {
        ...state,
        criandoLojasParticipantes: false,
        lojasParticipantes: {
          ...state.lojasParticipantes, ...(_.mapKeys(action.payload.data.content, 'id')),
        },
      };

    case actionTypes.RECUPERAR_LOJAS_PARTICIPANTES_REQUISICAO:
      return {
        ...state, recuperandoLojasParticipantes: true, lojasParticipantes: {},
      };
    case actionTypes.RECUPERAR_LOJAS_PARTICIPANTES_SUCESSO:
      return {
        ...state,
        recuperandoLojasParticipantes: false,
        lojasParticipantes: _.mapKeys(action.payload.data.content, 'id'),
      };

    case actionTypes.DELETAR_LOJA_PARTICIPANTE_REQUISICAO:
      return {
        ...state, deletandoLojaParticipante: true,
      };
    case actionTypes.DELETAR_LOJA_PARTICIPANTE_SUCESSO:
      return {
        ...state,
        deletandoLojaParticipante: false,
        lojasParticipantes: _.omit(state.lojasParticipantes, action.payload.data.content.id),
      };

    // Ilegiveis
    case actionTypes.RECUPERAR_INELEGIVEIS_REQUISICAO:
      return {
        ...state, recuperandoIlegiveis: true, inelegiveis: {},
      };
    case actionTypes.RECUPERAR_INELEGIVEIS_SUCESSO:
      return {
        ...state,
        recuperandoIlegiveis: false,
        inelegiveis: _.mapKeys(action.payload.data.content, 'id'),
      };

    case actionTypes.CRIAR_INELEGIVEL_SUCESSO: {
      const { content } = action.payload.data;
      return {
        ...state,
        inelegiveis: { ...state.inelegiveis, [content.id]: content },
      };
    }

    case actionTypes.DELETAR_INELEGIVEL_REQUISICAO:
      return {
        ...state, deletandoIlegivel: true,
      };
    case actionTypes.DELETAR_INELEGIVEL_SUCESSO:
      return {
        ...state,
        deletandoIlegivel: false,
        inelegiveis: _.omit(state.inelegiveis, action.payload.data.content.id),
      };

    // Sorteios
    case actionTypes.RECUPERAR_SORTEIOS_REQUISICAO:
      if (action.inBackground) return { ...state };
      return {
        ...state, recuperandoSorteios: true, sorteios: {},
      };
    case actionTypes.RECUPERAR_SORTEIOS_SUCESSO:
      return {
        ...state,
        recuperandoSorteios: false,
        sorteios: _.mapKeys(action.payload.data.content, 'id'),
      };

    case actionTypes.ELEGER_GANHADORES_SORTEIO_REQUISICAO:
      return {
        ...state, elegendoGanhadores: true,
      };

    case actionTypes.CRIAR_SORTEIO_REQUISICAO:
    case actionTypes.ATUALIZAR_SORTEIO_REQUISICAO:
      return {
        ...state, salvandoSorteio: true,
      };
    case actionTypes.CRIAR_SORTEIO_SUCESSO:
    case actionTypes.ATUALIZAR_SORTEIO_SUCESSO:
    case actionTypes.ELEGER_GANHADORES_SORTEIO_SUCESSO: {
      const { content } = action.payload.data;
      return {
        ...state,
        salvandoSorteio: false,
        elegendoGanhadores: false,
        sorteios: { ...state.sorteios, [content.id]: content },
      };
    }

    case actionTypes.DELETAR_SORTEIO_REQUISICAO:
      return {
        ...state, deletandoSorteio: true,
      };
    case actionTypes.DELETAR_SORTEIO_SUCESSO:
      return {
        ...state,
        deletandoSorteio: false,
        sorteios: _.omit(state.sorteios, action.payload.data.content.id),
      };

    // Números da Sorte
    case actionTypes.RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE_REQUISICAO:
      return { ...state, recuperandoTotalizacaoNumerosPorLoja: true };
    case actionTypes.RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE_SUCESSO:
      return {
        ...state,
        recuperandoTotalizacaoNumerosPorLoja: false,
        totalizacaoNumerosGeradosPorLoja: action.payload.data.content,
      };

    case actionTypes.LIMPAR_TOTALIZACAO_NUMEROS_DA_SORTE:
      return { ...state, totalizacaoNumerosGeradosPorLoja: {} };

    case actionTypes.PESQUISAR_NUMEROS_DA_SORTE_REQUISICAO: {
      const { contexto } = action;
      const numerosSorte = {
        ...state.numerosSorte,
        [contexto]: {
          ...state.numerosSorte[contexto],
          pesquisando: true,
        },
      };
      return { ...state, numerosSorte };
    }
    case actionTypes.PESQUISAR_NUMEROS_DA_SORTE_SUCESSO: {
      const { items, pagination } = action.payload.data.content;
      const { contexto } = action.meta.previousAction;
      const { numeros } = state.numerosSorte[contexto];
      const numerosSorte = {
        ...state.numerosSorte,
        [contexto]: {
          numeros: pagination.pageIndex === 1 ? items : [...numeros, ...items],
          pesquisando: false,
          pagination,
        },
      };
      return { ...state, numerosSorte };
    }

    case actionTypes.LIMPAR_NUMEROS_DA_SORTE: {
      const { contexto } = action;
      const numerosSorte = {
        ...state.numerosSorte,
        [contexto]: {
          ...state.numerosSorte[contexto],
          numeros: [],
          pagination: { pageIndex: 1 },
        },
      };
      return { ...state, numerosSorte };
    }

    case actionTypes.CRIAR_CAMPANHA_ERRO:
    case actionTypes.ATUALIZAR_CAMPANHA_ERRO:
    case actionTypes.PESQUISAR_CAMPANHAS_ERRO:
    case actionTypes.RECUPERAR_CAMPANHA_ERRO:
    case actionTypes.CANCELAR_CAMPANHA_ERRO:
    case actionTypes.RECUPERAR_LOJAS_PARTICIPANTES_ERRO:
    case actionTypes.CRIAR_LOJAS_PARTICIPANTES_ERRO:
    case actionTypes.DELETAR_LOJA_PARTICIPANTE_ERRO:
    case actionTypes.RECUPERAR_INELEGIVEIS_ERRO:
    case actionTypes.DELETAR_INELEGIVEL_ERRO:
    case actionTypes.RECUPERAR_SORTEIOS_ERRO:
    case actionTypes.CRIAR_SORTEIO_ERRO:
    case actionTypes.ATUALIZAR_SORTEIO_ERRO:
    case actionTypes.DELETAR_SORTEIO_ERRO:
    case actionTypes.ELEGER_GANHADORES_SORTEIO_ERRO:
    case actionTypes.PREPARAR_VIGENCIA_CAMPANHA_ERRO:
    case actionTypes.FINALIZAR_CAMPANHA_ERRO:
    case actionTypes.RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE_ERRO:
    case actionTypes.RECUPERAR_LISTA_CAMPANHAS_ERRO:
    case actionTypes.PESQUISAR_NUMEROS_DA_SORTE_ERRO: {
      const numerosSorte = {
        porLoja: {
          ...state.numerosSorte.porLoja,
          pesquisando: false,
        },
        porCliente: {
          ...state.numerosSorte.porCliente,
          pesquisando: false,
        },
      };
      return {
        ...state,
        pesquisando: false,
        recuperando: false,
        salvando: false,
        recuperandoLojasParticipantes: false,
        criandoLojasParticipantes: false,
        deletandoLojaParticipante: false,
        recuperandoIlegiveis: false,
        deletandoIlegivel: false,
        recuperandoSorteios: false,
        salvandoSorteio: false,
        deletandoSorteio: false,
        cancelando: false,
        elegendoGanhadores: false,
        recuperandoTotalizacaoNumerosPorLoja: false,
        recuperandoListaCampanhas: false,
        numerosSorte,
      };
    }
    default:
      return state;
  }
};

export default campanha;
