import React from 'react';
import { render } from 'react-dom';
import appInicializer from '@linear-front-end/infra/lib/appInitializer';
import * as projectNames from '@linear-front-end/infra/lib/projectNames';
import Home from '@linear-front-end/commons/lib/home';
import AppRoutes from './modules/routes';
import rootReducer from './rootReducer';

render(
  appInicializer(<Home projectName={projectNames.ERP} appRoutes={AppRoutes} />, rootReducer),
  document.getElementById('root'),
);
