import React from 'react';
import { getUrlConfirmacaoCadastro } from '@linear-front-end/infra/lib/frontUrls';
import { SEGURANCA } from '@linear-front-end/infra/lib/projectModules';

export const NAME = 'usuario';
export const TAB_USUARIO = 'TAB_USUARIO';

export const INITIAL_REDUX_STATE = {
  itens: [],
  current: {
    data: {},
    perfis: [],
    credenciais: [],
  },
  itensPaginacao: {
    hasNextPage: false,
    pageIndex: 0,
  },
  recuperando: false,
  pesquisando: false,
  salvando: false,
  deletando: false,
  recuperandoPerfis: false,
  configurandoPerfis: false,
  defaultData: {
    idParceiro: 0,
    notificar: true,
    urlConfirmaCadastro: getUrlConfirmacaoCadastro(),
    perfis: [],
    credencialLocal: {
      email: '',
      nomeUsuario: '',
    },
  },
};
export const UsuarioContext = React.createContext();
export const VIEW_TABS_IDS = {
  basicos: 'dadosBasicos',
  perfil: 'perfil',
};

export const FEATURE = `${SEGURANCA}_USUARIOS`;
const PERMISSAO_COMUM = { module: SEGURANCA, feature: FEATURE };

export const PERMISSAO_USUARIOS = {
  GRAVAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_GRAVAR`,
  },
  EXCLUIR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_EXCLUIR`,
  },
  CONSULTAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_CONSULTAR`,
  },
};
