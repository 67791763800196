import React from 'react';
import Panel, { PanelContent, PanelHeader } from 'linear-react-components-ui/lib/panel';
import Table, { TRow, TBody, Td, Th, THeader } from 'linear-react-components-ui/lib/table';
import _ from 'lodash';
import { DonwnloadDocumentosContext } from '../../constants';

const fieldNameColumn = { width: '150px' };
const filtroValues = (value) => {
  if (!_.isArray(value)) return value;
  return (
    <ul style={{ margin: '10px 0 10px 0', float: 'left' }}>
      {value.map(v => <li key={v}>{v}</li>)}
    </ul>
  );
};

const Filtros = () => (
  <DonwnloadDocumentosContext.Consumer>
    {({ t, downloadCorrente: download }) => (
      <Panel style={{ height: 'auto' }}>
        <PanelHeader
          headerColored={false}
          title={t('documentosEletronicos:downloadLote.labels.panelFiltros.title')} />
        <PanelContent>
          <Table hovered bordered headerColored>
            <THeader>
              <TRow>
                <Th>{t('documentosEletronicos:downloadLote.labels.panelFiltros.tipoFiltro')}</Th>
                <Th>{t('documentosEletronicos:downloadLote.labels.panelFiltros.valores')}</Th>
              </TRow>
            </THeader>
            <TBody>
              {download && _.map(download.metadados.filtros, filtro => (
                <TRow key={`filtro-${filtro.label}`}>
                  <Td style={fieldNameColumn}>
                    <span style={{ fontWeight: 'bold' }}>{filtro.label}</span>
                  </Td>
                  <Td>{filtroValues(filtro.value)}</Td>
                </TRow>
              ))}
            </TBody>
          </Table>
        </PanelContent>
      </Panel>
    )}
  </DonwnloadDocumentosContext.Consumer>
);

export default Filtros;
