import _ from 'lodash';
import * as constants from './constants';

const obterPesquisando = state => state[constants.NAME].pesquisando;
const obterNotificacoes = state => (
  _.orderBy(
    _.map(state[constants.NAME].itens, item => item),
    ['criadoEm'], ['desc'],
  )
);
const obterRecuperando = state => state[constants.NAME].recuperando;
const obterSalvando = state => state[constants.NAME].salvando;
const getDefaultData = state => JSON.parse(JSON.stringify(state[constants.NAME].defaultData));
const obterNotificacaoSelecionada = state => state[constants.NAME].current;
const obterCancelando = state => state[constants.NAME].cancelando;
const obterEnviandoParaPublicacao = state => state[constants.NAME].enviandoParaPublicacao;

export {
  obterPesquisando,
  obterSalvando,
  obterNotificacoes,
  getDefaultData,
  obterRecuperando,
  obterNotificacaoSelecionada,
  obterCancelando,
  obterEnviandoParaPublicacao,
};
