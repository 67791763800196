import * as actionTypes from './actionTypes';
import { PERMISSAO_GRUPOS_VENDEDORES } from './constants';

export function pesquisarGruposVendedores(data) {
  return {
    type: actionTypes.PESQUISAR_GRUPOS_VENDEDORES,
    payload: {
      request: {
        data,
        url: 'cadastros/grupos-vendedores/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        modelKey: 'gruposVendedores.model.one',
        action: 'search',
      },
      permissionAttr: PERMISSAO_GRUPOS_VENDEDORES.PESQUISAR,
    },
  };
}

export function recuperarGrupoVendedor(id, visualizacao = false) {
  return {
    type: actionTypes.RECUPERAR_GRUPO_VENDEDOR,
    payload: {
      request: {
        url: `cadastros/grupos-vendedores/${id}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        modelKey: 'gruposVendedores.model.one',
      },
      permissionAttr: visualizacao ? PERMISSAO_GRUPOS_VENDEDORES.VISUALIZAR : undefined,
    },
  };
}

export function criarGrupoVendedor(data) {
  return {
    type: actionTypes.CRIAR_GRUPO_VENDEDOR,
    payload: {
      request: {
        data,
        url: 'cadastros/grupos-vendedores',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        modelKey: 'gruposVendedores.model.one',
      },
    },
  };
}

export function atualizarGrupoVendedor(data, id) {
  return {
    type: actionTypes.ATUALIZAR_GRUPO_VENDEDOR,
    payload: {
      request: {
        data,
        url: `cadastros/grupos-vendedores/${id}`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        modelKey: 'gruposVendedores.model.one',
      },
    },
  };
}

export function deletarGrupoVendedor(id) {
  return {
    type: actionTypes.DELETAR_GRUPO_VENDEDOR,
    payload: {
      request: {
        url: `cadastros/grupos-vendedores/${id}`,
        method: 'DELETE',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        modelKey: 'gruposVendedores.model.one',
      },
    },
  };
}

export function restaurarGrupoVendedor(id, filtro) {
  return {
    type: actionTypes.RESTAURAR_GRUPO_VENDEDOR,
    filtro,
    payload: {
      request: {
        url: `cadastros/grupos-vendedores/${id}/restaurar`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        modelKey: 'gruposVendedores.model.one',
        action: 'restore',
      },
    },
  };
}
