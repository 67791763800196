import React from 'react';
// TODO - Entender o porque não esta conseguindo importar do arquivo parceiro raiz
// import parceiros from '../parceiros' (esta retornando undefined)
import * as parceiroConstants from '../parceiros/constants';

export const NAME = 'contato';
export const INITIAL_REDUX_STATE = {
  itens: {},
  recuperandoContatos: false,
  current: undefined,
  recuperando: false,
  salvando: false,
  deletando: false,
  contatoRecuperadoPorCPF: undefined,
  defaultData: {
    tipoPessoa: parceiroConstants.TIPO_PESSOA.fisica,
    email: '',
    telefone: '',
    pessoaFisica: {},
    caracteristicas: [parceiroConstants.CARACTERISTICAS.contato],
    contato: { idParceiro: 0 },
  },
};
export const ContatoContext = React.createContext();
export const FormContext = React.createContext();
