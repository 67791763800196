import React from 'react';

export const NAME = 'documentosEletronicos';
export const TAB_PAGE_NOTAS_FISCAIS = 'TAB_PAGE_NOTAS_FISCAIS';

export const INITIAL_REDUX_STATE = {
  itens: [],
  current: {},
  recuperando: false,
  pesquisando: false,
  recuperandoEventos: false,
  eventos: [],
  recuperandoConteudo: false,
  idDocumentoConteudo: undefined,
  solicitandoDownloadLote: false,
};

export const DocumentoEletronicoContext = React.createContext();
export const FormContext = React.createContext();

export const EVENTOS_NOTAS = {
  Protocolamento: 1,
  Cancelamento: 2,
  Inutilizacao: 3,
  Contingencia: 4,
};

export const CRITERIOS_FILTRO = {
  chaveAcesso: '',
  dataEmissaoInicial: null,
  dataEmissaoFinal: null,
  cnpjCliente: '',
  cpfCliente: '',
  codigoNota: '',
  protocolo: '',
  empresasIds: [],
  tiposDocumentosEletronicosIds: [],
  eventosIds: [],
};

export const MODULE = 'SG_DOCUMENTOS_ELETRONICOS';
export const FEATURE = 'SG_DOCUMENTOS_ELETRONICOS_NOTA_FISCAL_EMITIDA';
const PERMISSAO_COMUM = { module: MODULE, feature: FEATURE };

export const PERMISSAO_DOCUMENTOS_ELETRONICOS_NOTA_FISCAL_EMITIDA = {
  INTEGRACOES: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_INTEGRACOES`,
  },
  PESQUISAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_PESQUISAR`,
  },
  EVENTOS: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_EVENTOS`,
  },
  VISUALIZAR: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_VISUALIZAR`,
  },
  DOWNLOAD: {
    ...PERMISSAO_COMUM,
    operation: `${FEATURE}_DOWNLOAD`,
  },
};
