import * as actionTypes from './actionTypes';
import { PERMISSAO_NOTIFICACOES } from './constants';

export function pesquisarNotificacoes(data = {}) {
  return {
    type: actionTypes.PESQUISAR_NOTIFICACOES,
    payload: {
      request: {
        data,
        url: 'app/push/notificacoes/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'ePromo',
        success: false,
        failure: {
          key: 'notificacao.mensagens.getAll.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_NOTIFICACOES.CONSULTAR,
    },
  };
}

export function criarNotificacao(data) {
  return {
    type: actionTypes.CRIAR_NOTIFICACAO,
    payload: {
      request: {
        data,
        url: 'app/push/notificacoes',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'ePromo',
        success: {
          key: 'notificacao.mensagens.post.httpSucesso',
        },
        failure: {
          key: 'notificacao.mensagens.post.httpFalha',
        },
      },
    },
  };
}

export function recuperarNotificacao(id) {
  return {
    type: actionTypes.RECUPERAR_NOTIFICACAO,
    payload: {
      request: {
        url: `app/push/notificacoes/${id}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'ePromo',
        success: false,
        failure: {
          key: 'notificacao.mensagens.get.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_NOTIFICACOES.VISUALIZAR,
    },
  };
}

export function atualizarNotificacao(data, id) {
  return {
    type: actionTypes.ATUALIZAR_NOTIFICACAO,
    payload: {
      request: {
        data,
        url: `app/push/notificacoes/${id}`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'ePromo',
        success: {
          key: 'notificacao.mensagens.put.httpSucesso',
        },
        failure: {
          key: 'notificacao.mensagens.put.httpFalha',
        },
      },
    },
  };
}

export function cancelarNotificacao(id) {
  return {
    type: actionTypes.CANCELAR_NOTIFICACAO,
    payload: {
      request: {
        data: {},
        url: `app/push/notificacoes/${id}/cancelar`,
        method: 'PATCH',
      },
      feedbackMessages: {
        i18nNS: 'ePromo',
        success: {
          key: 'notificacao.mensagens.cancelar.httpSucesso',
        },
        failure: {
          key: 'notificacao.mensagens.cancelar.httpFalha',
        },
      },
    },
  };
}

export function enviarParaPublicacao(id) {
  return {
    type: actionTypes.ENVIAR_PARA_PUBLICACAO,
    payload: {
      request: {
        data: {},
        url: `app/push/notificacoes/${id}/enviar-para-publicacao`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'ePromo',
        success: {
          key: 'notificacao.mensagens.publicar.httpSucesso',
        },
        failure: {
          key: 'notificacao.mensagens.publicar.httpFalha',
        },
      },
    },
  };
}

export function verificarUsuarioHabilitadoParaPush() {
  return {
    type: actionTypes.USUARIO_HABILITADO_PUSH,
    payload: {
      request: {
        url: '/app/push/tokens/usuario-habilitado',
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'ePromo',
        success: false,
        failure: {
          key: 'notificacao.mensagens.usuarioHabilitado.httpFalha',
        },
      },
    },
  };
}

export function enviarPushTeste(data) {
  return {
    type: actionTypes.ENVIAR_PUSH_TESTE,
    payload: {
      request: {
        data,
        url: '/app/push/notificacoes/enviar-teste',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'ePromo',
        success: {
          key: 'notificacao.mensagens.pushTeste.httpSucesso',
        },
        failure: {
          key: 'notificacao.mensagens.pushTeste.httpFalha',
        },
      },
    },
  };
}

export function recuperarAlcance(id) {
  return {
    type: actionTypes.ALCANCE_PUSH,
    payload: {
      request: {
        url: `/app/push/notificacoes/${id}/alcance`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'ePromo',
        success: false,
        failure: {
          key: 'notificacao.mensagens.alcance.httpFalha',
        },
      },
    },
  };
}
