"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.moduleLabel = exports.COMPRAS = exports.VENDAS = exports.FISCAL = exports.FINANCEIRO = exports.PRODUTOS = exports.E_PROMO = exports.DOCUMENTOS_ELETRONICOS = exports.ADMINISTRATIVO_LINEAR = exports.CENTRAL_NEGOCIOS = exports.COTACOES_FORNECEDOR = exports.CONFIGURACOES = exports.SEGURANCA = exports.CADASTROS = void 0;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Os valores das constants devem ser o mesmo que é retornado pelo backend na chamada
 * da API /autorizacao/modulos
 */
var CADASTROS = 'SG_CADASTROS';
exports.CADASTROS = CADASTROS;
var SEGURANCA = 'SG_SEGURANCA';
exports.SEGURANCA = SEGURANCA;
var CONFIGURACOES = 'SG_CONFIGURACOES';
exports.CONFIGURACOES = CONFIGURACOES;
var COTACOES_FORNECEDOR = 'SG_COTACOES';
exports.COTACOES_FORNECEDOR = COTACOES_FORNECEDOR;
var CENTRAL_NEGOCIOS = 'SG_CENTRAL_NEGOCIOS';
exports.CENTRAL_NEGOCIOS = CENTRAL_NEGOCIOS;
var ADMINISTRATIVO_LINEAR = 'SG_ADMINISTRATIVO';
exports.ADMINISTRATIVO_LINEAR = ADMINISTRATIVO_LINEAR;
var DOCUMENTOS_ELETRONICOS = 'SG_DOCUMENTOS_ELETRONICOS';
exports.DOCUMENTOS_ELETRONICOS = DOCUMENTOS_ELETRONICOS;
var E_PROMO = 'SG_E_PROMO';
exports.E_PROMO = E_PROMO;
var PRODUTOS = 'SG_PRODUTOS';
exports.PRODUTOS = PRODUTOS;
var FINANCEIRO = 'SG_FINANCEIRO';
exports.FINANCEIRO = FINANCEIRO;
var FISCAL = 'SG_FISCAL';
exports.FISCAL = FISCAL;
var VENDAS = 'SG_VENDAS';
exports.VENDAS = VENDAS;
var COMPRAS = 'SG_COMPRAS';
exports.COMPRAS = COMPRAS;

var moduleLabel = function moduleLabel(moduleName) {
  var _labels;

  var labels = (_labels = {}, _defineProperty(_labels, CADASTROS, 'Cadastros'), _defineProperty(_labels, SEGURANCA, 'Segurança'), _defineProperty(_labels, CONFIGURACOES, 'Configuracões'), _defineProperty(_labels, COTACOES_FORNECEDOR, 'Cotações'), _defineProperty(_labels, CENTRAL_NEGOCIOS, 'Central Negócios'), _defineProperty(_labels, ADMINISTRATIVO_LINEAR, 'Administrativo'), _defineProperty(_labels, DOCUMENTOS_ELETRONICOS, 'Documentos Eletrônicos'), _defineProperty(_labels, E_PROMO, 'E-Promo'), _defineProperty(_labels, PRODUTOS, 'Produtos'), _defineProperty(_labels, FINANCEIRO, 'Financeiro'), _defineProperty(_labels, FISCAL, 'Fiscal'), _defineProperty(_labels, VENDAS, 'Vendas'), _defineProperty(_labels, COMPRAS, 'Compras'), _labels);
  return labels[moduleName];
};

exports.moduleLabel = moduleLabel;