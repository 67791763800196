import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'linear-react-components-ui/lib/inputs/text';
import Fieldset from 'linear-react-components-ui/lib/fieldset';
import GridCol, { GridRow } from 'linear-react-components-ui/lib/gridlayout';
import LabelMessages from 'linear-react-components-ui/lib/labelMessages';
import { Field, FieldArray } from 'linear-react-components-ui/lib/form';
import SpinnerLoading from 'linear-react-components-ui/lib/spinner';
import * as validators from '@linear-front-end/infra/lib/fieldValidators';
import perfil from '../../../perfis';
import funcionalidadeParceiro from '../../../../cadastros/parceiros';

const { CARACTERISTICAS } = funcionalidadeParceiro.constants;
const mensagemParceiro = ({ t, parceiro }) => {
  const { nome } = parceiro.pessoaFisica;
  if (parceiro.caracteristicas.includes(CARACTERISTICAS.contato)) {
    return t('cadastros:parceiro.labels.infoUsuarioContato', { nome });
  }
  return t('cadastros:parceiro.labels.infoUsuarioColaborador', { nome });
};

class Campos extends Component {
  state = {
    perfilSelecionado: false,
  }

  componentDidUpdate() {
    if (!this.focusSetado) this.nomeUsuarioRef.focus();
    this.focusSetado = true;
  }

  obterLegendaPerfil = () => {
    const { t } = this.props;
    const { perfilSelecionado } = this.state;
    let legenda = t('commons:labels.perfil');
    if (!perfilSelecionado) {
      legenda += `: ${t('commons:mensagensValidacoesCampos.selecaoObrigatoria')}`;
    }
    return legenda;
  }

  render() {
    const {
      t, handlerVerificarUsuarioExistente, usuarioCadastrado, handlerAtualizarPerfis,
      verificandoUsuario,
    } = this.props;
    const { perfilSelecionado } = this.state;
    const desabilitaCampo = usuarioCadastrado !== undefined;
    return (
      <GridRow style={{ height: '100%' }}>
        <GridCol cols="6 6 6 6">
          <Fieldset title={t('seguranca:usuario.labels.dadosBasicos')}>
            <LabelMessages type="info" style={{ marginBottom: 10 }} iconName="information">
              {mensagemParceiro({ ...this.props })}
            </LabelMessages>
            {usuarioCadastrado && (
              <LabelMessages type="warning" style={{ marginBottom: 10 }} iconName="warning">
                {t('seguranca:usuario.mensagens.usuarioJaCadastrado')}
              </LabelMessages>
            )}
            <Field
              component={TextField}
              name="credencialLocal.email"
              label={t('commons:labels.email')}
              inputRef={(ref) => { this.nomeUsuarioRef = ref; }}
              required
              validators={[validators.required]}
              onBlur={(e) => { handlerVerificarUsuarioExistente(e.target.value); }}
              disabled={desabilitaCampo}
              rightElements={
                verificandoUsuario ? [<div key="loading-email"><SpinnerLoading size="24" /></div>] : []
              } />
            <Field
              component={TextField}
              name="nome"
              label={t('commons:pessoas.fisica.nome')}
              required
              validators={[validators.required]}
              disabled={desabilitaCampo} />
            <Field
              component={TextField}
              name="credencialLocal.nomeUsuario"
              label={t('seguranca:usuario.campos.nomeUsuario')}
              hint={t('seguranca:usuario.campos.nomeUsuarioHint')}
              required
              validators={[validators.required]}
              disabled={desabilitaCampo} />
          </Fieldset>
        </GridCol>
        <GridCol cols="6 6 6 6">
          <Fieldset
            title={this.obterLegendaPerfil()}
            hasError={!perfilSelecionado}>
            <FieldArray
              component={perfil.PerfisSelecaoContainer}
              name="perfis"
              valuePropName="idsSelecionados"
              changePropName="handlerAtualizarSelecionados"
              validators={[validators.arrayEmpty(t('seguranca:usuario.mensagens.PerfilObrigatorio'))]}
              showTableBorder
              handlerSelecionados={(ids) => {
                handlerAtualizarPerfis(ids);
                this.setState({ perfilSelecionado: ids.length > 0 });
              }} />
          </Fieldset>
        </GridCol>
      </GridRow>
    );
  }
}

Campos.propTypes = {
  t: PropTypes.func.isRequired,
  handlerVerificarUsuarioExistente: PropTypes.func.isRequired,
  usuarioCadastrado: PropTypes.object,
  handlerAtualizarPerfis: PropTypes.func.isRequired,
  verificandoUsuario: PropTypes.bool.isRequired,
};

Campos.defaultProps = {
  usuarioCadastrado: undefined,
};

export default Campos;
