import _ from 'lodash';
import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

const promocao = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.PESQUISAR_PRODUTOS_EM_PROMOCAO_REQUISICAO:
      return {
        ...state, pesquisandoProdutosEmPromocao: true, produtosEmPromocao: {},
      };
    case actionTypes.PESQUISAR_PRODUTOS_EM_PROMOCAO_SUCESSO: {
      const { items, pagination } = action.payload.data.content;
      return {
        ...state,
        pesquisandoProdutosEmPromocao: false,
        produtosEmPromocao: { itens: _.mapKeys(items, 'id'), pagination },
      };
    }

    case actionTypes.RECUPERAR_PROMOCOES_PRODUTO_REQUISICAO:
      return {
        ...state, recuperandoPromocoesProduto: true, promocoesProduto: [],
      };
    case actionTypes.RECUPERAR_PROMOCOES_PRODUTO_SUCESSO:
      return {
        ...state, recuperandoPromocoesProduto: false, promocoesProduto: action.payload.data,
      };

    case actionTypes.SUBSTITUIR_PRODUTO_EM_PROMOCAO: {
      const { produto } = action;
      const itens = { ...state.produtosEmPromocao.itens, [produto.id]: produto };
      return {
        ...state,
        produtosEmPromocao: { ...state.produtosEmPromocao, itens, produtoSelecionado: produto },
      };
    }

    case actionTypes.SETAR_PRODUTO_SELECIONADO: {
      const { produto } = action;
      return {
        ...state,
        produtosEmPromocao: { ...state.produtosEmPromocao, produtoSelecionado: produto },
      };
    }

    case actionTypes.RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO_REQUISICAO:
      return {
        ...state,
        recuperandoSemelhantesEmPromocao: true,
        semelhantesEmPromocao: [],
      };

    case actionTypes.RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO_SUCESSO:
      return {
        ...state,
        semelhantesEmPromocao: action.payload.data.content,
        recuperandoSemelhantesEmPromocao: false,
      };

    case actionTypes.RECUPERAR_PRODUTOS_SEMELHANTES_EM_PROMOCAO_ERRO:
    case actionTypes.RECUPERAR_PROMOCOES_PRODUTO_ERRO:
    case actionTypes.PESQUISAR_PRODUTOS_EM_PROMOCAO_ERRO:
      return {
        ...state,
        pesquisandoProdutosEmPromocao: false,
        recuperandoPromocoesProduto: false,
        recuperandoSemelhantesEmPromocao: false,
      };
    default:
      return state;
  }
};

export default promocao;
