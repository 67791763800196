export const UPLOAD_IMAGEM_PRODUTO = 'UPLOAD_IMAGEM_PRODUTO';
export const UPLOAD_IMAGEM_PRODUTO_REQUISICAO = 'UPLOAD_IMAGEM_PRODUTO_REQUISICAO';
export const UPLOAD_IMAGEM_PRODUTO_SUCESSO = 'UPLOAD_IMAGEM_PRODUTO_SUCESSO';
export const UPLOAD_IMAGEM_PRODUTO_ERRO = 'UPLOAD_IMAGEM_PRODUTO_ERRO';

export const ATACHAR_IMAGEM_AO_PRODUTO = 'ATACHAR_IMAGEM_AO_PRODUTO';
export const ATACHAR_IMAGEM_AO_PRODUTO_REQUISICAO = 'ATACHAR_IMAGEM_AO_PRODUTO_REQUISICAO';
export const ATACHAR_IMAGEM_AO_PRODUTO_SUCESSO = 'ATACHAR_IMAGEM_AO_PRODUTO_SUCESSO';
export const ATACHAR_IMAGEM_AO_PRODUTO_ERRO = 'ATACHAR_IMAGEM_AO_PRODUTO_ERRO';

export const PESQUISAR_IMAGENS_PRODUTOS = 'PESQUISAR_IMAGENS_PRODUTOS';
export const PESQUISAR_IMAGENS_PRODUTOS_REQUISICAO = 'PESQUISAR_IMAGENS_PRODUTOS_REQUISICAO';
export const PESQUISAR_IMAGENS_PRODUTOS_SUCESSO = 'PESQUISAR_IMAGENS_PRODUTOS_SUCESSO';
export const PESQUISAR_IMAGENS_PRODUTOS_ERRO = 'PESQUISAR_IMAGENS_PRODUTOS_ERRO';

export const PESQUISAR_IMAGENS_PRODUTOS_SELECAO = 'PESQUISAR_IMAGENS_PRODUTOS_SELECAO';
export const PESQUISAR_IMAGENS_PRODUTOS_SELECAO_REQUISICAO = 'PESQUISAR_IMAGENS_PRODUTOS_SELECAO_REQUISICAO';
export const PESQUISAR_IMAGENS_PRODUTOS_SELECAO_SUCESSO = 'PESQUISAR_IMAGENS_PRODUTOS_SELECAO_SUCESSO';
export const PESQUISAR_IMAGENS_PRODUTOS_SELECAO_ERRO = 'PESQUISAR_IMAGENS_PRODUTOS_SELECAO_ERRO';
export const LIMPAR_IMAGENS_PRODUTOS_SELECAO = 'LIMPAR_IMAGENS_PRODUTOS_SELECAO';

export const RECUPERAR_IMAGEM_PRODUTO = 'RECUPERAR_IMAGEM_PRODUTO';
export const RECUPERAR_IMAGEM_PRODUTO_REQUISICAO = 'RECUPERAR_IMAGEM_PRODUTO_REQUISICAO';
export const RECUPERAR_IMAGEM_PRODUTO_SUCESSO = 'RECUPERAR_IMAGEM_PRODUTO_SUCESSO';
export const RECUPERAR_IMAGEM_PRODUTO_ERRO = 'RECUPERAR_IMAGEM_PRODUTO_ERRO';

export const ATUALIZAR_IMAGEM_PRODUTO = 'ATUALIZAR_IMAGEM_PRODUTO';
export const ATUALIZAR_IMAGEM_PRODUTO_REQUISICAO = 'ATUALIZAR_IMAGEM_PRODUTO_REQUISICAO';
export const ATUALIZAR_IMAGEM_PRODUTO_SUCESSO = 'ATUALIZAR_IMAGEM_PRODUTO_SUCESSO';
export const ATUALIZAR_IMAGEM_PRODUTO_ERRO = 'ATUALIZAR_IMAGEM_PRODUTO_ERRO';

export const DELETAR_IMAGEM_PRODUTO = 'DELETAR_IMAGEM_PRODUTO';
export const DELETAR_IMAGEM_PRODUTO_REQUISICAO = 'DELETAR_IMAGEM_PRODUTO_REQUISICAO';
export const DELETAR_IMAGEM_PRODUTO_SUCESSO = 'DELETAR_IMAGEM_PRODUTO_SUCESSO';
export const DELETAR_IMAGEM_PRODUTO_ERRO = 'DELETAR_IMAGEM_PRODUTO_ERRO';

export const SETAR_IMAGEM_PRODUTO_SELECIONADA = 'SETAR_IMAGEM_PRODUTO_SELECIONADA';
