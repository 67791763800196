import React from 'react';

export const NAME = 'downloadDocumentosEletronicos';
export const INITIAL_REDUX_STATE = {
  itens: [],
  current: undefined,
  pesquisando: false,
  recuperando: false,
};

export const DonwnloadDocumentosContext = React.createContext();

export const TABS_IDS = {
  geral: 'geral',
  etapas: 'etapas',
};

export const STATUS = {
  pendente: 'pendente',
  processamento: 'processamento',
  falha: 'falha',
  concluido: 'concluido',
};
