/**
 * IMPORTANTE !! Um contato também é um Parceiro, porém com caracteristicas de CONTATO.
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DialogQuestion } from 'linear-react-components-ui/lib/dialog';
import { translate } from 'react-i18next';
import Lista from './Lista';
import ActionBar from './ActionBar';
import * as selectors from '../selectors';
import * as actions from '../actions';
import { ContatoContext } from '../constants';
import usuario from '../../../seguranca/usuarios';
import FormContato from './form';

class Container extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { contatos, contatoSelecionado } = nextProps;
    if (prevState.contatos !== contatos ||
        prevState.contatoSelecionado !== contatoSelecionado) {
      return {
        contatos,
        contatosFiltrados: contatos,
        contatoSelecionado,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { idParceiro, contatos } = props;
    this.state = {
      adicionando: false,
      editando: false,
      deletando: false,
      idContato: undefined,
      idParceiro,
      contatos,
      contatosFiltrados: contatos,
      cadastrandoUsuario: false,
      contatoSelecionado: undefined,
    };
  }

  componentDidMount() {
    const { recuperarContatos, idParceiro } = this.props;
    recuperarContatos(idParceiro);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idParceiro !== this.props.idParceiro) {
      const { recuperarContatos, idParceiro } = this.props;
      recuperarContatos(idParceiro);
    }
  }

  getContextValues = () => ({
    ...this.props,
    ...this.state,
    handlerAdicionar: this.adicionar,
    handlerEditar: this.editar,
    handlerDeletar: (idContato) => { this.setState({ deletando: true, idContato }); },
    handlerFiltrarContatos: this.filtrar,
    handlerGerarUsuario: this.gerarUsuario,
  })

  adicionar = () => {
    const { handlerFormContatoAberto } = this.props;
    this.setState({ adicionando: true }, () => {
      if (handlerFormContatoAberto) handlerFormContatoAberto();
    });
  }

  editar = (idContato) => {
    const { handlerFormContatoAberto } = this.props;
    this.props.recuperarContato(this.state.idParceiro, idContato);
    this.setState({ editando: true, idContato }, () => {
      if (handlerFormContatoAberto) handlerFormContatoAberto();
    });
  }

  gerarUsuario = (idContato) => {
    const { handlerFormContatoAberto } = this.props;
    this.setState({ cadastrandoUsuario: true, idContato }, () => {
      if (handlerFormContatoAberto) handlerFormContatoAberto();
    });
  }

  filtrar = (value) => {
    const { contatos } = this.props;
    const contatosFiltrados = contatos.filter(contato => (
      contato.pessoaFisica.nome.toLowerCase().indexOf(value.toLowerCase()) !== -1));
    this.setState({ contatosFiltrados });
  }

  render() {
    const contextValues = this.getContextValues();
    const { desassociarContato, t, handlerFormContatoAberto, contatos } = this.props;
    const {
      idParceiro, cadastrandoUsuario, idContato, adicionando, editando, contatoSelecionado,
    } = this.state;
    return (
      <ContatoContext.Provider value={contextValues}>
        <ActionBar />
        <Lista />
        {this.state.deletando &&
          <DialogQuestion
            title={t('cadastros:contato.mensagens.dialogDesassociacaoTitulo')}
            text={t('cadastros:contato.mensagens.perguntaDesassociacao')}
            onConfirmClick={() => {
              desassociarContato(idParceiro, [idContato]).then(() => {
                this.setState({ deletando: false, idContato: undefined });
              });
            }}
            onUnconfirmClick={() => {
              this.setState({ deletando: false, idContato: undefined });
            }} />
        }
        { (adicionando || editando) &&
          <FormContato
            {...contextValues}
            contato={contatoSelecionado}
            handlerFecharModal={() => {
              this.setState({ adicionando: false, editando: false }, () => {
                if (handlerFormContatoAberto) handlerFormContatoAberto();
              });
            }} />
        }
        { cadastrandoUsuario &&
          <usuario.Form
            t={t}
            handlerFecharModal={() => {
              this.setState({ cadastrandoUsuario: false }, () => {
                if (handlerFormContatoAberto) handlerFormContatoAberto();
              });
            }}
            adicionando
            idContato={idContato}
            parceiro={contatos.find(c => c.id === idContato)} />
        }

      </ContatoContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  contatos: selectors.obterContatos(state),
  recuperandoContatos: selectors.obterRecuperandoContatos(state),
  recuperando: selectors.obterRecuperando(state),
  contatoSelecionado: selectors.obterContatoSelecionado(state),
  defaultData: selectors.getDefaultData(state),
});

Container.propTypes = {
  recuperarContatos: PropTypes.func.isRequired,
  recuperarContato: PropTypes.func.isRequired,
  atualizarContato: PropTypes.func.isRequired,
  criarContato: PropTypes.func.isRequired,
  desassociarContato: PropTypes.func.isRequired,
  contatos: PropTypes.arrayOf(PropTypes.object).isRequired,
  idParceiro: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  contatoSelecionado: PropTypes.object,
  handlerFormContatoAberto: PropTypes.func,
};

Container.defaultProps = {
  contatoSelecionado: undefined,
  handlerFormContatoAberto: undefined,
};

const connectRedux = connect(mapStateToProps, { ...actions });

export default connectRedux(translate(['common', 'cadastros'])(Container));
