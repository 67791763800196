import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

const tenants = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.RECUPERAR_TENANTS_DISPONIVEIS_APP_REQUISICAO:
      return {
        ...state, recuperando: true, tenantsApp: [],
      };
    case actionTypes.RECUPERAR_TENANTS_DISPONIVEIS_APP_SUCESSO:
      return {
        ...state, recuperando: false, tenantsApp: action.payload.data.content,
      };

    case actionTypes.RECUPERAR_TENANTS_DISPONIVEIS_APP_ERRO:
      return {
        ...state, recuperando: false,
      };
    default:
      return state;
  }
};

export default tenants;
