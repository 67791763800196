import * as constants from './constants';

export const obterPesquisando = state => state[constants.NAME].pesquisando;
export const obterRecuperando = state => state[constants.NAME].recuperando;
export const obterSalvando = state => state[constants.NAME].salvando;
export const obterUsuarios = state => state[constants.NAME].itens;
export const getDefaultData = (state) => {
  const { defaultData } = state[constants.NAME];
  return JSON.parse(JSON.stringify(defaultData));
};
export const obterItensPaginacao = state => state[constants.NAME].itensPaginacao;

export const obterUsuarioSelecionado = (state) => {
  const { current } = state[constants.NAME];
  if (current) {
    const { data, perfis } = current;
    return { ...data, perfis };
  }
  return undefined;
};
export const obterRecuperandoPerfis = state => state[constants.NAME].recuperandoPerfis;
export const obterConfigurandoPerfis = state => state[constants.NAME].configurandoPerfis;
