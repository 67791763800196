import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

let index;
let result;
let newData;
let previousItems;
let currentItems;

const perfis = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.CRIAR_PERFIL_REQUISICAO:
    case actionTypes.ATUALIZAR_PERFIL_REQUISICAO:
    case actionTypes.INCLUIR_RECURSOS_PERFIL_REQUISICAO:
      return {
        ...state, salvando: true,
      };
    case actionTypes.CRIAR_PERFIL_SUCESSO: {
      const perfilCorrente = action.payload.data.content;
      return {
        ...state,
        salvando: false,
        itens: [...state.itens, perfilCorrente],
        perfilCorrente,
      };
    }

    case actionTypes.ATUALIZAR_PERFIL_SUCESSO:
      // TODO - Refatorar
      result = action.payload.data.content;
      index = state.itens.findIndex(item => item.id === result.id);
      newData = state.itens;
      newData[index] = result;
      return {
        ...state, salvando: false, itens: newData, perfilCorrente: result,
      };

    case actionTypes.PESQUISAR_PERFIS_REQUISICAO:
      return { ...state, pesquisando: true };

    case actionTypes.PESQUISAR_PERFIS_SUCESSO:
      result = action.payload.data.content;
      previousItems = state.itens || [];
      currentItems = result.pagination.pageIndex === 0
        ? result.items
        : [...previousItems, ...result.items];

      return {
        ...state,
        pesquisando: false,
        itensPaginacao: { ...state.itensPaginacao, ...result.pagination },
        itens: currentItems,
      };

    case actionTypes.RECUPERAR_PERFIL_REQUISICAO:
      return {
        ...state,
        recuperando: true,
        perfilCorrente: undefined,
      };
    case actionTypes.RECUPERAR_PERFIL_SUCESSO:
      return {
        ...state,
        recuperando: false,
        perfilCorrente: action.payload.data.content,
      };

    case actionTypes.RECUPERAR_RECURSOS_VINCULADOS_PERFIL_REQUISICAO:
      return {
        ...state,
        recursosVinculados: {
          recuperando: true,
          items: [],
        },
      };
    case actionTypes.RECUPERAR_RECURSOS_VINCULADOS_PERFIL_SUCESSO:
      return {
        ...state,
        recursosVinculados: {
          recuperando: false,
          items: action.payload.data.content.items,
        },
      };

    case actionTypes.RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL_REQUISICAO:
      return {
        ...state,
        recursos: {
          recuperando: true, items: [],
        },
      };
    case actionTypes.RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL_SUCESSO:
      return {
        ...state,
        recursos: {
          recuperando: false,
          items: action.payload.data.content.items,
        },
      };

    case actionTypes.INCLUIR_RECURSOS_PERFIL_SUCESSO:
      return { ...state, salvando: false };

    case actionTypes.DELETAR_PERFIL_REQUISICAO:
      return { ...state, deletando: true };
    case actionTypes.DELETAR_PERFIL_SUCESSO: {
      const { idPerfil } = action.meta.previousAction;
      return {
        ...state,
        deletando: false,
        itens: state.itens.filter(i => i.id !== idPerfil),
      };
    }

    case actionTypes.PESQUISAR_PERFIS_ERRO:
    case actionTypes.RECUPERAR_PERFIL_ERRO:
    case actionTypes.CRIAR_PERFIL_ERRO:
    case actionTypes.ATUALIZAR_PERFIL_ERRO:
    case actionTypes.RECUPERAR_RECURSOS_VINCULADOS_PERFIL_ERRO:
    case actionTypes.RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL_ERRO:
    case actionTypes.DELETAR_PERFIL_ERRO:
      return {
        ...state,
        salvando: false,
        pesquisando: false,
        recursosVinculados: { ...state.recursosVinculados, recuperando: false },
        recursos: { ...state.recursos, recuperando: false },
        deletando: false,
      };
    default:
      return state;
  }
};

export default perfis;
