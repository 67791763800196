import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import SpinnerLoading from 'linear-react-components-ui/lib/spinner';
import Table, { TRow, TBody, Td, Th, THeader } from 'linear-react-components-ui/lib/table';
import Panel, { PanelContent, PanelHeader } from 'linear-react-components-ui/lib/panel';
import ToolBar, { ToolBarGroup, ButtonBar } from 'linear-react-components-ui/lib/toolbar';
import Checkbox from 'linear-react-components-ui/lib/checkbox';
import SearchField from 'linear-react-components-ui/lib/inputs/search';
import Icon from 'linear-react-components-ui/lib/icons';
import SemDados from '@linear-front-end/commons/lib/semDados';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { TIPO_SELECAO } from '../constants';

class ListaSelecaoContainer extends Component {
  static getDerivedStateFromProps = (props, state) => {
    const { listaItens, idsFromProps } = state;
    const { empresas, lojasApp, idsSelecionados, selecao } = props;
    const itens = (selecao === TIPO_SELECAO.empresas ? empresas : lojasApp);

    if (idsFromProps.length !== idsSelecionados.length || (!listaItens && itens.length > 0)) {
      return {
        idsSelecionados,
        idsFromProps: idsSelecionados,
        listaItens: !listaItens ? itens : listaItens,
      };
    }
    return null;
  }

  state = {
    idsSelecionados: this.props.idsSelecionados,
    // eslint-disable-next-line react/no-unused-state
    idsFromProps: this.props.idsSelecionados,
    listaItens: undefined,
  }

  componentDidMount() {
    const { selecao, pesquisarEmpresasTenant, pesquisarLojasAppTenant } = this.props;
    setTimeout(() => {
      if (selecao === TIPO_SELECAO.empresas) {
        pesquisarEmpresasTenant();
      } else {
        pesquisarLojasAppTenant();
      }
    }, 200);
  }

  onCheckboxClick = (id) => {
    let { idsSelecionados } = this.state;
    const { handlerMudarSelecionados } = this.props;

    if (idsSelecionados.includes(id)) {
      idsSelecionados = idsSelecionados.filter(item => item !== id);
    } else {
      idsSelecionados = [...idsSelecionados, id];
    }
    this.setState({ idsSelecionados });
    if (handlerMudarSelecionados) handlerMudarSelecionados(idsSelecionados);
  }

  marcarTodos = () => {
    const { handlerMudarSelecionados } = this.props;
    const idsSelecionados = this.obterItens().map(m => m.id);
    this.setState({ idsSelecionados });
    if (handlerMudarSelecionados) handlerMudarSelecionados(idsSelecionados);
  };

  desmarcarTodos = () => {
    const { handlerMudarSelecionados } = this.props;
    this.setState({ idsSelecionados: [] });
    if (handlerMudarSelecionados) handlerMudarSelecionados([]);
  };

  pesquisar = (value = '') => {
    let itens = JSON.parse(JSON.stringify(this.obterItens()));
    if (value.trim() !== '') {
      itens = itens.filter(item => (
        item.nomeFantasia.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.cnpj.indexOf(value) > -1));
    }
    this.setState({ listaItens: itens });
  };

  obterItens = () => {
    const { empresas, lojasApp, selecao, idsOcultacao } = this.props;
    let itens = (selecao === TIPO_SELECAO.empresas ? empresas : lojasApp);

    if (itens && idsOcultacao) itens = itens.filter(e => !idsOcultacao.includes(e.id));
    return itens;
  }

  renderizarEmpresas = () => {
    const {
      pesquisando, t, showTableHeader, showTableBorder, showRowBorder, idsOcultacao,
    } = this.props;
    const { idsSelecionados, listaItens } = this.state;
    let itens = listaItens;

    if (itens && idsOcultacao) itens = itens.filter(e => !idsOcultacao.includes(e.id));

    return (
      <Fragment>
        {itens && itens.length > 0 ? (
          <Table condensed selectable={false} bordered={showTableBorder}>
            {showTableHeader &&
              <THeader>
                <TRow>
                  <Th />
                  <Th>{t('commons:pessoas.juridica.nomeFantasia')}</Th>
                </TRow>
              </THeader>}
            <TBody>
              {itens && itens.map(item => (
                <TRow
                  key={`item-${item.id}`}
                  onClick={() => { this.onCheckboxClick(item.id); }}
                  rowBorder={showRowBorder}>
                  <Td style={{ width: 30 }}>
                    <Checkbox checked={idsSelecionados.includes(item.id)} />
                  </Td>
                  <Td>{`${item.nomeFantasia} (${item.cnpj})`}</Td>
                </TRow>
              ))}
            </TBody>
          </Table>
        ) : (
          (!pesquisando && itens !== undefined && (
            <SemDados
              title="Sem Registros"
              info="Não há registros a serem listados." />
          ))
        )}
        <div>{pesquisando && <SpinnerLoading />}</div>
      </Fragment>
    );
  };

  renderizarPanel = () => {
    const { t, panelHeight } = this.props;
    return (
      <Panel style={{ height: panelHeight, display: 'flex', flexDirection: 'column' }}>
        <PanelHeader
          title={t('commons:labels.lojas')}
          styleForTitle={{ fontSize: 14 }}
          style={{ padding: '5px 0 5px 0' }}>
          <ToolBar>
            <ToolBarGroup style={{ float: 'right' }}>
              <SearchField
                onChange={(e) => { this.pesquisar(e.target.value); }}
                onReset={() => { this.pesquisar(); }}
                placeHolder={t('commons:pessoas.hintPesquisaNomeCnpj')} />
              <ButtonBar
                transparent
                size="small"
                iconAlign="left"
                icon={<Icon name="stackCheck" />}
                tooltip={t('commons:buttons.marcarTodos')}
                tooltipPosition="left"
                onClick={() => { this.marcarTodos(); }} />
              <ButtonBar
                transparent
                size="small"
                iconAlign="left"
                icon={<Icon name="stackCancel" />}
                tooltip={t('commons:buttons.desmarcarTodos')}
                tooltipPosition="left"
                onClick={() => { this.desmarcarTodos(); }} />
            </ToolBarGroup>
          </ToolBar>
        </PanelHeader>
        <PanelContent style={{ padding: 6 }}>
          {this.renderizarEmpresas()}
        </PanelContent>
      </Panel>
    );
  };

  render() {
    const { showToolbar } = this.props;
    return (
      <Fragment>
        {showToolbar ? (
          this.renderizarPanel()
        ) : (
          this.renderizarEmpresas()
        )}
      </Fragment>
    );
  }
}

ListaSelecaoContainer.propTypes = {
  pesquisarEmpresasTenant: PropTypes.func.isRequired,
  pesquisando: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  selecao: PropTypes.string.isRequired,
  pesquisarLojasAppTenant: PropTypes.func.isRequired,
  empresas: PropTypes.arrayOf(PropTypes.objectOf),
  lojasApp: PropTypes.arrayOf(PropTypes.objectOf),
  idsSelecionados: PropTypes.arrayOf(PropTypes.number),
  idsOcultacao: PropTypes.arrayOf(PropTypes.number),
  handlerMudarSelecionados: PropTypes.func,
  showTableHeader: PropTypes.bool,
  showTableBorder: PropTypes.bool,
  showRowBorder: PropTypes.bool,
  showToolbar: PropTypes.bool,
  panelHeight: PropTypes.number,
};

ListaSelecaoContainer.defaultProps = {
  empresas: [],
  lojasApp: [],
  idsSelecionados: [],
  idsOcultacao: undefined,
  handlerMudarSelecionados: undefined, // Propriedade necessária para atender o FieldArray do form
  showTableHeader: true,
  showTableBorder: false,
  showRowBorder: true,
  showToolbar: false,
  panelHeight: 300,
};

const mapStateToProps = state => ({
  pesquisando: selectors.obterPesquisando(state),
  empresas: selectors.obterEmpresas(state),
  lojasApp: selectors.obterLojasApp(state),
});

const connectRedux = connect(mapStateToProps, { ...actions });
export default connectRedux(translate(['common', 'cadastros'])(ListaSelecaoContainer));
