"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _auth_token = require("linear-react-commons/lib/utils/auth_token");

var actionTypes = _interopRequireWildcard(require("./actionTypes"));

var _constants = require("./constants");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var credenciais = function credenciais() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _constants.INITIAL_REDUX_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.RECUPERAR_CREDENCIAIS_REQUISICAO:
      return _objectSpread(_objectSpread({}, state), {}, {
        recuperando: true,
        itens: {}
      });

    case actionTypes.RECUPERAR_CREDENCIAIS_SUCESSO:
      return _objectSpread(_objectSpread({}, state), {}, {
        recuperando: false,
        itens: _lodash["default"].mapKeys(action.payload.data.content, 'id')
      });

    case actionTypes.ATUALIZAR_CREDENCIAL_REQUISICAO:
      return _objectSpread(_objectSpread({}, state), {}, {
        salvando: true
      });

    case actionTypes.ATUALIZAR_CREDENCIAL_SUCESSO:
      {
        var content = action.payload.data.content;
        return _objectSpread(_objectSpread({}, state), {}, {
          salvando: false,
          itens: _objectSpread(_objectSpread({}, state.itens), {}, _defineProperty({}, content.id, content))
        });
      }

    case actionTypes.TROCAR_SENHA_USUARIO_REQUISICAO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          trocandoSenha: true
        });
      }

    case actionTypes.TROCAR_SENHA_USUARIO_SUCESSO:
      {
        (0, _auth_token.storeUserAuthInfo)(_constants.AUTH_COOKIE_NAME, action.payload.data.content);
        return _objectSpread(_objectSpread({}, state), {}, {
          trocandoSenha: false
        });
      }

    case actionTypes.RECUPERAR_URLS_AVATAR_USUARIO_REQUISICAO:
      return _objectSpread(_objectSpread({}, state), {}, {
        recuperandoAvatar: true,
        urlsAvatar: {}
      });

    case actionTypes.RECUPERAR_URLS_AVATAR_USUARIO_SUCESSO:
      return _objectSpread(_objectSpread({}, state), {}, {
        recuperandoAvatar: false,
        urlsAvatar: action.payload.data.content
      });

    case actionTypes.RECUPERAR_URLS_AVATAR_USUARIO_LOCAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        recuperandoAvatar: false,
        urlsAvatar: action.data
      });

    case actionTypes.UPLOAD_AVATAR_USUARIO_REQUISICAO:
      return _objectSpread(_objectSpread({}, state), {}, {
        enviandoAvatar: true
      });

    case actionTypes.UPLOAD_AVATAR_USUARIO_SUCESSO:
      return _objectSpread(_objectSpread({}, state), {}, {
        enviandoAvatar: false,
        urlsAvatar: action.payload.data.content
      });

    case actionTypes.RECUPERAR_CREDENCIAIS_ERRO:
    case actionTypes.ATUALIZAR_CREDENCIAL_ERRO:
    case actionTypes.TROCAR_SENHA_USUARIO_ERRO:
    case actionTypes.RECUPERAR_URLS_AVATAR_USUARIO_ERRO:
    case actionTypes.UPLOAD_AVATAR_USUARIO_ERRO:
      return _objectSpread(_objectSpread({}, state), {}, {
        salvando: false,
        recuperando: false,
        trocandoSenha: false,
        recuperandoAvatar: false,
        enviandoAvatar: false
      });

    default:
      return state;
  }
};

var _default = credenciais;
exports["default"] = _default;