import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Progress from 'linear-react-components-ui/lib/progress';
import * as projectNames from '@linear-front-end/infra/lib/projectNames';

const Cadastros = React.lazy(() => import('./cadastros'));
const Seguranca = React.lazy(() => import('./seguranca'));
const Configuracoes = React.lazy(() => import('./configuracoes'));
const DocumentosEletronicos = React.lazy(() => import('./documentos_eletronicos'));
const EPromo = React.lazy(() => import('./e_promo'));

const AppRoutes = () => (
  <Suspense fallback={
    <div style={{ width: '100%', gridColumn: '1 / 3' }}>
      <Progress type="success" progressWidth="40%" height={4} indeterminate indeterminateDelay={0} />
    </div>
  }>
    <Switch>
      <Route path={`/${projectNames.ERP}/cadastros`} component={Cadastros} />
      ,
      <Route path={`/${projectNames.ERP}/seguranca`} component={Seguranca} />
      ,
      <Route path={`/${projectNames.ERP}/configuracoes`} component={Configuracoes} />
      ,
      <Route path={`/${projectNames.ERP}/documentos_eletronicos`} component={DocumentosEletronicos} />
      ,
      <Route path={`/${projectNames.ERP}/e_promo`} component={EPromo} />
      ,
    </Switch>
  </Suspense>
);

export default AppRoutes;
