import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ModalForm from 'linear-react-components-ui/lib/dialog/form';
import Icon from 'linear-react-components-ui/lib/icons';
import Button from 'linear-react-components-ui/lib/buttons';
import { connect } from 'react-redux';
import GridCol, { GridRow } from 'linear-react-components-ui/lib/gridlayout';
import { translate } from 'react-i18next';
import * as actions from '../actions';
import * as selectors from '../selectors';
import { DonwnloadDocumentosContext } from '../constants';
import Lista from './Lista';
import Detalhes from './detalhes';

class Container extends PureComponent {
  componentDidMount() {
    const { pesquisarDownloadsDocumentos, downloadId, recuperarDownloadDocumentos } = this.props;
    let data = {};
    if (downloadId) data = { id: downloadId };
    setTimeout(() => {
      pesquisarDownloadsDocumentos(data)
        .then(() => {
          if (downloadId) recuperarDownloadDocumentos(downloadId);
        });
    }, 200);
  }

  componentWillUnmount() {
    this.props.limparDownloads();
  }

  getContextValues = () => ({
    ...this.props,
    ...this.state,
  });

  render() {
    const { t, handlerClose } = this.props;
    const contextValues = this.getContextValues();
    return (
      <DonwnloadDocumentosContext.Provider value={contextValues}>
        <ModalForm
          title={t('documentosEletronicos:downloadLote.labels.listaTituloModal')}
          width="98%"
          height="95%"
          icon={<Icon name="file_download" color="#A9A8A8" size={24} />}
          handlerClose={() => { handlerClose(); }}
          closeOnEsc
          styleForContent={{ padding: '5px 0 0 0' }}
          buttons={[
            <Button
              key="botao-fechar"
              label={t('commons:buttons.fechar')}
              onClick={() => { handlerClose(); }} />,
          ]}>
          <GridRow style={{ height: '96%' }}>
            <GridCol cols="3 3 3 3">
              <Lista />
            </GridCol>
            <GridCol cols="9 9 9 9">
              <Detalhes />
            </GridCol>
          </GridRow>
        </ModalForm>
      </DonwnloadDocumentosContext.Provider>
    );
  }
}

Container.propTypes = {
  t: PropTypes.func.isRequired,
  handlerClose: PropTypes.func.isRequired,
  pesquisarDownloadsDocumentos: PropTypes.func.isRequired,
  recuperarDownloadDocumentos: PropTypes.func.isRequired,
  limparDownloads: PropTypes.func.isRequired,
  downloadId: PropTypes.number,
};

Container.defaultProps = {
  downloadId: undefined,
};

const mapStateToProps = state => ({
  pesquisando: selectors.obterPesquisando(state),
  downloads: selectors.obterDownloads(state),
  recuperando: selectors.obterRecuperando(state),
  downloadCorrente: selectors.obterDownloadCorrente(state),
});

const connectRedux = connect(mapStateToProps, { ...actions });
export default connectRedux(translate(['common', 'documentosEletronicos'])(Container));
