import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import List, { ListItem } from 'linear-react-components-ui/lib/list';
import GridCol, { GridRow } from 'linear-react-components-ui/lib/gridlayout';
import SearchField from 'linear-react-components-ui/lib/inputs/search';
import { PrimaryButton } from 'linear-react-components-ui/lib/buttons';
import LabelMessages from 'linear-react-components-ui/lib/labelMessages';
import SpinnerLoading from 'linear-react-components-ui/lib/spinner';
import _ from 'lodash';
import SemDados from '@linear-front-end/commons/lib/semDados';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

class ImagensPrivadasContainer extends Component {
  state = {
    imagemSelecionada: undefined,
    pesquisaFixaPorCodigoProduto: this.props.produtoId !== undefined,
  }

  componentDidMount() {
    if (this.state.pesquisaFixaPorCodigoProduto) this.pesquisar();
  }

  componentWillUnmount() {
    this.props.liparImagensProdutosSelecao();
  }

  pesquisar = (value = '') => {
    const { pesquisarImagens, liparImagensProdutosSelecao, produtoId } = this.props;
    const { imagemSelecionada, pesquisaFixaPorCodigoProduto } = this.state;
    let filtro = { codigoBarra: value };

    if (pesquisaFixaPorCodigoProduto) {
      filtro = { produtoId };
    } else if (value.trim() === '' || imagemSelecionada) {
      this.setState({ imagemSelecionada: undefined });
      liparImagensProdutosSelecao();
      return;
    } else {
      filtro = { codigoBarra: value };
      if (!/^\d+$/.test(value)) filtro = { descricaoProduto: value };
    }
    pesquisarImagens(filtro, true);
  }

  renderizarImagens = ({ imagens, pesquisando, t }) => (
    <List selectable style={{ height: 400, marginTop: 5 }}>
      {imagens.length === 0 && !pesquisando ? (
        <SemDados
          hideIcon
          title={t('commons:labels.semImagens')}
          info={t('cadastros:produtoImagem.mensagens.produtoSemImagemPrivada')} />
      ) : (
        <Fragment>
          {imagens.map(imagem => (
            <ListItem
              leftElement={
                <div className="image-container">
                  <img src={imagem.urlImagemThumb} alt="" className="thumbsize" />
                </div>
              }
              itemId={`${imagem.id}`}
              key={`${imagem.id}`}
              text={imagem.descricaoProduto}
              onClick={() => { this.setState({ imagemSelecionada: imagem }); }} />
          ))}
          { pesquisando && <SpinnerLoading />}
        </Fragment>
      )}
    </List>
  )

  renderizarImagemSelecionada = () => {
    const {
      handlerUsarImagem, pesquisando, disableButton, t, imagens,
    } = this.props;
    const { imagemSelecionada } = this.state;

    if (imagemSelecionada && !pesquisando) {
      return (
        <Fragment>
          <img src={imagemSelecionada.urlImagemOriginal} alt="" className="originalsize" />
          <PrimaryButton
            label={t('commons:buttons.usarImagem')}
            onClick={() => { handlerUsarImagem(imagemSelecionada.id); }}
            isLoading={disableButton}
            disabled={disableButton}
            style={{ marginTop: 10 }} />
        </Fragment>
      );
    } if (imagens.length > 0 && !pesquisando) {
      return (
        <SemDados
          hideIcon
          title={t('commons:labels.selecioneImagem')}
          info={t('cadastros:produtoImagem.mensagens.clicarImagemLista')} />
      );
    }
    return null;
  }

  render() {
    const { t } = this.props;
    const { pesquisaFixaPorCodigoProduto } = this.state;
    const pesquisaImagens = _.debounce((condicao) => { this.pesquisar(condicao); }, 300);
    return (
      <GridRow style={{ height: '100%' }}>
        <GridCol cols="12 12 6 6 6">
          {pesquisaFixaPorCodigoProduto ? (
            <LabelMessages type="info" iconName="information">
              {t('cadastros:produtoImagem.mensagens.imagensPrivadasProduto')}
            </LabelMessages>
          ) : (
            <SearchField
              onChange={(e) => { pesquisaImagens(e.target.value); }}
              placeHolder={t('tabelasSistema:gtins.labels.pesquisarPlaceholder')}
              onReset={() => { pesquisaImagens(''); }} />
          )}
          {this.renderizarImagens({ ...this.props })}
        </GridCol>
        <GridCol cols="12 12 6 6 6">
          <div className="image-container">
            {this.renderizarImagemSelecionada()}
          </div>
        </GridCol>
      </GridRow>
    );
  }
}

ImagensPrivadasContainer.propTypes = {
  pesquisarImagens: PropTypes.func.isRequired,
  pesquisando: PropTypes.bool.isRequired,
  imagens: PropTypes.arrayOf(PropTypes.objectOf),
  handlerUsarImagem: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
  liparImagensProdutosSelecao: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  // Passando valor para esta propriedade, entende-se que a pesquisa será limitada
  // ao código do produto, não permitindo pesquisar por descrição ou codigo de barras
  produtoId: PropTypes.number,
};

ImagensPrivadasContainer.defaultProps = {
  imagens: [],
  disableButton: undefined,
  produtoId: undefined,
};

const mapStateToProps = state => ({
  pesquisando: selectors.obterPesquisandoImagensSelecao(state),
  imagens: selectors.obterImagensSelecao(state),
});

const { pesquisarImagens, liparImagensProdutosSelecao } = actions;
const connectRedux = connect(mapStateToProps, { pesquisarImagens, liparImagensProdutosSelecao });
export default connectRedux(translate(['common', 'tabelasSistema'])(ImagensPrivadasContainer));
