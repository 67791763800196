import React from 'react';
import Table, { TRow, TBody, Th, Td } from 'linear-react-components-ui/lib/table';
import Panel, { PanelContent, PanelHeader } from 'linear-react-components-ui/lib/panel';
import { formatDateTime } from 'linear-react-commons/lib/utils/datetime_format';
import SpinnerLoading from 'linear-react-components-ui/lib/spinner';
import LabelMessages from 'linear-react-components-ui/lib/labelMessages';
import { DonwnloadDocumentosContext } from '../../constants';

const fieldNameColumn = { width: '200px' };
const DadosBasicos = () => (
  <DonwnloadDocumentosContext.Consumer>
    {({ t, downloadCorrente: download, recuperando }) => (
      <Panel style={{ height: 'auto' }}>
        <PanelHeader
          headerColored={false}
          title={t('documentosEletronicos:downloadLote.labels.panelGeral.title')}>
          {recuperando && <div><SpinnerLoading /></div>}
        </PanelHeader>
        <PanelContent>
          <Table vertical condensed>
            <TBody>
              <TRow>
                <Th style={fieldNameColumn}>
                  {t('documentosEletronicos:downloadLote.campos.dataSolicitacao')}
                </Th>
                <Td>{download && formatDateTime(download.criadoEm)}</Td>
              </TRow>
              <TRow>
                <Th style={fieldNameColumn}>
                  {t('documentosEletronicos:downloadLote.campos.inicioProcessamento')}
                </Th>
                <Td>{download && formatDateTime(download.iniciadoEm)}</Td>
              </TRow>
              <TRow>
                <Th style={fieldNameColumn}>
                  {t('documentosEletronicos:downloadLote.campos.terminoProcessamento')}
                </Th>
                <Td>{download && formatDateTime(download.terminadoEm)}</Td>
              </TRow>
              <TRow>
                <Th style={fieldNameColumn}>
                  {t('documentosEletronicos:downloadLote.campos.duracaoProcessamento')}
                </Th>
                <Td>{download && download.duracaoProcessamento}</Td>
              </TRow>
            </TBody>
          </Table>
          <br />
          {download && download.metadados.urlArquivoZip && !download.expirado && (
            <div>
              <LabelMessages type="warning" style={{ marginBottom: 8 }}>
                {t('documentosEletronicos:downloadLote.labels.infoExpiracao', { data: formatDateTime(download.expiraEm) })}
              </LabelMessages>
              <a
                href={download.metadados.urlArquivoZip}
                style={{ fontSize: 16, marginTop: 10, textDecoration: 'underline' }}>
                {t('documentosEletronicos:downloadLote.labels.linkDownload')}
              </a>
            </div>
          )}
          {download && download.expirado && (
            <LabelMessages type="warning" style={{ marginBottom: 8 }}>
              {t('documentosEletronicos:downloadLote.labels.infoExpirado')}
            </LabelMessages>
          )}
        </PanelContent>
      </Panel>
    )}
  </DonwnloadDocumentosContext.Consumer>
);

export default DadosBasicos;
