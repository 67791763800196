import React from 'react';

export const NAME = 'clientesApp';
export const TAB_PAGE_CLIENTES_APP = 'TAB_PAGE_CLIENTES_APP';
export const INITIAL_REDUX_STATE = {
  itens: [],
  pesquisando: false,
};
export const ClientesAppContext = React.createContext();
export const CRITERIOS_FILTRO = {
  nome: '',
  sobreNome: '',
  email: '',
  celular: '',
  dataCadastroInicial: null,
  dataCadastroFinal: null,
};

export const TABS_VISUALIZACAO = {
  tabBasico: 'tab-basico',
  tabNumerosSorte: 'tab-numerosdasorte',
};
