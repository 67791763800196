import * as actionTypes from './actionTypes';

export function criarContato(data) {
  return {
    type: actionTypes.CRIAR_CONTATO,
    payload: {
      request: {
        data,
        url: 'cadastros/parceiros',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'contato.mensagens.post.httpSucesso',
        },
        failure: {
          key: 'contato.mensagens.post.httpFalha',
        },
      },
    },
  };
}

export function recuperarContatos(idParceiro, atualizandoLista = false) {
  return {
    type: actionTypes.RECUPERAR_CONTATOS,
    atualizandoLista,
    payload: {
      request: {
        url: `cadastros/parceiros/${idParceiro}/contatos`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        failure: {
          key: 'contato.mensagens.getAll.httpFalha',
        },
      },
    },
  };
}

export function recuperarContato(idParceiro, idContato) {
  return {
    type: actionTypes.RECUPERAR_CONTATO,
    payload: {
      request: {
        url: `cadastros/parceiros/${idParceiro}/contatos/${idContato}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        failure: {
          key: 'contato.mensagens.get.httpFalha',
        },
      },
    },
  };
}

export function atualizarContato(data, idParceiro) {
  return {
    type: actionTypes.ATUALIZAR_CONTATO,
    idParceiro,
    payload: {
      request: {
        data,
        url: `cadastros/parceiros/${idParceiro}`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'contato.mensagens.put.httpSucesso',
        },
        failure: {
          key: 'contato.mensagens.put.httpFalha',
        },
      },
    },
  };
}

export function removerContato(idParceiro) {
  return {
    type: actionTypes.REMOVER_CONTATO,
    payload: {
      request: {
        url: `cadastros/parceiros/${idParceiro}/contatos/excluir`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'contato.mensagens.delete.httpSucesso',
        },
        failure: {
          key: 'contato.mensagens.delete.httpFalha',
        },
      },
    },
  };
}

export function recuperarContatoPorCPF(data) {
  return {
    type: actionTypes.RECUPERAR_CONTATO_POR_CPF,
    payload: {
      request: {
        data,
        url: 'cadastros/parceiros/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: false,
        failure: {
          key: 'contato.mensagens.recuperarContatoPorCPF.httpFalha',
        },
      },
    },
  };
}

export function associarContato(idParceiro, data) {
  return {
    type: actionTypes.ASSOCIAR_CONTATO_AO_PARCEIRO,
    payload: {
      request: {
        data,
        url: `cadastros/parceiros/${idParceiro}/contatos/incluir`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'contato.mensagens.associarContato.httpSucesso',
        },
        failure: {
          key: 'contato.mensagens.associarContato.httpFalha',
        },
      },
    },
  };
}

export function desassociarContato(idParceiro, data) {
  return {
    type: actionTypes.DESASSOCIAR_CONTATO_DO_PARCEIRO,
    payload: {
      request: {
        data,
        url: `cadastros/parceiros/${idParceiro}/contatos/excluir`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'cadastros',
        success: {
          key: 'contato.mensagens.desassociarContato.httpSucesso',
        },
        failure: {
          key: 'contato.mensagens.desassociarContato.httpFalha',
        },
      },
    },
  };
}

export function resetarContatoRecuperadoPorCPF() {
  return {
    type: actionTypes.RESETAR_CONTATO_RECUPERADO_POR_EMAIL,
  };
}

export function atualizarContatoAposCriarUsuario(idContato) {
  return {
    type: actionTypes.ATUALIZAR_CONTATO_APOS_CRIAR_USUARIO,
    idContato,
  };
}
