export const PESQUISAR_GRUPOS_EMPRESAS = 'PESQUISAR_GRUPOS_EMPRESAS';
export const PESQUISAR_GRUPOS_EMPRESAS_REQUISICAO = 'PESQUISAR_GRUPOS_EMPRESAS_REQUISICAO';
export const PESQUISAR_GRUPOS_EMPRESAS_SUCESSO = 'PESQUISAR_GRUPOS_EMPRESAS_SUCESSO';
export const PESQUISAR_GRUPOS_EMPRESAS_ERRO = 'PESQUISAR_GRUPOS_EMPRESAS_ERRO';

export const RECUPERAR_GRUPO_EMPRESA = 'RECUPERAR_GRUPO_EMPRESA';
export const RECUPERAR_GRUPO_EMPRESA_REQUISICAO = 'RECUPERAR_GRUPO_EMPRESA_REQUISICAO';
export const RECUPERAR_GRUPO_EMPRESA_SUCESSO = 'RECUPERAR_GRUPO_EMPRESA_SUCESSO';
export const RECUPERAR_GRUPO_EMPRESA_ERRO = 'RECUPERAR_GRUPO_EMPRESA_ERRO';

export const CRIAR_GRUPO_EMPRESA = 'CRIAR_GRUPO_EMPRESA';
export const CRIAR_GRUPO_EMPRESA_REQUISICAO = 'CRIAR_GRUPO_EMPRESA_REQUISICAO';
export const CRIAR_GRUPO_EMPRESA_SUCESSO = 'CRIAR_GRUPO_EMPRESA_SUCESSO';
export const CRIAR_GRUPO_EMPRESA_ERRO = 'CRIAR_GRUPO_EMPRESA_ERRO';

export const ATUALIZAR_GRUPO_EMPRESA = 'ATUALIZAR_GRUPO_EMPRESA';
export const ATUALIZAR_GRUPO_EMPRESA_REQUISICAO = 'ATUALIZAR_GRUPO_EMPRESA_REQUISICAO';
export const ATUALIZAR_GRUPO_EMPRESA_SUCESSO = 'ATUALIZAR_GRUPO_EMPRESA_SUCESSO';
export const ATUALIZAR_GRUPO_EMPRESA_ERRO = 'ATUALIZAR_GRUPO_EMPRESA_ERRO';

export const DELETAR_GRUPO_EMPRESA = 'DELETAR_GRUPO_EMPRESA';
export const DELETAR_GRUPO_EMPRESA_REQUISICAO = 'DELETAR_GRUPO_EMPRESA_REQUISICAO';
export const DELETAR_GRUPO_EMPRESA_SUCESSO = 'DELETAR_GRUPO_EMPRESA_SUCESSO';
export const DELETAR_GRUPO_EMPRESA_ERRO = 'DELETAR_GRUPO_EMPRESA_ERRO';

export const RESTAURAR_GRUPO_EMPRESA = 'RESTAURAR_GRUPO_EMPRESA';
export const RESTAURAR_GRUPO_EMPRESA_REQUISICAO = 'RESTAURAR_GRUPO_EMPRESA_REQUISICAO';
export const RESTAURAR_GRUPO_EMPRESA_SUCESSO = 'RESTAURAR_GRUPO_EMPRESA_SUCESSO';
export const RESTAURAR_GRUPO_EMPRESA_ERRO = 'RESTAURAR_GRUPO_EMPRESA_ERRO';

export const RECUPERAR_EMPRESAS_DISPONIVEIS = 'RECUPERAR_EMPRESAS_DISPONIVEIS';
export const RECUPERAR_EMPRESAS_DISPONIVEIS_REQUISICAO = 'RECUPERAR_EMPRESAS_DISPONIVEIS_REQUISICAO';
export const RECUPERAR_EMPRESAS_DISPONIVEIS_SUCESSO = 'RECUPERAR_EMPRESAS_DISPONIVEIS_SUCESSO';
export const RECUPERAR_EMPRESAS_DISPONIVEIS_ERRO = 'RECUPERAR_EMPRESAS_DISPONIVEIS_ERRO';

export const RECUPERAR_EMPRESAS_VINCULADAS = 'RECUPERAR_EMPRESAS_VINCULADAS';
export const RECUPERAR_EMPRESAS_VINCULADAS_REQUISICAO = 'RECUPERAR_EMPRESAS_VINCULADAS_REQUISICAO';
export const RECUPERAR_EMPRESAS_VINCULADAS_SUCESSO = 'RECUPERAR_EMPRESAS_VINCULADAS_SUCESSO';
export const RECUPERAR_EMPRESAS_VINCULADAS_ERRO = 'RECUPERAR_EMPRESAS_VINCULADAS_ERRO';

export const REMOVER_EMPRESA = 'REMOVER_EMPRESA';
export const REMOVER_EMPRESA_REQUISICAO = 'REMOVER_EMPRESA_REQUISICAO';
export const REMOVER_EMPRESA_SUCESSO = 'REMOVER_EMPRESA_SUCESSO';
export const REMOVER_EMPRESA_ERRO = 'REMOVER_EMPRESA_ERRO';
