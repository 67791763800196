import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

const curvaAbcApp = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.CARREGAR_PRODUTOS_CURVA_ABC_VENDAS_APP_REQUISICAO:
      return {
        ...state, carregando: true, produtos: [],
      };
    case actionTypes.CARREGAR_PRODUTOS_CURVA_ABC_VENDAS_APP_SUCESSO:
      return {
        ...state, carregando: false, produtos: action.payload.data.content,
      };

    case actionTypes.CARREGAR_PRODUTOS_CURVA_ABC_VENDAS_APP_ERRO:
      return {
        ...state,
        carregando: false,
      };
    default:
      return state;
  }
};

export default curvaAbcApp;
