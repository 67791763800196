import * as constants from './constants';

export const obterPesquisando = state => state[constants.NAME].pesquisando;
export const obterRecuperando = state => state[constants.NAME].recuperando;
export const obterRecuperandoConteudo = state => state[constants.NAME].recuperandoConteudo;
export const obterIdDocumentoConteudo = state => state[constants.NAME].idDocumentoConteudo;
export const obterDocumentosEletronicos = state => state[constants.NAME].itens;
export const obterDocumentoEletronicoCorrente = state => state[constants.NAME].current;
export const obterRecuperandoEventos = state => state[constants.NAME].recuperandoEventos;
export const obterEventos = state => state[constants.NAME].eventos;
export const obterSolicitandoDownloadLote = state => state[constants.NAME].solicitandoDownloadLote;
