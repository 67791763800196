import React, { Component } from 'react';
import Tabs, { TabPanel } from 'linear-react-components-ui/lib/tabs';
import { TABS_IDS, DonwnloadDocumentosContext } from '../../constants';
import DadosBasicos from './DadosBasicos';
import Destinatarios from './Destinatarios';
import Filtros from './Filtros';
import Etapas from './etapas';

class Detalhes extends Component {
  state = {
    selectedTabId: TABS_IDS.geral,
  }

  render() {
    const { selectedTabId } = this.state;
    return (
      <DonwnloadDocumentosContext.Consumer>
        {({ t }) => (
          <Tabs
            tabMenuPosition="top"
            tabMenuSize="medium"
            selectedTab={selectedTabId}
            handlerTabClick={(tabId) => {
              this.setState({ selectedTabId: tabId });
            }}
            style={{ position: 'absolute', bottom: 0, top: 0, left: 5, width: '99%' }}>
            <TabPanel
              title={t('documentosEletronicos:downloadLote.labels.abas.geral')}
              id={TABS_IDS.geral}>
              <DadosBasicos />
              <Destinatarios />
              <Filtros />
            </TabPanel>
            <TabPanel
              title={t('documentosEletronicos:downloadLote.labels.abas.progresso')}
              id={TABS_IDS.etapas}
              customClass="etapas-container">
              <Etapas />
            </TabPanel>
          </Tabs>
        )}
      </DonwnloadDocumentosContext.Consumer>
    );
  }
}

export default Detalhes;
