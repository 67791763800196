import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import SpinnerLoading from 'linear-react-components-ui/lib/spinner';
import Table, { TRow, TBody, Td, Th, THeader } from 'linear-react-components-ui/lib/table';
import Checkbox from 'linear-react-components-ui/lib/checkbox';
import * as actions from '../../actions';
import * as selectors from '../../selectors';

class ListaSelecaoContainer extends Component {
  static getDerivedStateFromProps = (props, state) => {
    if (state.idsFromProps.length !== props.idsSelecionados.length) {
      const { idsSelecionados } = props;
      return {
        idsSelecionados,
        idsFromProps: idsSelecionados,
      };
    }
    return null;
  }

  state = {
    idsSelecionados: this.props.idsSelecionados,
    // eslint-disable-next-line react/no-unused-state
    idsFromProps: this.props.idsSelecionados,
  }

  componentDidMount() {
    this.props.pesquisarTiposDocumentosEletronicos();
  }

  onCheckboxClick = (id) => {
    let { idsSelecionados } = this.state;
    const { handlerMudarSelecionados } = this.props;

    if (idsSelecionados.includes(id)) {
      idsSelecionados = idsSelecionados.filter(item => item !== id);
    } else {
      idsSelecionados = [...idsSelecionados, id];
    }
    this.setState({ idsSelecionados });
    if (handlerMudarSelecionados) handlerMudarSelecionados(idsSelecionados);
  }

  render() {
    const {
      tiposDocumentosEletronicos, pesquisando, t, showTableHeader, showTableBorder, showRowBorder,
    } = this.props;
    const { idsSelecionados } = this.state;
    return (
      <Fragment>
        <Table condensed headerColored bordered={showTableBorder}>
          {showTableHeader &&
            <THeader>
              <TRow>
                <Th />
                <Th>{t('commons:labels.descricao')}</Th>
              </TRow>
            </THeader>}
          <TBody>
            {tiposDocumentosEletronicos.map(tipoDoc => (
              <TRow
                key={`tipos-docs-${tipoDoc.id}`}
                onClick={() => { this.onCheckboxClick(tipoDoc.id); }}
                rowBorder={showRowBorder}>
                <Td style={{ width: 30 }}>
                  <Checkbox checked={idsSelecionados.includes(tipoDoc.id)} />
                </Td>
                <Td>{`${tipoDoc.modelo} - ${tipoDoc.sigla}`}</Td>
              </TRow>
            ))}
          </TBody>
        </Table>
        <div>
          {pesquisando && <SpinnerLoading />}
        </div>
      </Fragment>
    );
  }
}

ListaSelecaoContainer.propTypes = {
  pesquisarTiposDocumentosEletronicos: PropTypes.func.isRequired,
  pesquisando: PropTypes.bool.isRequired,
  tiposDocumentosEletronicos: PropTypes.arrayOf(PropTypes.objectOf),
  t: PropTypes.func.isRequired,
  idsSelecionados: PropTypes.arrayOf(PropTypes.number),
  handlerMudarSelecionados: PropTypes.func,
  showTableHeader: PropTypes.bool,
  showTableBorder: PropTypes.bool,
  showRowBorder: PropTypes.bool,
};

ListaSelecaoContainer.defaultProps = {
  tiposDocumentosEletronicos: [],
  idsSelecionados: [],
  handlerMudarSelecionados: undefined, // Propriedade necessária para atender o FieldArray do form
  showTableHeader: true,
  showTableBorder: false,
  showRowBorder: true,
};

const mapStateToProps = state => ({
  pesquisando: selectors.obterPesquisandoTiposDocsEletronicos(state),
  tiposDocumentosEletronicos: selectors.obterTiposDocsEletronicos(state),
});

const { pesquisarTiposDocumentosEletronicos } = actions;
const connectRedux = connect(mapStateToProps, { pesquisarTiposDocumentosEletronicos });
export default connectRedux(translate(['commons', 'tabelasSistema'])(ListaSelecaoContainer));
