import * as actionTypes from './actionTypes';
import { PERMISSAO_PERFIS } from './constants';

export function criarPerfil(data) {
  return {
    type: actionTypes.CRIAR_PERFIL,
    payload: {
      request: {
        data,
        url: 'seguranca/perfis',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'perfil.mensagens.post.httpSucesso',
        },
        failure: {
          key: 'perfil.mensagens.post.httpFalha',
        },
      },
    },
  };
}

export function pesquisarPerfis(data = {}) {
  return {
    type: actionTypes.PESQUISAR_PERFIS,
    payload: {
      request: {
        data,
        url: 'seguranca/perfis/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: false,
        failure: {
          key: 'perfil.mensagens.getAll.httpFalha',
        },
      },
      // TODO: comentado este código temporariamente até que o caso 32077 seja executado
      // permissionAttr: PERMISSAO_PERFIS.CONSULTAR,
    },
  };
}

export function recuperarPerfil(id) {
  return {
    type: actionTypes.RECUPERAR_PERFIL,
    payload: {
      request: {
        url: `seguranca/perfis/${id}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: false,
        failure: {
          key: 'perfil.mensagens.get.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_PERFIS.CONSULTAR,
    },
  };
}

export function atualizarPerfil(id, data) {
  return {
    type: actionTypes.ATUALIZAR_PERFIL,
    payload: {
      request: {
        data,
        url: `seguranca/perfis/${id}`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'perfil.mensagens.put.httpSucesso',
        },
        failure: {
          key: 'perfil.mensagens.put.httpFalha',
        },
      },
    },
  };
}

export function deletarPerfil(id) {
  return {
    type: actionTypes.DELETAR_PERFIL,
    idPerfil: id,
    payload: {
      request: {
        url: `seguranca/perfis/${id}`,
        method: 'DELETE',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'perfil.mensagens.delete.httpSucesso',
        },
        failure: {
          key: 'perfil.mensagens.delete.httpFalha',
        },
      },
    },
  };
}

export function recuperarRecursosDisponiveisPerfil() {
  return {
    type: actionTypes.RECUPERAR_RECURSOS_DISPONIVEIS_PERFIL,
    payload: {
      request: {
        data: { pagination: { pageSize: 10000 } },
        url: 'seguranca/perfis/recursos/disponiveis',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'administrativo',
        success: false,
        failure: {
          key: 'recursos.mensagens.getAll.httpFalha',
        },
      },
    },
  };
}

export function recuperarRecursosVinculadosPerfil(idPerfil) {
  return {
    type: actionTypes.RECUPERAR_RECURSOS_VINCULADOS_PERFIL,
    payload: {
      request: {
        data: { pagination: { pageSize: 10000 } },
        url: `seguranca/perfis/${idPerfil}/recursos/vinculados`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'administrativo',
        success: false,
        failure: {
          key: 'recursos.mensagens.vinculados.httpFalha',
        },
      },
    },
  };
}

export function incluirRecursosPerfil(idPerfil, data, exibirMessagemFeedback = true) {
  const successFeed = exibirMessagemFeedback ? {
    key: 'perfil.mensagens.incluirRecursos.httpSucesso',
  } : false;
  return {
    type: actionTypes.INCLUIR_RECURSOS_PERFIL,
    payload: {
      request: {
        data,
        url: `seguranca/perfis/${idPerfil}/recursos/incluir`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: successFeed,
        failure: {
          key: 'perfil.mensagens.incluirRecursos.httpFalha',
        },
      },
    },
  };
}

export function removerRecursosPerfil(idPerfil, data) {
  return {
    type: actionTypes.REMOVER_RECURSOS_PERFIL,
    payload: {
      request: {
        data,
        url: `seguranca/perfis/${idPerfil}/recursos/excluir`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'seguranca',
        success: {
          key: 'perfil.mensagens.removerRecursos.httpSucesso',
        },
        failure: {
          key: 'perfil.mensagens.removerRecursos.httpFalha',
        },
      },
    },
  };
}
