import * as constants from './constants';
import reducer from './reducer';
import PesquisaGtins from './components/gtins/Container';
import TiposDocumentosEletronicosSelecao from './components/tipos_documentos_eletronicos/ListaSelecaoContainer';

export default {
  PesquisaGtins,
  TiposDocumentosEletronicosSelecao,
  constants,
  reducer,
};
