import * as actionTypes from './actionTypes';
import { NUMEROS_SORTE, PERMISSAO_CAMPANHAS } from './constants';

// Campanhas
export function pesquisarCampanhas(data = {}) {
  return {
    type: actionTypes.PESQUISAR_CAMPANHAS,
    payload: {
      request: {
        data,
        url: 'app/configuracoes/campanhas/pesquisa',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: false,
        failure: {
          key: 'campanha.mensagens.getAll.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_CAMPANHAS.CONSULTAR,
    },
  };
}

export function recuperarListaCampanhas() {
  return {
    type: actionTypes.RECUPERAR_LISTA_CAMPANHAS,
    payload: {
      request: {
        url: 'app/configuracoes/campanhas/lista',
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: false,
        failure: {
          key: 'campanha.mensagens.recuperarListaDeCampanhas.falha',
        },
      },
      permissionAttr: PERMISSAO_CAMPANHAS.VISUALIZAR,
    },
  };
}

export function criarCampanha(data) {
  return {
    type: actionTypes.CRIAR_CAMPANHA,
    payload: {
      request: {
        data,
        url: 'app/configuracoes/campanhas',
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: {
          key: 'campanha.mensagens.post.httpSucesso',
          alertOptions: { timeout: 9000, position: 'centerTop', closeButton: true },
        },
        failure: {
          key: 'campanha.mensagens.post.httpFalha',
        },
      },
    },
  };
}

export function recuperarCampanha(id) {
  return {
    type: actionTypes.RECUPERAR_CAMPANHA,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${id}`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: false,
        failure: {
          key: 'campanha.mensagens.get.httpFalha',
        },
      },
      permissionAttr: PERMISSAO_CAMPANHAS.VISUALIZAR,
    },
  };
}

export function atualizarCampanha(data, id) {
  return {
    type: actionTypes.ATUALIZAR_CAMPANHA,
    payload: {
      request: {
        data,
        url: `app/configuracoes/campanhas/${id}`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: {
          key: 'campanha.mensagens.put.httpSucesso',
        },
        failure: {
          key: 'campanha.mensagens.put.httpFalha',
        },
      },
    },
  };
}

export function cancelarCampanha(id) {
  return {
    type: actionTypes.CANCELAR_CAMPANHA,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${id}/cancelar`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: {
          key: 'campanha.mensagens.cancelar.httpSucesso',
        },
        failure: {
          key: 'campanha.mensagens.cancelar.httpFalha',
        },
      },
    },
  };
}

export function prepararVigenciaCampanha(id) {
  return {
    type: actionTypes.PREPARAR_VIGENCIA_CAMPANHA,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${id}/preparar-vigencia`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: {
          key: 'campanha.mensagens.prepararVigencia.httpSucesso',
        },
        failure: {
          key: 'campanha.mensagens.prepararVigencia.httpFalha',
        },
      },
    },
  };
}

export function finalizarCampanha(id) {
  return {
    type: actionTypes.FINALIZAR_CAMPANHA,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${id}/finalizar`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: {
          key: 'campanha.mensagens.finalizarCampanha.httpSucesso',
        },
        failure: {
          key: 'campanha.mensagens.finalizarCampanha.httpFalha',
        },
      },
    },
  };
}

// Lojas Participantes
export function criarLojasParticipantes(campanhaId, data) {
  return {
    type: actionTypes.CRIAR_LOJAS_PARTICIPANTES,
    payload: {
      request: {
        data,
        url: `app/configuracoes/campanhas/${campanhaId}/lojas-participantes`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'defaultHttpMensage',
        success: { key: 'post.httpSucesso' },
        failure: { key: 'post.httpFalha' },
      },
    },
  };
}

export function recuperarLojasParticipantes(campanhaId) {
  return {
    type: actionTypes.RECUPERAR_LOJAS_PARTICIPANTES,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${campanhaId}/lojas-participantes`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'defaultHttpMensage',
        success: false,
        failure: { key: 'getAll.httpFalha' },
      },
    },
  };
}

export function deletarLojaParticipante(campanhaId, lojaParticipanteId) {
  return {
    type: actionTypes.DELETAR_LOJA_PARTICIPANTE,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${campanhaId}/lojas-participantes/${lojaParticipanteId}`,
        method: 'DELETE',
      },
      feedbackMessages: {
        i18nNS: 'defaultHttpMensage',
        success: { key: 'delete.httpSucesso' },
        failure: { key: 'delete.httpFalha' },
      },
    },
  };
}

// Inelegiveis
export function criarInelegivel(campanhaId, data, exibirFeedback = true) {
  return {
    type: actionTypes.CRIAR_INELEGIVEL,
    payload: {
      request: {
        data,
        url: `app/configuracoes/campanhas/${campanhaId}/inelegiveis`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'defaultHttpMensage',
        success: exibirFeedback ? { key: 'post.httpSucesso' } : false,
        failure: exibirFeedback ? { key: 'post.httpFalha' } : false,
      },
    },
  };
}

export function recuperarIlegiveis(campanhaId) {
  return {
    type: actionTypes.RECUPERAR_INELEGIVEIS,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${campanhaId}/inelegiveis`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'defaultHttpMensage',
        success: false,
        failure: { key: 'getAll.httpFalha' },
      },
    },
  };
}

export function deletarInelegivel(campanhaId, inelegivelId) {
  return {
    type: actionTypes.DELETAR_INELEGIVEL,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${campanhaId}/inelegiveis/${inelegivelId}`,
        method: 'DELETE',
      },
      feedbackMessages: {
        i18nNS: 'defaultHttpMensage',
        success: { key: 'delete.httpSucesso' },
        failure: { key: 'delete.httpFalha' },
      },
    },
  };
}

// Sorteios
export function recuperarSorteios(campanhaId, inBackground = false) {
  return {
    type: actionTypes.RECUPERAR_SORTEIOS,
    inBackground,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${campanhaId}/sorteios`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'defaultHttpMensage',
        success: false,
        failure: { key: 'getAll.httpFalha' },
      },
    },
  };
}

export function criarSorteio(campanhaId, data) {
  return {
    type: actionTypes.CRIAR_SORTEIO,
    payload: {
      request: {
        data,
        url: `app/configuracoes/campanhas/${campanhaId}/sorteios`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'defaultHttpMensage',
        success: { key: 'post.httpSucesso' },
        failure: { key: 'post.httpFalha' },
      },
    },
  };
}

export function atualizarSorteio(campanhaId, data, sorteioId) {
  return {
    type: actionTypes.ATUALIZAR_SORTEIO,
    payload: {
      request: {
        data,
        url: `app/configuracoes/campanhas/${campanhaId}/sorteios/${sorteioId}`,
        method: 'PUT',
      },
      feedbackMessages: {
        i18nNS: 'defaultHttpMensage',
        success: { key: 'put.httpSucesso' },
        failure: { key: 'put.httpFalha' },
      },
    },
  };
}

export function deletarSorteio(campanhaId, sorteioId) {
  return {
    type: actionTypes.DELETAR_SORTEIO,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${campanhaId}/sorteios/${sorteioId}`,
        method: 'DELETE',
      },
      feedbackMessages: {
        i18nNS: 'defaultHttpMensage',
        success: { key: 'delete.httpSucesso' },
        failure: { key: 'delete.httpFalha' },
      },
    },
  };
}

export function elegerGanhadores(campanhaId, sorteioId) {
  return {
    type: actionTypes.ELEGER_GANHADORES_SORTEIO,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${campanhaId}/sorteios/${sorteioId}/eleger-ganhadores`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: {
          key: 'campanha.mensagens.elegerGanhadores.sucesso',
        },
        failure: {
          key: 'campanha.mensagens.elegerGanhadores.falha',
        },
      },
    },
  };
}

// Números da Sorte
export function pesquisarNumerosDaSorte(campanhaId, data, contexto = NUMEROS_SORTE.porLoja) {
  return {
    type: actionTypes.PESQUISAR_NUMEROS_DA_SORTE,
    contexto,
    payload: {
      request: {
        data,
        url: `app/configuracoes/campanhas/${campanhaId}/numeros-sortes/pesquisa`,
        method: 'POST',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: false,
        failure: { key: 'campanha.mensagens.pesquisarNumeros.falha' },
      },
    },
  };
}

export function limparNumerosDaSorte(contexto = NUMEROS_SORTE.porLoja) {
  return {
    type: actionTypes.LIMPAR_NUMEROS_DA_SORTE,
    contexto,
  };
}

export function recuperarTotalizacaoNumerosSorte(campanhaId) {
  return {
    type: actionTypes.RECUPERAR_TOTALIZACAO_NUMEROS_DA_SORTE,
    payload: {
      request: {
        url: `app/configuracoes/campanhas/${campanhaId}/numeros-sortes/totalizacao-por-loja`,
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'configuracoes',
        success: false,
        failure: { key: 'campanha.mensagens.recuperarTotalizacoesPorLoja.falha' },
      },
    },
  };
}

export function limparTotalizacaoNumerosDaSorte() {
  return {
    type: actionTypes.LIMPAR_TOTALIZACAO_NUMEROS_DA_SORTE,
  };
}
