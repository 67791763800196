"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.obterRecuperandoFuncionalidades = exports.obterFuncionalidades = exports.obterBuscando = exports.obterUrls = exports.obterModulos = void 0;

var constants = _interopRequireWildcard(require("./constants"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var obterModulos = function obterModulos(state) {
  return state[constants.NAME].todos;
};

exports.obterModulos = obterModulos;

var obterUrls = function obterUrls(state) {
  return state[constants.NAME].config;
};

exports.obterUrls = obterUrls;

var obterBuscando = function obterBuscando(state) {
  return state[constants.NAME].buscando;
};

exports.obterBuscando = obterBuscando;

var obterFuncionalidades = function obterFuncionalidades(state) {
  return state[constants.NAME].funcionalidades;
};

exports.obterFuncionalidades = obterFuncionalidades;

var obterRecuperandoFuncionalidades = function obterRecuperandoFuncionalidades(state) {
  return state[constants.NAME].recuperandoFuncionalidades;
};

exports.obterRecuperandoFuncionalidades = obterRecuperandoFuncionalidades;