"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPLOAD_AVATAR_USUARIO_ERRO = exports.UPLOAD_AVATAR_USUARIO_SUCESSO = exports.UPLOAD_AVATAR_USUARIO_REQUISICAO = exports.UPLOAD_AVATAR_USUARIO = exports.RECUPERAR_URLS_AVATAR_USUARIO_LOCAL = exports.RECUPERAR_URLS_AVATAR_USUARIO_ERRO = exports.RECUPERAR_URLS_AVATAR_USUARIO_SUCESSO = exports.RECUPERAR_URLS_AVATAR_USUARIO_REQUISICAO = exports.RECUPERAR_URLS_AVATAR_USUARIO = exports.TROCAR_SENHA_USUARIO_ERRO = exports.TROCAR_SENHA_USUARIO_SUCESSO = exports.TROCAR_SENHA_USUARIO_REQUISICAO = exports.TROCAR_SENHA_USUARIO = exports.ATUALIZAR_CREDENCIAL_ERRO = exports.ATUALIZAR_CREDENCIAL_SUCESSO = exports.ATUALIZAR_CREDENCIAL_REQUISICAO = exports.ATUALIZAR_CREDENCIAL = exports.CRIAR_CREDENCIAL_ERRO = exports.CRIAR_CREDENCIAL_SUCESSO = exports.CRIAR_CREDENCIAL_REQUISICAO = exports.CRIAR_CREDENCIAL = exports.RECUPERAR_CREDENCIAIS_ERRO = exports.RECUPERAR_CREDENCIAIS_SUCESSO = exports.RECUPERAR_CREDENCIAIS_REQUISICAO = exports.RECUPERAR_CREDENCIAIS = void 0;
var RECUPERAR_CREDENCIAIS = 'RECUPERAR_CREDENCIAIS';
exports.RECUPERAR_CREDENCIAIS = RECUPERAR_CREDENCIAIS;
var RECUPERAR_CREDENCIAIS_REQUISICAO = 'RECUPERAR_CREDENCIAIS_REQUISICAO';
exports.RECUPERAR_CREDENCIAIS_REQUISICAO = RECUPERAR_CREDENCIAIS_REQUISICAO;
var RECUPERAR_CREDENCIAIS_SUCESSO = 'RECUPERAR_CREDENCIAIS_SUCESSO';
exports.RECUPERAR_CREDENCIAIS_SUCESSO = RECUPERAR_CREDENCIAIS_SUCESSO;
var RECUPERAR_CREDENCIAIS_ERRO = 'RECUPERAR_CREDENCIAIS_ADMIN_ERRO';
exports.RECUPERAR_CREDENCIAIS_ERRO = RECUPERAR_CREDENCIAIS_ERRO;
var CRIAR_CREDENCIAL = 'CRIAR_CREDENCIAL';
exports.CRIAR_CREDENCIAL = CRIAR_CREDENCIAL;
var CRIAR_CREDENCIAL_REQUISICAO = 'CRIAR_CREDENCIAL_REQUISICAO';
exports.CRIAR_CREDENCIAL_REQUISICAO = CRIAR_CREDENCIAL_REQUISICAO;
var CRIAR_CREDENCIAL_SUCESSO = 'CRIAR_CREDENCIAL_SUCESSO';
exports.CRIAR_CREDENCIAL_SUCESSO = CRIAR_CREDENCIAL_SUCESSO;
var CRIAR_CREDENCIAL_ERRO = 'CRIAR_CREDENCIAL_ERRO';
exports.CRIAR_CREDENCIAL_ERRO = CRIAR_CREDENCIAL_ERRO;
var ATUALIZAR_CREDENCIAL = 'ATUALIZAR_CREDENCIAL';
exports.ATUALIZAR_CREDENCIAL = ATUALIZAR_CREDENCIAL;
var ATUALIZAR_CREDENCIAL_REQUISICAO = 'ATUALIZAR_CREDENCIAL_REQUISICAO';
exports.ATUALIZAR_CREDENCIAL_REQUISICAO = ATUALIZAR_CREDENCIAL_REQUISICAO;
var ATUALIZAR_CREDENCIAL_SUCESSO = 'ATUALIZAR_CREDENCIAL_SUCESSO';
exports.ATUALIZAR_CREDENCIAL_SUCESSO = ATUALIZAR_CREDENCIAL_SUCESSO;
var ATUALIZAR_CREDENCIAL_ERRO = 'ATUALIZAR_CREDENCIAL_ERRO';
exports.ATUALIZAR_CREDENCIAL_ERRO = ATUALIZAR_CREDENCIAL_ERRO;
var TROCAR_SENHA_USUARIO = 'TROCAR_SENHA_USUARIO';
exports.TROCAR_SENHA_USUARIO = TROCAR_SENHA_USUARIO;
var TROCAR_SENHA_USUARIO_REQUISICAO = 'TROCAR_SENHA_USUARIO_REQUISICAO';
exports.TROCAR_SENHA_USUARIO_REQUISICAO = TROCAR_SENHA_USUARIO_REQUISICAO;
var TROCAR_SENHA_USUARIO_SUCESSO = 'TROCAR_SENHA_USUARIO_SUCESSO';
exports.TROCAR_SENHA_USUARIO_SUCESSO = TROCAR_SENHA_USUARIO_SUCESSO;
var TROCAR_SENHA_USUARIO_ERRO = 'TROCAR_SENHA_USUARIO_ERRO';
exports.TROCAR_SENHA_USUARIO_ERRO = TROCAR_SENHA_USUARIO_ERRO;
var RECUPERAR_URLS_AVATAR_USUARIO = 'RECUPERAR_URLS_AVATAR_USUARIO';
exports.RECUPERAR_URLS_AVATAR_USUARIO = RECUPERAR_URLS_AVATAR_USUARIO;
var RECUPERAR_URLS_AVATAR_USUARIO_REQUISICAO = 'RECUPERAR_URLS_AVATAR_USUARIO_REQUISICAO';
exports.RECUPERAR_URLS_AVATAR_USUARIO_REQUISICAO = RECUPERAR_URLS_AVATAR_USUARIO_REQUISICAO;
var RECUPERAR_URLS_AVATAR_USUARIO_SUCESSO = 'RECUPERAR_URLS_AVATAR_USUARIO_SUCESSO';
exports.RECUPERAR_URLS_AVATAR_USUARIO_SUCESSO = RECUPERAR_URLS_AVATAR_USUARIO_SUCESSO;
var RECUPERAR_URLS_AVATAR_USUARIO_ERRO = 'RECUPERAR_URLS_AVATAR_USUARIO_ERRO';
exports.RECUPERAR_URLS_AVATAR_USUARIO_ERRO = RECUPERAR_URLS_AVATAR_USUARIO_ERRO;
var RECUPERAR_URLS_AVATAR_USUARIO_LOCAL = 'RECUPERAR_URLS_AVATAR_USUARIO_LOCAL';
exports.RECUPERAR_URLS_AVATAR_USUARIO_LOCAL = RECUPERAR_URLS_AVATAR_USUARIO_LOCAL;
var UPLOAD_AVATAR_USUARIO = 'UPLOAD_AVATAR_USUARIO';
exports.UPLOAD_AVATAR_USUARIO = UPLOAD_AVATAR_USUARIO;
var UPLOAD_AVATAR_USUARIO_REQUISICAO = 'UPLOAD_AVATAR_USUARIO_REQUISICAO';
exports.UPLOAD_AVATAR_USUARIO_REQUISICAO = UPLOAD_AVATAR_USUARIO_REQUISICAO;
var UPLOAD_AVATAR_USUARIO_SUCESSO = 'UPLOAD_AVATAR_USUARIO_SUCESSO';
exports.UPLOAD_AVATAR_USUARIO_SUCESSO = UPLOAD_AVATAR_USUARIO_SUCESSO;
var UPLOAD_AVATAR_USUARIO_ERRO = 'UPLOAD_AVATAR_USUARIO_ERRO';
exports.UPLOAD_AVATAR_USUARIO_ERRO = UPLOAD_AVATAR_USUARIO_ERRO;