import React from 'react';

export const NAME = 'curvaAbcApp';
export const TAB_PAGE_CURVA_ABC_VENDAS_APP = 'TAB_PAGE_CURVA_ABC_VENDAS_APP';
export const INITIAL_REDUX_STATE = {
  produtos: [],
  carregando: false,
};
export const CurvaAbcVendasAppContext = React.createContext();
export const CRITERIOS_FILTRO = {
  dataVendaInicial: null,
  dataVendaFinal: null,
  descricaoProduto: '',
  apenasDescontoExclusivo: false,
  tipoOrdenacao: 1,
};
