export const NAME = 'ePromoDashboard';
export const TAB_PAGE_DASHBOARD_PROMOCAO = 'TAB_PAGE_DASHBOARD_PROMOCAO';
export const INITIAL_REDUX_STATE = {
  quantidadeProdutosVigentes: 0,
  promocoesExclusivas: [],
  resumoVendasPorTurno: [],
  resumoVendasPorData: [],
  resumoVendasPorEmpresa: [],
  ticketMedio: {},
  comparativoTipoPromocaoGeral: {},
  comparativoTipoPromocaoAgrupado: [],
  comparativoClientesVendasGeral: {},
  comparativoClientesVendasAgrupado: [],
  resumoClientesPorDataCadastro: { items: [], media: 0 },
  quantidadeTotalClientesGeral: {},
  quantidadeTotalClientesAgrupado: [],
};

export const TIPO_PERIODOS = {
  hoje: 1,
  ontem: 5,
  semanaAtual: 10,
  semanaPassada: 15,
  mesAtual: 20,
  mesPassado: 25,
  customizado: 999,
};

export const MODOS_VISUALIZACAO_COMPARATIVOS = {
  porProdutosUnicos: 1,
  porQuantidadeTotal: 2,
  porValorTotal: 3,
};

export const AGRUPAMENTO = { tenant: 'tenant', empresa: 'empresa' };
