export const CRIAR_USUARIO = 'CRIAR_USUARIO';
export const CRIAR_USUARIO_REQUISICAO = 'CRIAR_USUARIO_REQUISICAO';
export const CRIAR_USUARIO_SUCESSO = 'CRIAR_USUARIO_SUCESSO';
export const CRIAR_USUARIO_ERRO = 'CRIAR_USUARIO_ERRO';

export const PESQUISAR_USUARIOS = 'PESQUISAR_USUARIOS';
export const PESQUISAR_USUARIOS_REQUISICAO = 'PESQUISAR_USUARIOS_REQUISICAO';
export const PESQUISAR_USUARIOS_SUCESSO = 'PESQUISAR_USUARIOS_SUCESSO';
export const PESQUISAR_USUARIOS_ERRO = 'PESQUISAR_USUARIOS_ERRO';

export const RECUPERAR_USUARIO = 'RECUPERAR_USUARIO';
export const RECUPERAR_USUARIO_REQUISICAO = 'RECUPERAR_USUARIO_REQUISICAO';
export const RECUPERAR_USUARIO_SUCESSO = 'RECUPERAR_USUARIO_SUCESSO';
export const RECUPERAR_USUARIO_ERRO = 'RECUPERAR_USUARIO_ERRO';

export const CONFIGURAR_PERFIS_USUARIO = 'CONFIGURAR_PERFIS_USUARIO';
export const CONFIGURAR_PERFIS_USUARIO_REQUISICAO = 'CONFIGURAR_PERFIS_USUARIO_REQUISICAO';
export const CONFIGURAR_PERFIS_USUARIO_SUCESSO = 'CONFIGURAR_PERFIS_USUARIO_SUCESSO';
export const CONFIGURAR_PERFIS_USUARIO_ERRO = 'CONFIGURAR_PERFIS_USUARIO_ERRO';

export const ATUALIZAR_USUARIO = 'ATUALIZAR_USUARIO';
export const ATUALIZAR_USUARIO_REQUISICAO = 'ATUALIZAR_USUARIO_REQUISICAO';
export const ATUALIZAR_USUARIO_SUCESSO = 'ATUALIZAR_USUARIO_SUCESSO';
export const ATUALIZAR_USUARIO_ERRO = 'ATUALIZAR_USUARIO_ERRO';

export const DELETAR_USUARIO = 'DELETAR_USUARIO';
export const DELETAR_USUARIO_REQUISICAO = 'DELETAR_USUARIO_REQUISICAO';
export const DELETAR_USUARIO_SUCESSO = 'DELETAR_USUARIO_SUCESSO';
export const DELETAR_USUARIO_ERRO = 'DELETAR_USUARIO_ERRO';

export const RECUPERAR_PERFIS_USUARIO = 'RECUPERAR_PERFIS_USUARIO';
export const RECUPERAR_PERFIS_USUARIO_REQUISICAO = 'RECUPERAR_PERFIS_USUARIO_REQUISICAO';
export const RECUPERAR_PERFIS_USUARIO_SUCESSO = 'RECUPERAR_PERFIS_USUARIO_SUCESSO';
export const RECUPERAR_PERFIS_USUARIO_ERRO = 'RECUPERAR_PERFIS_USUARIO_ERRO';

export const VERIFICAR_USUARIO_EXISTENTE_POR_EMAIL = 'VERIFICAR_USUARIO_EXISTENTE_POR_EMAIL';
export const VERIFICAR_USUARIO_EXISTENTE_POR_EMAIL_REQUISICAO = 'VERIFICAR_USUARIO_EXISTENTE_POR_EMAIL_REQUISICAO';
export const VERIFICAR_USUARIO_EXISTENTE_POR_EMAIL_SUCESSO = 'VERIFICAR_USUARIO_EXISTENTE_POR_EMAIL_SUCESSO';
export const VERIFICAR_USUARIO_EXISTENTE_POR_EMAIL_ERRO = 'VERIFICAR_USUARIO_EXISTENTE_POR_EMAIL_ERRO';

export const ASSOCIAR_USUARIO_AO_PARCEIRO = 'ASSOCIAR_USUARIO_AO_PARCEIRO';
export const ASSOCIAR_USUARIO_AO_PARCEIRO_REQUISICAO = 'ASSOCIAR_USUARIO_AO_PARCEIRO_REQUISICAO';
export const ASSOCIAR_USUARIO_AO_PARCEIRO_SUCESSO = 'ASSOCIAR_USUARIO_AO_PARCEIRO_SUCESSO';
export const ASSOCIAR_USUARIO_AO_PARCEIRO_ERRO = 'ASSOCIAR_USUARIO_AO_CONTATO_ERRO';
