import * as actionTypes from './actionTypes';

export function recuperarTenantsDisponiveisAPP() {
  return {
    type: actionTypes.RECUPERAR_TENANTS_DISPONIVEIS_APP,
    payload: {
      request: {
        url: 'app/tenants/disponiveis',
        method: 'GET',
      },
      feedbackMessages: {
        i18nNS: 'app',
        success: false,
        failure: {
          key: 'tenant.mensagens.post.httpFalha',
        },
      },
    },
  };
}
