import _ from 'lodash';
import * as actionTypes from './actionTypes';
import { INITIAL_REDUX_STATE } from './constants';

const produtoImagens = (state = INITIAL_REDUX_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_IMAGEM_PRODUTO_REQUISICAO:
      return {
        ...state, uploadingImagemProduto: true, imagemProduto: undefined,
      };
    case actionTypes.UPLOAD_IMAGEM_PRODUTO_SUCESSO:
      return {
        ...state, uploadingImagemProduto: false, imagemProduto: action.payload.data,
      };

    case actionTypes.ATACHAR_IMAGEM_AO_PRODUTO_REQUISICAO:
      return {
        ...state, atachandoImagem: true,
      };
    case actionTypes.ATACHAR_IMAGEM_AO_PRODUTO_SUCESSO:
      return {
        ...state, atachandoImagem: false,
      };

    case actionTypes.PESQUISAR_IMAGENS_PRODUTOS_REQUISICAO:
      return {
        ...state, pesquisando: true, produtoImagens: {},
      };
    case actionTypes.PESQUISAR_IMAGENS_PRODUTOS_SUCESSO: {
      const { items, pagination } = action.payload.data.content;
      return {
        ...state,
        pesquisando: false,
        produtoImagens: { itens: _.mapKeys(items, 'id'), pagination },
      };
    }

    case actionTypes.PESQUISAR_IMAGENS_PRODUTOS_SELECAO_REQUISICAO:
      return {
        ...state, pesquisandoImagensSelecao: false, produtoImagensSelecao: [],
      };
    case actionTypes.PESQUISAR_IMAGENS_PRODUTOS_SELECAO_SUCESSO:
      return {
        ...state,
        pesquisandoImagensSelecao: false,
        produtoImagensSelecao: action.payload.data.content.items,
      };
    case actionTypes.LIMPAR_IMAGENS_PRODUTOS_SELECAO:
      return {
        ...state, produtoImagensSelecao: [],
      };

    case actionTypes.RECUPERAR_IMAGEM_PRODUTO_REQUISICAO:
      return {
        ...state, recuperando: true,
      };
    case actionTypes.RECUPERAR_IMAGEM_PRODUTO_SUCESSO:
      return {
        ...state, recuperando: false, imagemSelecionada: action.payload.data,
      };

    case actionTypes.ATUALIZAR_IMAGEM_PRODUTO_REQUISICAO:
      return {
        ...state, atualizando: true,
      };

    case actionTypes.ATUALIZAR_IMAGEM_PRODUTO_SUCESSO: {
      const { data } = action.payload;
      return {
        ...state,
        atualizando: false,
        imagemSelecionada: data,
        produtoImagens: {
          ...state.produtoImagens, itens: { ...state.produtoImagens.itens, [data.id]: data },
        },
      };
    }

    case actionTypes.DELETAR_IMAGEM_PRODUTO_REQUISICAO:
      return {
        ...state, deletando: true,
      };

    case actionTypes.DELETAR_IMAGEM_PRODUTO_SUCESSO: {
      const { produtoImagemId } = action.meta.previousAction;
      return {
        ...state,
        deletando: false,
        produtoImagens: {
          ...state.produtoImagens,
          itens: _.omit(state.produtoImagens.itens, produtoImagemId),
        },
      };
    }

    case actionTypes.ATUALIZAR_IMAGEM_PRODUTO_ERRO:
    case actionTypes.UPLOAD_IMAGEM_PRODUTO_ERRO:
    case actionTypes.PESQUISAR_IMAGENS_PRODUTOS_SELECAO_ERRO:
    case actionTypes.ATACHAR_IMAGEM_AO_PRODUTO_ERRO:
    case actionTypes.PESQUISAR_IMAGENS_PRODUTOS_ERRO:
    case actionTypes.RECUPERAR_IMAGEM_PRODUTO:
      return {
        ...state,
        uploadingImagemProduto: false,
        atachandoImagem: false,
        pesquisandoImagensSelecao: false,
        pesquisando: false,
        recuperando: false,
        atualizando: false,
        deletando: false,
      };
    default:
      return state;
  }
};

export default produtoImagens;
