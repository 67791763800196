import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SpinnerLoading from 'linear-react-components-ui/lib/spinner';
import { translate } from 'react-i18next';
import Table, { TRow, TBody, Td, Th, THeader } from 'linear-react-components-ui/lib/table';
import Checkbox from 'linear-react-components-ui/lib/checkbox';
import * as selectors from '../selectors';
import { pesquisarPerfis } from '../actions';
import { perfilAdminNome, STATUS } from '../constants';

class PerfisSelecaoContainer extends Component {
  static getDerivedStateFromProps = (props, state) => {
    if (state.idsFromProps.length !== props.idsSelecionados.length) {
      const { idsSelecionados, usuarioSelecionado } = props;
      return {
        idsSelecionados,
        usuarioSelecionado,
        idsFromProps: idsSelecionados,
      };
    }
    return null;
  }

  state = {
    usuarioSelecionado: this.props.usuarioSelecionado,
    idsSelecionados: this.props.idsSelecionados,
    // TODO
    // eslint-disable-next-line react/no-unused-state
    idsFromProps: this.props.idsSelecionados,
  }

  componentDidMount() {
    this.props.pesquisarPerfis({ status: [STATUS.ativo] });
  }

  onCheckboxClick = (id) => {
    let { idsSelecionados } = this.state;
    const { handlerAtualizarSelecionados } = this.props;

    if (idsSelecionados.includes(id)) {
      idsSelecionados = idsSelecionados.filter(item => item !== id);
    } else {
      idsSelecionados = [...idsSelecionados, id];
    }
    this.setState({ idsSelecionados });
    if (handlerAtualizarSelecionados) handlerAtualizarSelecionados(idsSelecionados);
  }

  possuiAdmin = perfil => perfil.nome === perfilAdminNome &&
    this.state.idsSelecionados.includes(perfil.id) &&
    this.state.usuarioSelecionado.perfis.find(item => item.id === perfil.id);

  render() {
    const {
      perfis, pesquisando, t, showTableHeader, showTableBorder,
    } = this.props;
    const { idsSelecionados } = this.state;
    return (
      <Fragment>
        <Table condensed headerColored bordered={showTableBorder}>
          {showTableHeader &&
            <THeader>
              <TRow>
                <Th style={{ width: 30 }} />
                <Th style={{ width: 160 }}>{t('commons:labels.nome')}</Th>
                <Th>{t('commons:labels.descricao')}</Th>
              </TRow>
            </THeader>}
          <TBody>
            {perfis.map(perfil => (
              <TRow rowId={`perfil-${perfil.id}`} key={`perfil-${perfil.id}`}>
                <Td onClick={() =>
                  (!this.possuiAdmin(perfil) ? this.onCheckboxClick(perfil.id) : null)}>
                  <Checkbox
                    checked={idsSelecionados.includes(perfil.id)}
                    // TODO - Solução Paleativa até serem feitas validações no backend
                    disabled={this.possuiAdmin(perfil)} />
                </Td>
                <Td>{perfil.nome}</Td>
                <Td>{perfil.descricao}</Td>
              </TRow>
            ))}
          </TBody>
        </Table>
        <div>
          {pesquisando &&
          <SpinnerLoading text={t('seguranca:perfil.labels.carregandoPerfis')} />}
        </div>
      </Fragment>
    );
  }
}

PerfisSelecaoContainer.propTypes = {
  pesquisarPerfis: PropTypes.func.isRequired,
  perfis: PropTypes.arrayOf(PropTypes.object).isRequired,
  pesquisando: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  idsSelecionados: PropTypes.arrayOf(PropTypes.number),
  usuarioSelecionado: PropTypes.object,
  handlerAtualizarSelecionados: PropTypes.func,
  showTableHeader: PropTypes.bool,
  showTableBorder: PropTypes.bool,
};

PerfisSelecaoContainer.defaultProps = {
  idsSelecionados: [],
  usuarioSelecionado: {},
  handlerAtualizarSelecionados: undefined,
  showTableHeader: true,
  showTableBorder: false,
};

const mapStateToProps = state => ({
  perfis: selectors.obterPerfis(state),
  pesquisando: selectors.obterPesquisando(state),
});

export default connect(mapStateToProps, { pesquisarPerfis })(translate(['seguranca'])(PerfisSelecaoContainer));
